const loginTourSteps = [
    {
        target: '#accountSettings',
        header: {
            title: 'admin.tour.account_settings_title'
        },
        content: 'admin.tour.account_settings_message'
    },
    {
        target: '#navigationOrgSettings',
        header: {
            title: 'admin.tour.org_settings_title'
        },
        content: 'admin.tour.org_settings_message',
        params: {
            placement: 'right'
        }
    },
    {
        target: '#navigationUsers',
        header: {
            title: 'admin.tour.users_title'
        },
        content: 'admin.tour.users_message',
        params: {
            placement: 'right'
        }
    },
    {
        target: '#navigationApps',
        header: {
            title: 'admin.tour.apps_title'
        },
        content: 'admin.tour.apps_message',
        params: {
            placement: 'right'
        }
    },
];

export {
    loginTourSteps
}