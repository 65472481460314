<template>
    <div class="pb-2">
        <DisplayCard :title="this.title" :isCards="this.isCards">
            <form @submit.prevent="submitForm">
                <ActionBar :buttons="actionButtons()" />

                <div class="card border-0 col-12 pl-0">
                    <div class="card-body" v-if="this.isLoaded">
                        <div class="row">
                            <div
                                v-for="(row, index) in currentColumns"
                                :key="index"
                                class="inputStyle col-12 col-xl-6"
                            >
                                <div v-if="row.type != 'File'">
                                    <input
                                        v-if="
                                            !optionsMapping[row.path] &&
                                                row.path != 'content' &&
                                                row.type != 'Boolean'
                                        "
                                        :type="typeInput(row.type)"
                                        :required="row.required"
                                        v-model="object[row.path]"
                                        :placeholder="row.title"
                                    />
                                    <select
                                        :required="row.required"
                                        v-if="optionsMapping[row.path]"
                                        v-model="object[row.path]"
                                    >
                                        <option
                                            v-for="(option,
                                            index) in optionsMapping[row.path](
                                                row
                                            )"
                                            :key="index"
                                            :value="option.value"
                                        >
                                            {{ $t(option.display) }}
                                        </option>
                                    </select>
                                    <textarea
                                        @keyup.enter="addBr($event, row.path)"
                                        :required="row.required"
                                        v-if="row.path == 'content'"
                                        rows="10"
                                        v-model="object[row.path]"
                                        :placeholder="row.title"
                                        data-toggle="tooltip"
                                        data-html="true"
                                        :title="
                                            $t(
                                                'Notification variables must be enclosed in curly braces {var}'
                                            )
                                        "
                                    >
                                    </textarea>
                                    <div
                                        class="input_wrapper mt-4 pl-1"
                                        v-if="row.type == 'Boolean'"
                                    >
                                        <input
                                            type="checkbox"
                                            v-model="object[row.path]"
                                        />
                                        <svg
                                            class="is_checked"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 426.67 426.67"
                                        >
                                            <path
                                                d="M153.504 366.84c-8.657 0-17.323-3.303-23.927-9.912L9.914 237.265c-13.218-13.218-13.218-34.645 0-47.863 13.218-13.218 34.645-13.218 47.863 0l95.727 95.727 215.39-215.387c13.218-13.214 34.65-13.218 47.86 0 13.22 13.218 13.22 34.65 0 47.863L177.435 356.928c-6.61 6.605-15.27 9.91-23.932 9.91z"
                                            />
                                        </svg>
                                        <svg
                                            class="is_unchecked"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 212.982 212.982"
                                        >
                                            <path
                                                d="M131.804 106.49l75.936-75.935c6.99-6.99 6.99-18.323 0-25.312-6.99-6.99-18.322-6.99-25.312 0L106.49 81.18 30.555 5.242c-6.99-6.99-18.322-6.99-25.312 0-6.99 6.99-6.99 18.323 0 25.312L81.18 106.49 5.24 182.427c-6.99 6.99-6.99 18.323 0 25.312 6.99 6.99 18.322 6.99 25.312 0L106.49 131.8l75.938 75.937c6.99 6.99 18.322 6.99 25.312 0 6.99-6.99 6.99-18.323 0-25.313l-75.936-75.936z"
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                            />
                                        </svg>
                                    </div>
                                    <label>{{
                                        $t("core.common." + row.path)
                                    }}</label>
                                </div>
                                <div v-if="row.type == 'File'">
                                    <input
                                        type="hidden"
                                        v-model="object[row.path]"
                                    />
                                    <img
                                        v-if="picture"
                                        v-bind:src="
                                            get_image_url_from_path(picture)
                                        "
                                        alt=""
                                        width="140"
                                        height="auto"
                                    />
                                    <input
                                        type="file"
                                        ref="file"
                                        @change="onFileChange(row.path)"
                                    />
                                    <label>
                                        {{ $t("core.common." + row.path) }}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div
                                v-if="!systemObject"
                                class="col-12 col-xl-4 pt-2"
                            >
                                <div class="borderTitle">
                                    <h5
                                        class="
                                            m-2
                                            pl-2
                                            table-title
                                            text-uppercase
                                        "
                                        style="display: inline-block"
                                    >
                                        {{ $t("core.common.tags") }}
                                    </h5>
                                </div>
                                <hr class="mb-4" />
                                <div class="row">
                                    <div class="col-12 col-xl-12">
                                        <button
                                            type="button"
                                            v-if="this.isSaveTag"
                                            @click="this.saveTag"
                                            class="btn btnSuccess"
                                        >
                                            {{ $t("core.common.save") }}
                                        </button>
                                        <div
                                            v-if="!hideTags && mappedTags"
                                            class="pt-2"
                                        >
                                            <TagsList
                                                v-if="
                                                    this.isLoaded &&
                                                        this.isLoadedTag
                                                "
                                                :isListTags="isListTags"
                                                :mappedTags="mappedTags"
                                                :isSaveTag="changeSaveTag"
                                                :selectedTags="selectedTags"
                                            />

                                            <TagsInputObject
                                                v-if="this.object._id"
                                                :isErrorTag="isErrorTag"
                                                :addTag="addTag"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr v-if="this.script" />
                        <EditorJs v-if="this.script" :script="this.script">
                        </EditorJs>
                    </div>
                </div>
            </form>
        </DisplayCard>
    </div>
</template>

<script>
    import TagsList from "./../screens/TagsList.vue";
    import TagsInputObject from "./../screens/TagsInputObject.vue";
    export default {
        name: "InputForm",
        watch: {
            object: {
                deep: true,
                handler() {
                    if (this.columnsFunction) {
                        this.currentColumns = this.columnsFunction(this.object);
                    }
                },
            },
        },
        components: {
            TagsList,
            TagsInputObject,
        },
        mounted() {
            this.loadData();
        },
        props: {
            buttonConfig: {
                type: String,
                default: "save",
            },
            columns: {
                type: Array,
            },
            hideTags: {
                type: Boolean,
            },
            mappedTags: {
                type: Object,
            },
            navigationInfo: {
                type: Object,
            },
            cancelObjectChanges: {
                type: Function,
            },
            confirmObjectChanges: {
                type: Function,
            },
            object: {
                type: Object,
            },
            title: {
                type: String,
                required: false,
            },
            isCards: {
                type: Boolean,
                required: false,
                default: true,
            },
            columnsFunction: {
                type: Function,
            },
            isSpinner: {
                type: Boolean,
            },
            systemObject: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                currentColumns: [],
                optionsMapping: {},
                selectedTags: [],
                script: false,
                picture: false,
                isAddTag: false,
                isErrorTag: false,
                isSaveTag: false,
                tagName: "",
                tagColor: "#ae0b4f",
                file: "",
                propertyFile: null,
                get_image_url_from_path: this.$iconico.get_image_url_from_path,
                colors: "#ae0b4f",
                isListTags: true,
                isLoaded: false,
                isLoadedTag: false,
            };
        },
        methods: {
            async loadData() {
                this.$store.state.showPageLoading = true;
                this.loadButton();
                if (this.columnsFunction) {
                    this.currentColumns = await this.columnsFunction(
                        this.object
                    );
                } else {
                    this.currentColumns = await this.columns;
                }
                this.currentColumns.forEach((object, index) => {
                    if (object.type == "File") {
                        let property = object.property;
                        this.propertyFile = property + "File";
                        if (this.object[this.propertyFile])
                            this.picture = this.object[this.propertyFile].url;
                    }
                });
                this.currentColumns = this.currentColumns.filter(
                    (column) => column.include_in_body
                );

                if (this.object.date) {
                    await this.loadDate();
                }

                if (this.object.tags) {
                    await this.loadSelectedTags();
                }

                await this.loadOptionsMapping();
                this.isLoaded = true;
                this.isLoadedTag = true;
                this.$store.state.showPageLoading = false;
            },
            loadSelectedTags() {
                const keys = Object.keys(this.mappedTags);
                this.selectedTags.fill(false, 0, keys.length);

                this.object.tags.forEach((tagId) => {
                    const index = keys.indexOf(tagId);
                    this.selectedTags[index] = true;
                });
            },
            loadButton() {
                if (this.object.email) {
                    this.buttonConfig = "save";
                }
            },
            updateValue() {
                this.tagColor = this.colors.hex;
            },
            changeSaveTag(status) {
                this.isSaveTag = status;
            },
            async addTag(tagName, tagColor) {
                if (tagName.length > 0) {
                    this.isLoadedTag = false;
                    this.$store.state.showPageLoading = true;
                    this.isListTags = false;
                    const body = {
                        name: tagName,
                        color: tagColor,
                        object: this.navigationInfo.object,
                    };

                    let config = {
                        org: this.$store.state.org,
                        authToken: this.$store.state.account.token,
                        body,
                        object: "tag",
                    };

                    let data = await this.$iconico.post_object(config);
                    if (data.status == 200) {
                        let color = data.data;
                        this.$store.state.mappedTags[color._id] = color;
                        this.mappedTags[color._id] = color;
                        this.isListTags = true;
                    } else {
                        this.isErrorTag = true;
                    }
                    this.isLoadedTag = true;
                    this.$store.state.showPageLoading = false;
                }
            },
            saveSelected() {
                if (this.confirmObjectChanges) {
                    let tags = [];
                    this.selectedTags.forEach((tag, index) => {
                        if (tag)
                            tags.push(
                                this.mappedTags[
                                    Object.keys(this.mappedTags)[index]
                                ]._id
                            );
                    });
                    this.object.tags = tags;
                    this.confirmObjectChanges(this.object);
                }
            },
            saveTag() {
                let tags = [];
                this.selectedTags.forEach((tag, index) => {
                    if (tag)
                        tags.push(
                            this.mappedTags[Object.keys(this.mappedTags)[index]]
                                ._id
                        );
                });
                this.object.tags = tags;
                let config = {
                    org: this.$store.state.org,
                    authToken: this.$store.state.account.token,
                    body: this.object,
                    object: this.navigationInfo.object,
                };
                this.$iconico
                    .put_object(config)
                    .then((result) => {
                        this.$store.state.status = 200;
                        this.$store.state.message = this.$t(
                            "core.alerts.success_modify"
                        );
                    })
                    .catch((error) => {
                        this.controlError(error);
                    });
                this.isSaveTag = false;
            },
            actionButtons() {
                return [
                    {
                        type: "cancel",
                        action: this.cancelForm,
                    },
                    {
                        type: this.buttonConfig,
                        action: this.saveSelected,
                    },
                ];
            },
            loadOptionsMapping() {
                let mapping = {};
                this.navigationInfo.extraColumns.forEach((property) => {
                    if (property.options) {
                        mapping[property.property] = property.options;
                    }
                });
                this.optionsMapping = mapping;
            },
            addBr(event, property) {
                if (event.keyCode == 13) {
                    this.object[property] = event.target.value + "<br>";
                    return;
                }
            },
            typeInput(type) {
                const mapping = {
                    String: "text",
                    Number: "number",
                    Date: "Date",
                    Boolean: "checkbox",
                };
                return mapping[type];
            },
            cancelForm() {
                this.cancelObjectChanges();
            },
            submitForm() {
                this.confirmObjectChanges(
                    this.object,
                    this.file,
                    this.propertyFile
                );
            },
            loadDate() {
                var date = new Date(this.object.date);
                var result =
                    "" +
                    date.getFullYear() +
                    "-" +
                    (date.getMonth() + 1 > 9 ? "" : "0") +
                    (date.getMonth() + 1) +
                    "-" +
                    (date.getDate() + 1 > 9 ? "" : "0") +
                    (date.getDate() + 1);
                this.object.date = result;
            },
            onFileChange(property) {
                this.file = this.$refs.file[0].files[0];
                var form = new FormData();
                form.append("file", this.file);
                this.object[property] = this.file.name;
                this.file = form;
            },
        },
    };
</script>

<style scoped>
    @import "../../assets/styleForm.css";
</style>
