<template>
    <div id="InfoAlert">
        <div class="w-100 bg-white p-0 fontbase font-lt" id="alert">
            <div
                class="
                    text-center text-white
                    d-flex
                    align-items-center
                    justify-content-center
                "
                :class="bg"
            >
                <i class="fas" :class="icon" />
            </div>
            <div class="d-flex align-items-center mx-2" :class="classText">
                {{ text }}
            </div>
        </div>
        <p v-if="textExtra" class="fontbase font-lt p-2">{{ textExtra }}</p>
    </div>
</template>
<script>
    export default {
        name: "InfoAlert",
        props: ["icon", "colorClass", "text", "textExtra"],
        mounted() {
            this.classText = `text-${this.colorClass}`;
            this.bg = `bg-${this.colorClass}`;
        },
        data() {
            return {
                classText: "text-info",
                bg: "bg-info",
            };
        },
    };
</script>

<style>
    #alert {
        height: 46px;
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.18),
            0 1px 1px 0 rgba(0, 0, 0, 0.14);
        display: grid;
        width: 100%;
        grid-template-columns: 59px 1fr;
    }
</style>
