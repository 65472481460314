<template>
    <div>
        <div
            class="alert alert-dismissible fade show"
            role="alert"
            :class="alertClass()"
        >
            <strong v-if="bg == 'danger'">Error</strong> {{ text }}
            {{ textExtra }}

            <button
                type="button"
                @click="dismissAlert()"
                class="close"
                data-dismiss="alert"
                :style="{ top: '-3px' }"
                aria-label="Close"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
</template>
<script>
    export default {
        name: "SimpleAlert",
        props: ["colorClass", "text", "textExtra", "dismiss"],
        mounted() {
            this.bg = this.colorClass;
        },
        data() {
            return {
                classText: "text-info",
                bg: "bg-info",
            };
        },
        methods: {
            dismissAlert() {
                if (this.dismiss) {
                    this.dismiss();
                }
            },
            alertClass() {
                if (this.bg == "danger") {
                    return " alert-danger ";
                } else {
                    return " alert-success ";
                }
            },
        },
    };
</script>

<style></style>
