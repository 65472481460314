fre<template>
    <div class="pt-1">
        <transition name="fade">
            <div
                class="clearfix row mr-1 ml-1 mb-4"
                v-if="this.isDataResources"
            >
                <div class="col-12 col-xl-12">
                    <div class="inputStyle col-12 col-xl-2">
                        <select v-model="range" @change="onChange($event)">
                            <option
                                v-for="(bucket, index) in this.currenctBucket"
                                :key="index"
                                :value="bucket.value"
                            >
                                {{ bucket.number }}
                                {{ $t(`core.common.${bucket.time}`) }}
                            </option>
                        </select>
                    </div>
                </div>

                <!-- Frequency -->

                <div
                    class="col-12 col-xl-6 p-0"
                    v-if="!this.loading"
                >
                    <DisplayCard :title="$t(title)" class="mt-2 ml-1">
                        <ChartArea
                            class="col-12 col-xl-12"
                            :dataModel="data"
                            :height="300"
                            :colors="[graphColor]"
                            :isFormat="graphFormat"
                            :annotations="
                                this.maxEntries
                                    ? [
                                          {
                                              value: this.maxEntries,
                                              color: '#de0561',
                                              colorFont: '#fff',
                                              text: 'Max Entries',
                                          },
                                      ]
                                    : null
                            "
                        />
                    </DisplayCard>
                </div>
            </div>
        </transition>
        <transition name="fade">
            <InfoAlert
                v-if="!this.isDataResources"
                :icon="'fa-info'"
                :colorClass="'info'"
                :text="textAlert()"
            />
        </transition>
    </div>
</template>
<script>
import bucketTime from "../ui/bucketTime";
export default {
    name: "SingleGraph",
    mounted() {
        this.currenctBucket = this.defaultTime
        if (bucketTime[this.bucket]){
            this.currenctBucket= bucketTime[this.bucket]
            this.presition = this.currenctBucket[0].presition
        }

        this.loading = true;
        this.$store.state.showPageLoading = true;

        if (this.chartAreaProps?.colors) {
            this.graphColor = this.chartAreaProps.colors;
        }
        if (this.chartAreaProps?.isFormat) {
            this.graphFormat = this.chartAreaProps.isFormat;
        }
        // !!
        let serverID = null;
        serverID = this.server;
        this.maxEntries = null;
        // !!
        this.dataResources(this.id, serverID);
    },
    props: {
        id: {
            type: String,
            require: true,
        },
        server: {
            type: String,
            require: true,
        },
        title: {
            type: String,
            require: true,
        },
        // measurement: {
        //     type: String,
        //     require: true,
        // },
        path: {
            type: String,
            require: true,
        },
        bucket: {
            type: String,
            require: true,
        },
        field: {
            type: Array,
            require: true,
        },
        name: {
            type: String,
            require: true,
        },
        chartAreaProps: {
            type: Object,
        },
    },
    data() {
        return {
            currenctBucket:[],
            loading:false,
            graphColor: "#b7240b",
            graphFormat: "hertz",
            range: "6d",
            presition: "5m",
            data: [],
            defaultTime: [
                {
                    value: "6h",
                    number: 6,
                    presition: 5,
                    time: "hours",
                },
                {
                    value: "12h",
                    number: 12,
                    presition: 10,
                    time: "hours",
                },
                {
                    value: "24h",
                    number: 24,
                    presition: 15,
                    time: "hours",
                },
            ],
            object: null,
            isDataResources: false,
            maxEntries: null,
        };
    },
    methods: {
        textAlert() {
            return `${this.$t("web.info.no_data")} ${this.name}`;
        },
        onChange: function (event) {
            const found = this.currenctBucket.find(element => element.value == this.range)      
            this.presition = found.presition
            this.range = event.target.value;
            this.loading = true;
            this.$store.state.showPageLoading = true;
            let serverID = null;
            serverID = this.server;
            this.dataResources(this.id, serverID);
        },
        async dataResources(deviceId, server) {
            try {
                this.data = [];

                let config = {
                    org: this.$store.state.org,
                    authToken: this.$store.state.account.token,
                    body: {
                        bucket: this.bucket,
                        measurement: `${this.$store.state.org.path}-probe-${server}`,
                        id: this.id,
                        path: this.path,
                        range: `start:-${this.range}`,
                        field: this.field,
                        function: `|> aggregateWindow(every: ${this.presition}, fn: mean)`,
                    },
                };
                let dataResourcesResult = await this.$iconico.get_influxdb(
                    config
                );

                let dataResources = dataResourcesResult.data;

                const [, ...dataset] = dataResources.split("\n");

                if (dataset.length) {
                    let tempData = [];
                    dataset.forEach((line) => {
                        const [, , , , , time, value] = line.split(",");
                        if (time && value) {
                            tempData.push({ x: time, y: value });
                        }
                    });

                    const dataForGraph = {
                        data: tempData,
                    };
                    this.data.push(dataForGraph);
                    this.isDataResources = true;
                }
                this.loading = false;
                this.$store.state.showPageLoading = false;
            } catch (error) {
                this.controlError(this.$t("core.alerts.error_loading"));
            }
        },
        controlError(error) {
            let alertInfo = {
                state: this.$store.state,
                title: this.$t("core.alerts.error"),
                message: error,
                bg: "danger",
                timeout: 3,
            };
            this.$utilities.showAlert(alertInfo);
        },
    },
};
</script>
