<template>
    <div class="pb-2">
        <DisplayCard :title="this.title" :isCards="this.isCards">
            <form @submit.prevent="submitForm">
                <ActionBar
                    v-if="showActionBar()"
                    :buttons="actionBarButtons()"
                    :object="object"
                />
                <div class="card border-0 col-12 pl-0">
                    <div class="card-body">
                        <div class="row">
                            <div
                                v-for="(row, index) in columnsFiltered"
                                :key="index"
                                class="inputStyle col-12 col-xl-6"
                            >
                                <div v-if="!row.path && picture">
                                    <img
                                        v-bind:src="
                                            get_image_url_from_path(picture)
                                        "
                                        alt=""
                                        width="140"
                                        height="auto"
                                    />
                                    <label>{{ $t("core.common.image") }}</label>
                                </div>
                                <div
                                    v-if="
                                        row.type != 'File' && row.path != '_id'
                                    "
                                >
                                    <input
                                        v-if="
                                            !optionsMapping[row.path] &&
                                                row.path != 'content' &&
                                                row.type != 'Boolean'
                                        "
                                        disabled
                                        v-model="object[row.path]"
                                        :placeholder="row.path"
                                    />
                                    <select
                                        disabled
                                        v-if="optionsMapping[row.path]"
                                        v-model="object[row.path]"
                                    >
                                        <option
                                            v-for="(option,
                                            index) in optionsMapping[row.path](
                                                row
                                            )"
                                            :key="index"
                                            :value="option.value"
                                        >
                                            {{ $t(option.display) }}
                                        </option>
                                    </select>
                                    <textarea
                                        v-if="row.path == 'content'"
                                        disabled
                                        rows="10"
                                        v-model="object[row.path]"
                                        :placeholder="row.path"
                                    >
                                    </textarea>
                                    <div
                                        class="input_wrapper mt-4 pl-1"
                                        v-if="row.type == 'Boolean'"
                                    >
                                        <input
                                            type="checkbox"
                                            disabled
                                            v-model="object[row.path]"
                                        />
                                        <svg
                                            class="is_checked"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 426.67 426.67"
                                        >
                                            <path
                                                d="M153.504 366.84c-8.657 0-17.323-3.303-23.927-9.912L9.914 237.265c-13.218-13.218-13.218-34.645 0-47.863 13.218-13.218 34.645-13.218 47.863 0l95.727 95.727 215.39-215.387c13.218-13.214 34.65-13.218 47.86 0 13.22 13.218 13.22 34.65 0 47.863L177.435 356.928c-6.61 6.605-15.27 9.91-23.932 9.91z"
                                            />
                                        </svg>
                                        <svg
                                            class="is_unchecked"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 212.982 212.982"
                                        >
                                            <path
                                                d="M131.804 106.49l75.936-75.935c6.99-6.99 6.99-18.323 0-25.312-6.99-6.99-18.322-6.99-25.312 0L106.49 81.18 30.555 5.242c-6.99-6.99-18.322-6.99-25.312 0-6.99 6.99-6.99 18.323 0 25.312L81.18 106.49 5.24 182.427c-6.99 6.99-6.99 18.323 0 25.312 6.99 6.99 18.322 6.99 25.312 0L106.49 131.8l75.938 75.937c6.99 6.99 18.322 6.99 25.312 0 6.99-6.99 6.99-18.323 0-25.313l-75.936-75.936z"
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                            />
                                        </svg>
                                    </div>
                                    <label>{{
                                        $t("core.common." + row.path)
                                    }}</label>
                                </div>
                            </div>
                            <div v-if="ShowRefreshButton">
                                <b class="text-block inputStyle">{{
                                    $t("core.notifications.reload_explanation")
                                }}</b>
                                <button
                                    type="button"
                                    class="
                                        position
                                        btn btn-sm
                                        btnPrimary
                                        font-bold
                                    "
                                    @click="socketRefresh"
                                >
                                    <i class="mr-2 fas fa-redo-alt" />
                                    {{ $t("core.common.reload") }}
                                </button>
                            </div>
                            <div
                                v-if="!hideTags && isObjectTags()"
                                class="inputStyle col-12 col-xl-6 pt-2"
                            >
                                <label class="mb-4"
                                    >{{ $t("core.common.tags") }}
                                </label>
                                <div class="mb-4" />
                                <span
                                    v-for="(tag, index) in object.tags"
                                    :key="index"
                                    class="m-2 p-2 rounded"
                                    :style="tagStyle(tag)"
                                >
                                    <i class="fas fa-tag fa-lg ml-1"></i>
                                    {{ tagName(tag) }}
                                </span>
                                <hr class="mt-3" />
                            </div>
                        </div>
                        <hr v-if="!columnDetails && !objectDetails" />
                        <div
                            v-if="columnDetails && objectDetails"
                            class="form-group row"
                        >
                            <label
                                class="col-sm-2 col-form-label font-weight-bold"
                                >{{ $t("core.common.details") }}</label
                            >
                            <ListForm
                                class="col-sm-10"
                                :columnDetails="columnDetails"
                                :objectDetails="objectDetails"
                            />
                        </div>
                    </div>
                </div>
            </form>
        </DisplayCard>
    </div>
</template>

<script>
    import bestContrast from "./bestContrast";
    export default {
        name: "DisplayForm",
        mounted() {
            if (this.object.imageFile) {
                this.picture = this.object.imageFile.url;
            }
            this.columnsFiltered = this.columns.filter(
                (column) =>
                    column.include_in_body &&
                    column.type &&
                    column.path != ("File" && "probe_refresh")
            );
            this.loadOptionsMapping();
        },
        props: {
            ShowRefreshButton: {
                type: Boolean,
                default: false,
            },
            columns: {
                type: Array,
            },
            extraDisplayActions: {
                type: Array,
            },
            hideTags: {
                type: Boolean,
            },
            mappedTags: {
                type: Object,
            },
            navigationInfo: {
                type: Object,
            },
            cancelObjectChanges: {
                type: Function,
            },
            object: {
                type: Object,
            },
            columnDetails: {
                type: Array,
            },
            objectDetails: {
                type: Array,
            },
            actionEdit: {
                type: Function,
            },
            actionDelete: {
                type: Function,
            },
            title: {
                type: String,
                required: false,
            },
            isCards: {
                type: Boolean,
                required: false,
                default: true,
            },
            systemObject: {
                type: Boolean,
                required: false,
                default: false,
            },
        },
        data() {
            return {
                optionsMapping: {},
                columnsFiltered: [],
                picture: false,
                script: false,
                get_image_url_from_path: this.$iconico.get_image_url_from_path,
            };
        },
        methods: {
            async socketRefresh() {
                let config = {
                    org: this.$store.state.org,
                    authToken: this.$store.state.account.token,
                    object: "socket",
                    id: this.object._id,
                    body: {
                        code: "refresh",
                    },
                };
                try {
                    this.$store.state.showPageLoading = true;
                    let response = await this.$iconico.post_socket(config);
                    this.$store.state.status = 200;
                    this.$store.state.message = `${this.$t(
                        "core.alerts.probe_refresh_success"
                    )}`;
                } catch (error) {
                    this.$store.state.showPageLoading = false;
                    this.controlError(
                        `${this.$t("core.alerts.error_refresh")}`
                    );
                }
                this.$store.state.showPageLoading = false;
            },
            showActionBar() {
                return !this.systemObject || this.isSystemOrg();
            },
            isSystemOrg() {
                return this.$store.state.org.administration == true;
            },
            isObjectTags() {
                let tags = this.object.tags;
                return tags && tags.length > 0 ? true : false;
            },
            editSelected() {
                if (this.actionEdit) {
                    this.actionEdit(this.object);
                }
            },
            deleteSelected() {
                if (this.actionDelete) {
                    this.actionDelete(this.object);
                }
            },
            actionBarButtons() {
                let result = [];

                if (this.extraDisplayActions) {
                    this.extraDisplayActions.forEach((el) => {
                        if (el.if) {
                            if (el.if(this.object)) {
                                result.push(el);
                            }
                        } else {
                            result.push(el);
                        }
                    });
                }
                return result.concat([
                    {
                        type: "edit",
                        action: this.editSelected,
                    },
                    {
                        type: "delete",
                        action: this.deleteSelected,
                    },
                ]);
            },
            loadOptionsMapping() {
                let mapping = {};
                this.navigationInfo.extraColumns.forEach((property) => {
                    if (property.options) {
                        mapping[property.property] = property.options;
                    }
                });
                this.optionsMapping = mapping;
            },
            selectionAction(option) {
                this.actionEdit(this.object);
            },
            cancelForm() {
                this.cancelObjectChanges();
            },
            tagName(tagId) {
                const tag = this.mappedTags[tagId];
                return tag.name;
            },
            tagStyle(tagId) {
                const tag = this.mappedTags[tagId];
                let colorText = tag.color || "#000000";
                let color = bestContrast(colorText);
                return {
                    color,
                    "background-color": colorText,
                    border: "3px",
                };
            },
        },
    };
</script>

<style scoped>
    @import "../../assets/styleForm.css";
    .position {
        margin-left: 1rem;
    }
    .text-block {
        display: block;
        margin-left: 1rem;
    }
</style>
