<template>
    <div id="ChartArea">
        <div :id="idElement">
            <span v-if="title" class="h4 font-weight-bold p-2">{{ title }}</span
            ><br />
            <apexchart
                :type="this.typeChart || 'area'"
                :height="this.height"
                ref="chart"
                :options="chartOptions"
                :series="series"
            ></apexchart>
        </div>
    </div>
</template>

<script>
    import VueApexCharts from "vue-apexcharts";
    import { any, string } from "joi";
    export default {
        name: "ChartArea",
        mounted() {},
        components: {
            apexchart: VueApexCharts,
        },
        props: {
            dataModel: {
                type: Array,
            },
            idElement: {
                type: String,
            },
            height: {
                type: Number,
            },
            colors: {
                type: Array,
            },
            isFormat: {
                type: String,
            },
            annotations: {
                type: Object,
            },
            title: {
                type: String,
            },
            typeChart: {
                type: String,
            },
            group: {
                type: String,
            },
            valueRange: {
                type: Object,
            },
        },

        data() {
            return {
                series: this.normalizeData(),
                chartOptions: {
                    chart: {
                        id: this.idElement,
                        type: this.typeChart || "area",
                        height: this.height,
                        zoom: {
                            autoScaleYaxis: true,
                        },
                        group: this.group || null,
                    },
                    legend: {
                        show: true,
                    },
                    opposite: true,
                    colors: this.colors,
                    dataLabels: {
                        enabled: false,
                    },
                    xaxis: {
                        type: "datetime",
                        labels: {
                            datetimeUTC: false,
                            datetimeFormatter: {
                                year: "yyyy",
                                month: "MMM yy",
                                day: "dd MMM HH:mm",
                                hour: "HH:mm",
                            },
                            format: "dd MMM HH:mm",
                        },
                    },
                    stroke: {
                        width: [2, 2, 2],
                        curve: "smooth",
                    },
                    yaxis: {
                        minWidth: 40,
                        labels: {
                            minWidth: 34,
                            tickDecimals: 2,
                            axisLabelUseCanvas: true,

                            formatter: (val) => {
                                if (this.isFormat == "days") {
                                    return this.$utilities.secondsToDhm(val);
                                }
                                if (this.isFormat == "minutes") {
                                    return this.$utilities.timeCronJobs(val);
                                }
                                if (this.isFormat == "bytes") {
                                    return this.$utilities.formatBytes(val);
                                }
                                if (this.isFormat == "bps") {
                                    return this.$utilities.formatBitsPerSecond(val);
                                }
                                if (this.isFormat == "porcentage") {
                                    return this.$utilities.percent(val);
                                }
                                if (this.isFormat == "ms") {
                                    return this.$utilities.timeMilliseconds(
                                        val
                                    );
                                }
                                if (this.isFormat == "degrees") {
                                    return this.$utilities.degreesCelsius(val);
                                }
                                if (this.isFormat == "voltage") {
                                    return this.$utilities.formatVol(val);
                                }
                                if (this.isFormat == "amps") {
                                    return this.$utilities.formatAmp(val);
                                }
                                if (this.isFormat == "rpm") {
                                    return this.$utilities.formatRpm(val);
                                }
                                if (this.isFormat == "power") {
                                    return this.$utilities.formatPower(val);
                                }
                                if (this.isFormat == "reactivepower") {
                                    return this.$utilities.formatReactivePower(val);
                                }
                                if (this.isFormat == "apparentpower") {
                                    return this.$utilities.formatApparentPower(val);
                                }
                                if (this.isFormat == "hertz") {
                                    return this.$utilities.formatHertz(val);
                                }

                                return val ? val.toFixed(2) : "";
                            },
                        },
                    },
                    tooltip: {
                        x: {
                            format: "dd MMM HH:mm",
                        },
                    },
                    fill: {
                        type: "gradient",
                        gradient: {
                            shadeIntensity: 1,
                            opacityFrom: 0.7,
                            opacityTo: 0.9,
                            stops: [0, 100],
                        },
                    },
                    annotations: this.loadAnnotations(),
                },
            };
        },
        methods: {
            normalizeData() {
                let filteredData = this.dataModel;
                //ToDo: Normalizar los datos
                /*
            
            

            if (this.valueRange && this.valueRange.min) { 
                filteredData = filteredData.filter(
                    (valor) => valor.y >= this.valueRange.min
                );
            }

            if (this.valueRange && this.valueRange.max) {
                filteredData = filteredData.filter(
                    (valor) => valor.y <= this.valueRange.max
                );
               
            }
            */
                return filteredData;
            },
            loadAnnotations() {
                if (this.annotations && this.annotations.length > 0) {
                    var result = {
                        position: "front",
                        yaxis: [],
                    };
                    this.annotations.forEach((annotation) => {
                        result.yaxis.push({
                            y: annotation.value,
                            borderColor: annotation.color,
                            label: {
                                borderColor: annotation.color,
                                style: {
                                    color: annotation.colorFont,
                                    background: annotation.color,
                                },
                                text: annotation.text,
                            },
                        });
                    });
                    return result;
                } else {
                    return {};
                }
            },
        },
    };
</script>
<style scoped></style>
