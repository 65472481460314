<template>
    <div id="home">
        <ModalAlert
            v-if="this.$store.state.modal"
            :modal="this.$store.state.modal"
        />

        <NavigationBar
            :showProbeSelector="this.showProbeSelector"
            :showSearchButton="this.showSearchButton"
        />

        <div class="wrapper">
            <SideBar :navigationGroups="navigationGroups" />

            <div id="content">
                <LoadingState
                    class="fixed-top"
                    style="max-height: 5px"
                    v-if="this.$store.state.showPageLoading"
                />

                <img
                    class="spinner"
                    src="../../assets/animated-logo.gif"
                    v-if="this.$store.state.showPageLoading"
                />

                <transition name="fade" mode="out-in">
                    <Component
                        v-if="
                            componentFor(
                                this.$store.state.selectedDashboardTab
                            ) && this.isLoaded
                        "
                        :is="
                            componentFor(this.$store.state.selectedDashboardTab)
                        "
                        v-bind="currentProperties()"
                    />
                </transition>
            </div>
        </div>

        <v-tour
            v-if="tourLoaded"
            :name="tour.name"
            :steps="tour.translatedSteps()"
            :options="tour.options()"
        ></v-tour>
    </div>
</template>

<script>
    import SideBar from "./SideBar.vue";
    import NavigationBar from "./NavigationBar.vue";

    export default {
        name: "Home",

        mounted() {
            document.documentElement.style.overflow = "auto";

            //Check if not logged in
            if (!this.checkValidSession()) {
                let alertInfo = {
                    state: this.$store.state,
                    title: this.$t("core.common.not_subscribed"),
                    message: this.$t("core.common.not_subscribed_message"),
                    bg: "danger",
                    timeout: 10,
                };

                this.$utilities.showAlert(alertInfo);
                this.$utilities.cleanUpAndGoToLogin(this);
            } else {
                this.loadData();
                this.$watch(
                    () => {
                        return this.$store.state.status;
                    },
                    () => {
                        this.showAlert(this.$store.state.status);
                    }
                );
            }
        },
        data() {
            return {
                isLoaded: false,
                tourLoaded: false,
                nIntervalId1m: null,
            };
        },
        props: [
            "navigationGroups",
            "tabMapping",
            "tour",
            "showProbeSelector",
            "showSearchButton",
        ],
        components: {
            SideBar,
            NavigationBar,
        },
        methods: {
            checkValidSession() {
                if (
                    JSON.parse(this.$cookie.get("Account")) &&
                    JSON.parse(this.$cookie.get("Org"))
                ) {
                    this.$store.state.org = JSON.parse(this.$cookie.get("Org"));
                    this.$store.state.account = JSON.parse(
                        this.$cookie.get("Account")
                    );
                }

                if (
                    this.$store.state.org == null ||
                    this.$store.state.account == null
                ) {
                    return false;
                }

                const checkSubscriptionCode = this.$store.state
                    .checkSubscription;

                if (checkSubscriptionCode) {
                    const org = this.$store.state.org;

                    const productSubscription = org.subscriptions.find(
                        (sub) => sub.code == checkSubscriptionCode
                    );

                    if (!productSubscription) {
                        return false;
                    } else {
                        const now = new Date();

                        const trialEnd = new Date(
                            productSubscription.trial_expiration || 0
                        );
                        const subscriptionPeriodEnd = new Date(
                            productSubscription.subscription_expiration || 0
                        );

                        if (
                            productSubscription.manual ||
                            trialEnd > now ||
                            subscriptionPeriodEnd > now
                        ) {
                            return true;
                        }
                        return false;
                    }
                }

                return true;
            },
            async loadData() {
                try {
                    this.$store.state.showPageLoading = true;
                    await this.loadObjectSystem();
                    this.$store.state.systemObjectsLoaded = true;
                    await this.downloadSchemas();
                    this.getCookie();
                    this.isLoaded = true;
                    this.$store.state.showPageLoading = false;

                    if (this.tour && !this.tour.hasBeenSeen()) {
                        this.tourLoaded = true;
                        const self = this;
                        this.$nextTick(() => {
                            self.$store.state.isToggle = false;
                            self.$tours[self.tour.name].start();
                            self.tour.markSeen();
                        });
                    }
                } catch (error) {
                    alert(error);
                    this.controlError(error);
                }
            },
            getCookie() {
                let currentHost = "localhost";
                if (process.env.VUE_APP_ICONICO_COOKIE_HOST) {
                    currentHost = process.env.VUE_APP_ICONICO_COOKIE_HOST;
                }
                let Account = JSON.parse(this.$cookie.get("Account"));
                let Org = JSON.parse(this.$cookie.get("Org"));
                if (Org == null && Account == null) {
                    this.$cookie.set(
                        "Org",
                        JSON.stringify(this.$store.state.org),
                        {
                            expires: "24h",
                            domain: currentHost,
                        }
                    );
                    this.$cookie.set(
                        "Account",
                        JSON.stringify(this.$store.state.account),
                        { expires: "24h", domain: currentHost }
                    );
                }
            },

            componentFor(selectedTab) {
                return this.tabMapping[selectedTab];
            },
            changeToggle() {
                this.$store.state.isToggle = !this.$store.state.isToggle;
            },
            currentProperties() {
                if (this.$store.state.selectedDashboardTab === "account") {
                    return {
                        closeSelected: () => {
                            this.$store.state.selectedDashboardTab = "setting";
                        },
                    };
                }
                return {};
            },
            showAlert(status) {
                let title = "";
                let bg = "";
                let message = "";

                if (status < 400) {
                    title = this.$t("core.alerts.success");
                    bg = "success";
                } else {
                    title = this.$t("core.alerts.error");
                    bg = "danger";
                }
                if (this.$store.state.message) {
                    message = this.$store.state.message;
                }
                const self = this;
                let hideCallback = function() {
                    if (status == 403 || status == 401) {
                        self.$cookie.delete("Account");
                        self.$store.state.account = null;
                        let Org = (this.$store.state.org = Org);
                        self.$router.push("/" + Org.path);
                    }

                    self.$store.state.status = null;
                    self.$store.state.message = null;
                };

                let alertInfo = {
                    state: this.$store.state,
                    title,
                    message,
                    bg,
                    hideCallback,
                    timeout: 3,
                };

                this.$utilities.showAlert(alertInfo);
            },
            modulePath() {
                let path = this.$store.state.selectedObject.toLowerCase();
                return path;
            },
            componentName() {
                return this.$store.state.selectedObject;
            },
            async downloadSchemas() {
                await this.$system.downloadSchemas(
                    this.$iconico,
                    this.$store.state
                );
            },
            async loadObjectSystem() {
                await this.$system.downloadObjects(
                    this.$iconico,
                    this.$store.state,
                    this.$store.state.downloadSystemObjects
                );
            },
            countOrder() {
                let config = {
                    org: this.$store.state.org,
                    authToken: this.$store.state.account.token,
                    object: "order",
                    parameters: {
                        params: {
                            page: 1,
                            limit: 1,
                            find: { status: "New" },
                        },
                    },
                };
                this.$iconico
                    .get_objects(config)
                    .then((result) => {
                        const response = result.data;

                        let newObjects = response.data.docs;
                        if (newObjects) {
                            this.$store.state.countOrder =
                                newObjects[0].products.length;
                        }
                    })
                    .catch((error) => {
                        this.controlError(error);
                    });
            },
            controlError(error) {
                if (this.$store.state.status) {
                    this.$store.state.status = error.response.status;
                    this.$store.state.message = error.message;
                }
            },
        },
    };
</script>

<style scoped>
    @import "../../assets/styleSideBar.css";
    @import "../../assets/styleGeneral.css";
    #home {
        height: 100%;
        width: 100%;
    }
    #content {
        padding-top: 2%;
    }
</style>
