export default {
    common: {
        general_search: 'General Search',
        invite: 'Invite',
        cancel: 'Cancel',
        add: 'Add',
        default: 'Default',
        save: 'Save',
        save_and_add: 'Save & Add Another',
        edit: 'Edit',
        delete: 'Delete',
        close: 'Close',
        latitude: 'Latitude',
        longitude: 'Longitude',
        email: 'Email',
        name: 'Name',
        path: 'Path',
        run: 'Run',
        first_name: 'First Name',
        last_name: 'Last Name',
        yes: 'Yes',
        no: 'No',
        version: 'Version',
        role: 'Role',
        change_logo: 'Change Logo',
        logo: 'Logo',
        mobile: 'Mobile',
        server: 'Network',
        servers: 'Networks',
        device: 'Device',
        script: 'Script',
        scripts: 'Scripts',
        file: 'File',
        code: 'Code',
        app_code: 'App',
        method: 'HTTP Method',
        object: 'Object',
        confirm_delete: 'Delete Object',
        confirm_reload: 'Restore Object',
        delete_body: 'Are you sure you want to delete',
        reload_body: 'Are you sure you want to Restore',
        time: 'Firing Time',
        password: 'Password',
        page_size: 'Page Size',
        token: 'Token',
        parent: 'Parent',
        event: 'Event',
        events: 'Events',
        cron: 'Cron',
        description: 'Description (Optional)',
        status: 'Status',
        ip: 'IP',
        total_cycles: 'Total Cycles',
        manufacturer: 'Manufacturer',
        devices: 'Devices',
        update_wireless: 'Update Wireless',
        type: 'Type',
        date: 'Date',
        notification_config: 'Configuration Notification',
        interface: 'Interface',
        interfaces: 'Interfaces',
        uptime: 'Uptime',
        ram: 'RAM',
        cpu: 'CPU',
        disk: 'Disk',
        search: 'Search',
        operator: 'Operator',
        user: 'User',
        administrator: 'Administrator',
        users: 'Users',
        create_many: 'Create Many',
        details: 'Details',
        content: 'Content',
        schema: 'Schema',
        schemas: 'Schemas',
        required: 'Required',
        image: 'Image',
        probe_refresh: 'Network Refresh',
        type_device: 'Type Device',
        active: 'Active',
        sign_out: 'Sign Out',
        inactive: 'Inactive',
        resend: 'Resend',
        deactivate: 'Deactivate',
        device_name: 'Device Name',
        hostname: 'Hostname',
        ipv4: 'IP v4',
        ipv6: 'IP v6',
        ssh_user: 'User',
        ssh_pass: 'Password',
        port: 'Port',
        api_port: 'API Port',
        ssh_port: 'SSH Port',
        snmp_community: 'SNMP Community',
        snmp_version: 'SNMP Version',
        probe_time: 'Network Time',
        sensor_category: 'Sensor Category',
        sensors: 'Sensors',
        resources: 'Resources',
        mqtt: 'MQTT',
        log: 'Log',
        logs: 'Logs',
        cpu_load: 'CPU Load',
        memory_used: 'Memory Used',
        disk_used: 'Disk Used',
        temperature: 'Temperature',
        temperature_cpu: 'CPU Temperature',
        temperature_board: 'Board Temperature',
        current: 'Current',
        fan_speed: 'Fan Speed',
        voltage_psu: 'Voltage PSU',
        current_psu: 'Current PSU',
        voltage: 'Voltage',
        system_health: 'System Health',
        system_resources: 'System Resources',
        cpuModel: 'CPU Model',
        last_probe: 'Last Network',
        log_probe: 'Log Network',
        lang: 'Language',
        sensor: 'Sensor',
        values: 'Values',
        value: 'Value',
        consumption: 'Power Consumption',
        color: 'Color',
        tag: 'Tag',
        tags: 'Tags',
        modules: 'Modules',
        module: 'Module',
        notification: 'Notification',
        action: 'Action',
        actions: 'Actions',
        event_code: 'Event Code',
        action_user_tag: 'User Tag',
        event_severity: 'Event Severity',
        action_user_id: 'Action User Id',
        notifications: 'Notifications',
        all: 'All',
        select: 'Select',
        new: 'New',
        templates: 'Templates',
        test: 'Test',
        configuration: 'Configuration',
        multiple: 'Multiple',
        line: 'Line',
        for: 'For',
        any_device: 'Any Device',
        send: 'Send',
        sms: 'SMS',
        app: 'App',
        whatsApp: 'WhatsApp',
        telegram: 'Telegram',
        telegram_bot: 'View Telegram Bot',
        telegram_installed: 'Telegram is installed to this organization',
        telegram_installation:
            'Invite Iconico_Bot to a Telegram channel and run the following command:',
        slack: 'Slack',
        team_name: 'Team Name',
        channel: 'Channel',
        remove_slack: 'Remove Slack',
        to: 'To',
        recipients: 'Recipients',
        subject: 'Subject',
        event_device: 'Action Device',
        hours: 'Hours',
        days: 'Days',
        no_label: 'No Label',
        is: 'Is',
        connection: 'Connection',
        error_save: 'Error creating a new Tag',
        probe: 'Network',
        dashboard: 'Dashboard',
        reload: 'Force Refresh',
        add_device: 'Add Device',
        category_name: 'Category',
        installation: 'Installation',
        offline_devices: 'Offline Devices',
        not_subscribed: 'Not Subscribed',
        not_subscribed_message:
            'You are not subscribed to this application. Visit the administration panel and install it to use it.',
        info_nav: 'You have',
        info_trialdays: 'trial days left, rembember to add a payment method',
        identifier: 'Identifier',
        unit: 'Unit',
    },
    servers: {
        online: 'Online',
        offline: 'Offline',
        probing: 'Probing',
    },
    users: {
        invited: 'Invited',
        active: 'Active',
        invite: 'Invite User',
        probe: 'Network',
    },
    sensor: {
        tension: 'tension',
        rtt_avr: 'Round Trip Time Average',
        host_live: 'Host Live',
        pck_lost: 'Packets Lost',
        cpu: 'CPU',
        used_memory: 'Memory in Use',
        used_disk: 'Disk in Use',
        sfp_rx_power: 'sfp_rx_power',
        sfp_tx_power: 'sfp_tx_power',
        sfp_temperature: 'sfp_temperature',
        temperature: 'temperature',
        cpu_temperature: 'cpu_temperature',
        voltage: 'Voltage',
        current: 'Current',
        prefix_count: 'prefix_count',
        power: 'Power',
        signal_strength: 'signal_strength',
        signal_strength_ch0: 'signal_strength_ch0',
        signal_strength_ch1: 'signal_strength_ch1',
        signal_strength_ch2: 'signal_strength_ch2',
        signal_to_noise: 'signal_to_noise',
        noise: 'Noise',
        tx_ccq: 'tx_ccq',
        rx_ccq: 'rx_ccq',
        tx_signal_strength: 'tx_signal_strength',
        tx_signal_strength_ch0: 'tx_signal_strength_ch0',
        tx_signal_strength_ch1: 'tx_signal_strength_ch1',
        tx_signal_strength_ch2: 'tx_signal_strength_ch2',
    },
    electric: {
        voltage_a: 'Phase A-B Voltage',
        voltage_b: 'Phase B-C Voltage',
        voltage_c: 'Phase C-A Voltage',
        current_a: 'Phase A Current',
        current_b: 'Phase B Current',
        current_c: 'Phase C Current',
        power_a: 'Phase A Active Power',
        power_b: 'Phase B Active Power',
        power_c: 'Phase C Active Power',
        power_total: 'Total Active Power',
        voltage_out_a: 'Phase A Voltage',
        voltage_out_b: 'Phase B Voltage',
        voltage_out_c: 'Phase C Voltage',
        reactive_power_a: 'Phase A Reactive Power',
        reactive_power_b: 'Phase B Reactive Power',
        reactive_power_c: 'Phase C Reactive Power',
        reactive_power_total: 'Total Reactive Power',
        apparent_power_a: 'Phase A Apparent Power',
        apparent_power_b: 'Phase B Apparent Power',
        apparent_power_c: 'Phase C Apparent Power',
        apparent_power_total: 'Total Apparent Power',
        power_factor_a: 'Phase A Power Factor',
        power_factor_b: 'Phase B Power Factor',
        power_factor_c: 'Phase C Power Factor',
        power_factor_total: 'Total Power Factor',
        frequency: 'Frequency',
    },
    measure: {
        voltage: 'V (Voltage)',
        kwh: 'kWh (Kilo Watt / Hour)',
        current: 'A (Amps)',
        milliseconds: 'MS (Milliseconds)',
        percentage: '% (Percentage)',
        megabytes: 'MB (Megabyte)',
        megabitspersecond: 'Mbps (Megabits per second)',
        gigabyte: 'GB (Gigabyte)',
        degrees: '°C (Celsius Degrees)',
        hertz: 'Hz (Hertz)',
    },
    device_home: {
        ap: 'AP',
        cpe: 'CPE',
        router: 'Router',
        edgeswitch: 'EdgeSwitch',
        switch: 'Switch',
        other: 'Other',
        no_sensor: 'No Sensor for this Device',
        manufacturer_type: 'Manufacturer & Type',
        basic_information: 'Basic Information',
        access: 'Access',
        location: 'Location (Optional)',
        tags: 'Tags (Optional)',
        active_device:
            'Only active devices are monitored, it’s useful to create devices and leave inactive if you want to keep them here but they are not yet connected to your network.',
    },
    sign_in: {
        please: 'Please Sign In',
        please_to: 'Please sign in to',
        password: 'Password',
        login: 'Login',
        sign_in: 'Sign In',
        forgot_your_password: 'Forgot your password?',
        reset_password: 'Reset Password',
        reset: 'Reset',
        lang: 'Language',
        error_signin: 'Invalid Credentials',
        error_password: 'Check your username or password',
    },
    startup: {
        line1:
            'Iconico is a complete platform for Networking Software with several applications built in.',
        line2: 'Stay Connected',
    },
    navigation: {
        run: 'Run',
        previous: 'Previous',
        next: 'Next',
        settings: 'Settings',
        iconico_apps: 'Iconico Apps',
    },
    choose_org: {
        choose_organization: 'Continue',
        organization_path: 'Organization Path',
        type_name: 'Type the path of your organization',
        create_organization: 'Create Organization',
        org_not_found: 'Organization Not Found',
        org_error: 'There is no organization with code ',
        org_check: '. Please check for errors or create a new one',
    },
    password_reset: {
        reset_password: 'Reset Password',
        invitation: 'Accept Invitation',
        choose_password: 'Choose Password',
        confirm_email: 'Confirm Email',
        save: 'Save',
        reset: 'Reset',
        accept_invitation: 'Accept Invitation',
    },
    await_confirmation: {
        done: 'Done!',
        instructions:
            'Please check your email and follow instructions to complete the creation of your organization',
    },
    create_org: {
        org_path: 'Path',
        org_name: 'Name',
        create_organization: 'Create Organization',
        administrator_info: 'Administrator',
        organization_info: 'Organization',
        success_message:
            'Organization Created. Please check your email to continue.',
        error_message:
            'There was an error trying to create your organization. Contact support@iconico.us if the problem persists.',
    },
    articles: {
        generic: 'the',
        male: 'the',
        female: 'the',
        account: 'the',
        server: 'the',
        device: 'the',
        sensor: 'the',
    },
    objects: {
        account: 'Account',
        server: 'Network',
        device: 'Device',
        sensor: 'Sensor',
    },
    account: {
        account_settings: 'Account Settings',
        modified: 'Account modified',
        picture: 'Picture',
        language: 'Language',
        change_password: 'Change Password',
        save_password: 'Save Password',
    },
    content: {
        confirm_delete: 'Are you sure you want to delete ',
        user: 'the User',
        object: 'the Object',
        delete_title: 'Confirmation',
        delete_button: 'Delete',
    },
    empty: {
        title: 'No Elements',
        title_search: 'No Matches',
        message: 'No objects found.',
        message_search: 'No object matches your search.',
        empty_message: 'There are no elements at this level.',
        footer: 'You can create objects at any time.',
        back_to: 'Back to',
    },
    notifications: {
        reload_explanation: `Force refresh the probe application to reload all manufacturers, devices, sensors, and restart the probing scheduler.`,
        notifications: 'Notifications',
        notification_templates: 'Notification Templates',
        information:
            'Notification templates contain the content structure of a particular notification type. Templates are normally used with parameters to fill in additional information.',
        parameters: 'For this event we have the following parameters',
        content:
            'Notification variables must be enclosed in curly braces {var}',
        predetermined: 'Predetermined',
    },
    create_multiple: {
        save_multiple: '{count} records were saved successfully',
    },
    alerts: {
        probe_refresh_success: 'Network Refreshed Successfully!',
        success: 'Success',
        error: 'Error',
        copied_text: 'Copied to clipboard!',
        success_modify: 'Object was correctly modified',
        success_delete: 'Object was correctly deleted',
        success_create: 'Object was correctly created',
        error_refresh: 'There was an error refreshing the object',
        error_create: 'There was an error creating the object',
        error_delete: 'There was an error deleting the object',
        error_modify: 'There was an error modifying the object',
        error_loading: 'There was an error loading required objects',
    },
    validate: {
        required: 'Is not allowed to be empty',
        invalid_ip4_format: 'Invalid IP version 4 format',
        invalid_ip6_format: 'Invalid IP version 6 format',
        number: 'Must be a number',
    },
    install_server: {
        install_server_info:
            "It's necessary to install the Iconico Network via docker in a computer or virtual machine running on your network.",
        install_app:
            'This program will connect to the different devices and perform connectivity Networks, latency checks, etc.',
        install_process:
            'The installation process is simple, download the docker image for the Network and then containerize it',
        install_download_docker: 'Download docker:',
        install_download_server: 'Download the Iconico Network:',
        docker_desktop: 'Install Docker Desktop',
        install_run: 'Create a Container:',
        full_command: 'Full Command',
        install_run_linux: '',
        install_run_linux_command: 'sudo apt install docker.io',
    },
    events: {
        no_event: 'No events found',
        critical: 'Critical',
        serious: 'Serious',
        warning: 'Warning',
        info: 'Info',
        minor: 'Minor',
        severity: 'Severity',
        code: 'Code',
        variable: 'Variable',
        alarm: 'Alarm',
        value_before: 'Value Before',
        value_actual: 'Current value',
        no_event_device: 'No events found for this device',
        device_reboot: 'Device Rebooted',
        device_upgrade: 'Upgrade o Downgrade Version SO',
        device_hardware: 'HARDWARE Changed, Caution',
        firmware_upgrade: 'Upgrade Firmware',
        device_identity: 'Device name change',
    },
    tour: {
        buttons_skip_tour: 'Skip Tour',
        buttons_previous: 'Previous',
        buttons_next: 'Next',
        buttons_finish: 'Finish',
    },
    maps: {
        map_style: 'Map Style',
        streets: 'Streets',
        outdoors: 'Outdoors',
        light: 'Light',
        dark: 'Dark',
        satellite: 'Satellite',
        satellite_streets: 'Satellite Streets',
    },
};
