<template>
    <div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb bg-transparent p-0">
                <li
                    v-for="(crumb, index) in crumbs()"
                    :key="index"
                    class="breadcrumb-item title-section text-uppercase"
                    :class="classForIndex(index)"
                >
                    <a
                        v-if="!last(index)"
                        href="#"
                        class="bread-item mr-1"
                        @click="selected(crumb, index)"
                        >{{ crumbTitle(crumb) }}</a
                    >
                    <img
                        v-if="!last(index)"
                        src="../../assets/chevron.svg"
                        class="chevron"
                    />
                    <span
                        v-if="last(index)"
                        class="bread-last"
                        aria-current="page"
                        >{{ crumbTitle(crumb) }}</span
                    >
                </li>
            </ol>
        </nav>
        <Component
            v-if="loadedData"
            :is="getCurrentComponent()"
            v-bind="getCurrentProperties()"
        >
        </Component>
    </div>
</template>

<script>
    import DefaultComponent from "./DefaultComponent.vue";
    export default {
        name: "Crumbs",
        mounted() {
            this.$store.state.crumbs = [this.rootComponent];
            this.loadedData = true;
        },
        components: {
            DefaultComponent,
        },
        props: ["rootComponent"],
        data() {
            return {
                loadedData: false,
            };
        },
        methods: {
            getCurrentComponent() {
                let crumbs = this.crumbs();

                if (crumbs.length == 0) {
                    return this.DefaultComponent;
                } else {
                    let lastCrumb = crumbs[crumbs.length - 1];

                    return lastCrumb.component || this.DefaultComponent;
                }
            },
            getCurrentProperties() {
                let crumbs = this.crumbs();

                if (crumbs.length == 0) {
                    return {};
                } else {
                    let lastCrumb = crumbs[crumbs.length - 1];
                    return lastCrumb.properties || {};
                }
            },
            crumbs() {
                return this.$store.state.crumbs;
            },
            selected(crumb, selected) {
                this.$store.state.crumbs.forEach((object, index) => {
                    if (crumb == object) {
                        this.$store.state.crumbs = this.$store.state.crumbs.slice(
                            0,
                            selected + 1
                        );
                        if (crumb.component) {
                            this.currentComponent = crumb.component;
                        }
                        if (crumb.properties) {
                            this.currentProperties = crumb.properties;
                        }

                        this.refreshComponent = ++this.refreshComponent;
                    }
                });
            },
            crumbTitle(crumb) {
                return crumb.title;
            },
            last(index) {
                return index >= this.crumbs().length - 1;
            },
            classForIndex(index) {
                if (index == this.crumbs().length - 1) {
                    return "active text-success";
                }
            },
        },
    };
</script>

<style scoped>
    .breadcrumb-item {
        font-size: 1rem !important;
    }

    .chevron {
        height: 1rem;
    }

    .bread-item {
        font-family: "HelveticaNeueLt";
        font-weight: 100;
        font-size: 1rem;
    }

    .bread-last {
        font-family: "HelveticaNeueLt";
        font-weight: 100;
        font-size: 1rem;
    }
</style>
