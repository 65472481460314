<template>
    <div
        class="pb-2"
        :class="{ 'cards-component': isCards }"
        style="min-width: 20rem"
    >
        <div class="borderTitle" v-if="title && isCards">
            <h5
                class="m-2 pl-2 table-title text-uppercase"
                style="display: inline-block"
            >
                {{ title }}
            </h5>
        </div>
        <div class="clearfix">
            <div class="spinner-container" v-show="loading">
                <img
                    class="small-spinner mx-auto d-block"
                    src="../../assets/animated-logo.gif"
                />
            </div>
            <slot v-show="!loading" />
        </div>
    </div>
</template>

<script>
    export default {
        name: "DisplayCard",
        props: {
            title: {
                type: String,
            },
            loading: {
                type: Boolean,
                default: false,
            },
            isCards: {
                type: Boolean,
                default: true,
            },
        },
        data() {
            return {};
        },
    };
</script>

<style scoped lang="scss">
    .spinner-container {
        min-height: 5rem;
    }

    .small-spinner {
        aspect-ratio: 1;
        min-width: 2rem;
        max-width: 4rem;
    }
</style>
