<template>
    <div>
        <Tabs :tabs="tabs" />
    </div>
</template>

<script>
import UsersTab from "./UsersTab.vue";
import NetworksTab from "./NetworksTab.vue";
export default {
    name: "TabsHome",
    data() {
        return {
            tabs: [
                {
                    id: "users",
                    display: "core.common.users",
                    component: UsersTab,
                },
                {
                    id: "networks",
                    display: "core.common.servers",
                    component: NetworksTab,
                },
            ],
        };
    },
    mounted() {},
    components: {
        UsersTab,
        NetworksTab,
    },
    methods: {},
};
</script>

<style scoped>
</style>
