<template>
    <div>
        <auth-frame>
            <div class="container-fluid h-100">
                <form class="form-signin h-100" @submit="onSubmit">
                    <div v-if="this.$store.state.org" class="choose-subtitle">
                        {{ this.$store.state.org.name }}: {{ subtitle() }}
                    </div>

                    <div
                        v-if="!this.$store.state.org"
                        class="text-secondary text-left mb-3 font-weight-normal"
                    >
                        {{ subtitle() }}
                    </div>

                    <div
                        v-if="queryEmail"
                        class="text-secondary text-left my-4 font-weight-normal"
                    >
                        {{ this.$t("core.common.email") }}: {{ queryEmail }}
                    </div>

                    <label for="inputEmail" class="sr-only">{{
                        $t("core.common.email")
                    }}</label>
                    <input
                        type="email"
                        id="inputEmail"
                        :hidden="queryEmail"
                        v-model="email"
                        class="form-control form-control-lg"
                        :class="emailClass()"
                        :placeholder="emailPlaceholder()"
                        required
                        :autofocus="autofocusEmail()"
                    />

                    <label for="inputToken" class="sr-only">{{
                        $t("core.common.token")
                    }}</label>
                    <input
                        type="text"
                        id="inputToken"
                        v-model="token"
                        hidden
                        class="form-control form-control-lg"
                        :placeholder="$t('core.common.token')"
                        required
                    />

                    <label for="inputNewPassword" class="sr-only">{{
                        passwordPlaceholder()
                    }}</label>
                    <input
                        type="password"
                        id="inputNewPassword"
                        v-model="newpassword"
                        class="form-control form-control-lg"
                        :class="passwordClass()"
                        :placeholder="passwordPlaceholder()"
                        required
                        :autofocus="autofocusPassword()"
                    />

                    <button
                        class="
                            btn btn-lg
                            create-button
                            btn-primary btn-block
                            my-4
                        "
                        type="submit"
                    >
                        <span
                            class="spinner-grow postition-relative float-right"
                            v-if="isSpinner"
                            role="status"
                            aria-hidden="true"
                        ></span>
                        {{ buttonTitle() }}
                    </button>
                </form>
            </div>
        </auth-frame>
    </div>
</template>

<script>
    import AuthFrame from "../forms/AuthFrame.vue";

    export default {
        name: "UpdatePassword",
        computed: {
            org() {
                return this.$store.state.org;
            },
        },
        components: {
            AuthFrame,
        },
        data() {
            return {
                orgPath: null,
                email: null,
                queryEmail: null,
                newpassword: null,
                token: null,
                isSpinner: false,
                path: null,
            };
        },
        mounted() {
            const routePath = this.$router.currentRoute.path;

            this.path = routePath.slice(1).split("/")[2];
            this.token = routePath.slice(1).split("/")[3];
            this.queryEmail = this.$route.query.email;

            if (this.queryEmail) {
                this.email = this.queryEmail;
            }

            if (this.org == null) {
                this.orgPath = routePath.slice(1);
                this.orgPath = this.orgPath.split("/")[0];
                this.$iconico.get_org_internal(
                    this.orgPath,
                    (orgInfo, error) => {
                        if (orgInfo == null || error != null) {
                            alert("Invalid Organization Path." + error);
                            this.$router.push("/");
                        } else {
                            this.$store.state.org = orgInfo;
                        }
                    }
                );
            }
        },
        methods: {
            isAcceptInvitation() {
                return this.path != "updatePassword";
            },
            subtitle() {
                if (this.isAcceptInvitation()) {
                    return this.$t("core.password_reset.invitation");
                } else {
                    return this.$t("core.password_reset.reset_password");
                }
            },
            autofocusEmail() {
                return !this.queryEmail;
            },
            autofocusPassword() {
                return this.queryEmail;
            },
            emailClass() {
                if (!this.queryEmail) {
                    return " topInput ";
                }

                return "";
            },
            passwordClass() {
                if (!this.queryEmail) {
                    return " bottomInput ";
                }

                return "";
            },
            emailPlaceholder() {
                return this.$t("core.password_reset.confirm_email");
            },
            passwordPlaceholder() {
                return this.$t("core.password_reset.choose_password");
            },
            buttonTitle() {
                if (this.isAcceptInvitation()) {
                    return this.$t("core.password_reset.save");
                } else {
                    return this.$t("core.password_reset.reset");
                }
            },
            showAlert(title, message, bg) {
                let self = this;
                let showCallback = function() {
                    self.isSpinner = false;
                };

                let hideCallback = function() {
                    if (bg != "danger") {
                        self.$router.push("/" + self.orgPath);
                    }
                };

                let alertInfo = {
                    state: this.$store.state,
                    title,
                    message,
                    bg,
                    hideCallback,
                    showCallback,
                    timeout: 3,
                };

                this.$utilities.showAlert(alertInfo);
            },
            onSubmit(event) {
                this.isSpinner = true;
                event.preventDefault();
                const body = {
                    email: this.email,
                    token: this.token,
                    invitation_token: this.token,
                    password: this.newpassword,
                };

                const org = this.$store.state.org;
                this.$iconico.account_accept(
                    org,
                    this.path,
                    body,
                    (response, error) => {
                        //Billy here
                        if (error != null || response == null) {
                            this.showAlert(
                                this.isAcceptInvitation()
                                    ? "Invitation"
                                    : "Reset Password",
                                error,
                                "danger"
                            );
                        } else {
                            this.showAlert(
                                "Success",
                                this.isAcceptInvitation()
                                    ? "Invitation accepted!!!"
                                    : "Password reset successfully!!!",
                                "success"
                            );
                        }
                    }
                );
            },
        },
    };
</script>

<style scoped lang="scss">
    .choose-subtitle {
        font-family: HelveticaNeue;
        font-size: 1.3rem;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: 2.33px;
        color: var(--primary);
    }

    .form-signin {
        text-align: center;
        width: 80%;
        margin: auto;
    }

    .form-signin .form-control {
        height: auto;
        font-size: 1rem;
    }
    .form-signin .form-control:focus {
        z-index: 2;
    }
</style>
