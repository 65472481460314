<template>
<div>
    <div class="flex-container">
        <div v-for="(content, index) in cardsContent" :key="index">
            <FlexCard
                :data="content.cardData"
                :info="content.cardInfo"
                :title="content.cardTitle"
            />
        </div>
    </div>
    <div class="list-size">
        <ListCard
        :title="titleListCard"
        />

    </div>
</div>
</template>

<script>
import FlexCard from "./dashboard/FlexCard.vue";
import ListCard from "./dashboard/ListCard.vue"
export default {
    name: "Dashboard",
    components: {
        FlexCard,
        ListCard,
    },
    data() {
        return {
            titleListCard:"test",
            
            cardsContent: [
                {
                    cardTitle: "Titulo Test",
                    cardData: [
                        {
                            title: "Online",
                            value: "20",
                            color: "danger",
                        },
                        {
                            title: "test",
                            value: "30",
                            color: "success",
                        },
                        {
                            title: "test",
                            value: "30",
                            color: "success",
                        },
                        {
                            title: "test",
                            value: "30",
                            color: "success",
                        },
                        {
                            title: "test",
                            value: "20",
                            color: "warning",
                        },
                    ],
                },
                {
                    cardTitle: "Titulo Test",
                    cardInfo: "100000 000 %",
                    cardData: [
                        {
                            title: "Online",
                            value: "20",
                            color: "danger",
                        },
                        {
                            title: "Online",
                            value: "30",
                            color: "success",
                        },
                        {
                            title: "Offline",
                            value: "20",
                            color: "warning",
                        },
                    ],
                },
                {
                    cardInfo: "test 123%",
                },
                {
                    cardInfo: "test 123%",
                    cardData: [
                        {
                            title: "Online",
                            value: "20",
                            color: "danger",
                        },
                        {
                            title: "Online",
                            value: "30",
                            color: "success",
                        },
                    ],
                },
                {
                    cardData: [
                        {
                            title: "Online",
                            value: "20",
                            color: "danger",
                        },
                    ],
                },
            ],
        };
    },
};
</script>

<style scoped>
.list-size{
    width: 50%;
}
.flex-container {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}
.flex-container div {
    margin-bottom: 0.3rem;
}
</style>