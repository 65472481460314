<template>
    <div>
        <auth-frame>
            <form class="form-create" @submit="onSubmit">
                <div class="text-secondary float-left mb-3 font-weight-normal">
                    {{ $t("core.create_org.organization_info") }}
                </div>

                <div class="form-group">
                    <input
                        type="text"
                        id="name"
                        v-model="body.name"
                        class="form-control mt-2"
                        :placeholder="$t('core.create_org.org_name')"
                        @input="orgNameChanged()"
                        required
                        autofocus
                    />

                    <input
                        type="text"
                        id="path"
                        v-model="body.path"
                        class="form-control mt-2"
                        :placeholder="$t('core.create_org.org_path')"
                        style="text-transform: lowercase"
                        required
                        autofocus
                    />
                </div>

                <div style="height: 1rem" />
                <div class="text-secondary float-left mb-3 font-weight-normal">
                    {{ $t("core.create_org.administrator_info") }}
                </div>

                <div class="form-group">
                    <input
                        type="text"
                        id="first_name"
                        v-model="body.first_name"
                        class="form-control mt-2"
                        :placeholder="$t('core.common.first_name')"
                        required
                        autofocus
                    />
                    <input
                        type="text"
                        id="last_name"
                        v-model="body.last_name"
                        class="form-control mt-2"
                        :placeholder="$t('core.common.last_name')"
                        required
                        autofocus
                    />
                    <input
                        type="text"
                        id="email"
                        v-model="body.email"
                        class="form-control mt-2"
                        :placeholder="$t('core.common.email')"
                        required
                        autofocus
                    />
                </div>

                <div style="height: 2rem" />

                <button
                    class="btn mx-auto btn-md btn-primary btn-block mt-2 mb-4"
                    type="submit"
                    style="max-width: 20rem"
                >
                    <span
                        class="spinner-grow postition-relative float-right"
                        v-if="isSpinner"
                        role="status"
                        aria-hidden="true"
                    >
                    </span>
                    {{ $t("core.create_org.create_organization") }}
                </button>

                <button
                    class="
                        btn
                        mx-auto
                        btn-md btn-outline-secondary btn-block
                        mt-2
                        mb-4
                    "
                    @click="cancelForm()"
                    style="max-width: 20rem"
                >
                    {{ $t("core.common.cancel") }}
                </button>
            </form>
        </auth-frame>
    </div>
</template>

<script>
    import AuthFrame from "../forms/AuthFrame";

    export default {
        name: "CreateOrg",
        data() {
            return {
                isSpinner: false,
                get_static_image_url: this.$iconico.get_static_image_url,
                body: {
                    name: null,
                    path: null,
                    first_name: null,
                    last_name: null,
                    email: null,
                    lang: this.$i18n.locale,
                },
            };
        },
        mounted() {
            this.$store.state.org = null;
        },
        components: {
            AuthFrame,
        },
        methods: {
            orgNameChanged() {
                let path = /[\w]+/gi.exec(this.body.name);

                if (path) {
                    this.body.path = path[0].toLowerCase();
                }
            },
            cancelForm() {
                this.$store.state.org = null;
                this.$router.push("/");
            },
            showAlert(title, message, bg, timeout, redirectURL = null) {
                let self = this;

                let showCallback = function() {
                    self.isSpinner = false;
                };

                let hideCallback = function() {
                    if (redirectURL) {
                        self.$router.push(redirectURL);
                    }
                };

                let alertInfo = {
                    state: this.$store.state,
                    title,
                    message,
                    bg,
                    showCallback,
                    hideCallback,
                    timeout,
                };

                this.$utilities.showAlert(alertInfo);
            },
            onSubmit(event) {
                this.isSpinner = true;
                event.preventDefault();

                let body = { ...this.body };
                body.lang = this.$i18n.locale || "en";

                this.$iconico.post_org_internal(body, (response, error) => {
                    this.isSpinner = false;

                    if (response != null || error == null) {
                        this.showAlert(
                            this.$t("core.alerts.success"),
                            this.$t("core.create_org.success_message"),
                            "success",
                            3,
                            `/${this.body.path}/await`
                        );
                    } else {
                        this.showAlert(
                            this.$t("core.alerts.error"),
                            `${this.$t(
                                "core.create_org.error_message"
                            )}: ${error || "-"}`,
                            "danger",
                            10
                        );
                    }
                });
            },
        },
    };
</script>

<style lang="scss" scoped>
    .iconico-logo {
        max-width: 230px;
        max-height: 95px;
        width: auto;
        height: auto;
    }

    .form-create {
        text-align: center;
        max-width: 30rem;
        padding: 15px;
        margin: auto;
    }
    .form-create .form-control {
        position: relative;
        box-sizing: border-box;
        height: auto;
        padding: 10px;
        font-size: 16px;
    }
    .form-create .form-control:focus {
        z-index: 2;
    }
</style>
