<template>
    <div class="progress bg-primary">
        <div
            class="
                progress-bar
                bg-success
                progress-bar-striped progress-bar-animated
            "
            :style="barStyle"
        ></div>
    </div>
</template>

<script>
    export default {
        name: "LoadingState",
        computed: {
            barStyle() {
                return {
                    width: this.progress + "%",
                };
            },
        },
        data() {
            return {
                progress: 0.0,
            };
        },
        mounted() {
            this.increaseProgress();
        },
        methods: {
            increaseProgress() {
                const self = this;
                var idIterval = setInterval(function() {
                    self.progress += 10;
                    if (self.progress == 100) {
                        clearInterval(idIterval);
                    }
                }, 50);
            },
        },
    };
</script>

<style></style>
