<template>
    <div>
        <div class="float-right mr-4">
            <button
                v-for="(button, index) in buttons"
                :key="index"
                type="button"
                :disabled="button.disabled"
                :class="classForButton(button)"
                @click="buttonSelected(button)"
            >
                <i class="mr-2 fas" :class="iconForButton(button)" />
                {{ nameForButton(button) }}
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ActionBar",
        props: ["buttons", "object"],
        data() {
            return {
                standardNames: {
                    invite: "core.common.invite",
                    edit: "core.common.edit",
                    delete: "core.common.delete",
                    cancel: "core.common.cancel",
                    save: "core.common.save",
                    run: "core.common.run",
                    save_and_add: "core.common.save_and_add",
                    close: "core.common.close",
                    test: "core.common.test",
                    resend: "core.common.resend",
                    deactivate: "core.common.deactivate",
                    update_wireless: "core.common.update_wireless",
                    reload: "core.common.reload",
                },
                standardClasses: {
                    invite: "btnSuccess",
                    edit: " btnSuccess",
                    delete: " btnDanger",
                    cancel: " btnDark",
                    save: " btnSuccess",
                    run: " btnSuccess",
                    save_and_add: " btnPrimary",
                    close: " btnDark",
                    test: " btnPrimary",
                    resend: " btnPrimary",
                    deactivate: " btnDanger",
                    update_wireless: "btnSuccess",
                    reload: " btnPrimary",
                },
                standardIcon: {
                    invite: "fa-paper-plane",
                    edit: " fa-edit",
                    run: " fa-play",
                    delete: " fa-trash-alt",
                    cancel: " fa-window-close",
                    save: " fa-save",
                    save_and_add: " fa-save",
                    close: " fa-times-circle",
                    test: " fa-sign-in-alt",
                    resend: " fa-undo-alt",
                    deactivate: " fa-ban",
                    update_wireless: " fa-network-wired",
                    reload: " fa-redo-alt",
                },
            };
        },
        methods: {
            iconForButton(button) {
                if (button.type) {
                    return this.standardIcon[button.type] || "";
                }

                return this.$t(button.name || "");
            },
            nameForButton(button) {
                if (button.type) {
                    return this.$t(this.standardNames[button.type] || "");
                }

                return this.$t(button.name || "");
            },
            classForButton(button) {
                const defaultClass =
                    "btn btn-sm pb-2 pt-2 pl-4 pr-4 mr-2 font-bold";
                let additionalClass = "";
                if (button.type) {
                    additionalClass = this.standardClasses[button.type];
                } else if (button.class) {
                    additionalClass = button.class;
                }

                if (button.disabled) {
                    additionalClass = `${additionalClass} disabled `;
                }

                return `${defaultClass} ${additionalClass}`;
            },
            buttonSelected(button) {
                if (button.action) {
                    button.action(this.object || button);
                }
            },
        },
    };
</script>

<style></style>
