import axios from 'axios';

export const constants = {
    role:{
        administrator:'Administrator'
    }
}

function backendHost() {
    const localhost = 'http://localhost:8081';

    return process.env.VUE_APP_ICONICO_BACKEND_HOST || localhost;
}

export function post_socket(config) {
    let headers = {
        'Content-Type': 'application/json',
        'Iconico-Api-Key': config.org.apiKey,
        Authorization: 'Bearer ' + config.authToken,
    };

    var parameters = config.parameters || {};
    parameters.headers = headers;

    let host = backendHost();
    let path =
        host + '/' + config.org.path + '/' + config.object + '/' + config.id;

    if (config.timeout) {
        const httpClient = axios.create();
        httpClient.defaults.timeout = config.timeout;
        config.body.timeout = config.timeout;

        return httpClient.post(path, config.body, parameters);
    } else {
        return axios.post(path, config.body, parameters);
    }
}

export function get_org(config) {
    let headers = {
        'Iconico-Api-Key': config.org.apiKey,
        Authorization: 'Bearer ' + config.authToken,
    };
    var parameters = config.parameters || {};
    parameters.headers = headers;

    let host = backendHost();
    let path = host + '/' + config.org.path;
    return axios.get(path, parameters);
}

export function get_org_internal(orgPath, callback) {
    let host = backendHost();
    let path = host + '/org';

    let params = {
        params: {
            org_path: orgPath,
        },
    };

    axios
        .get(path, params)
        .then(response => {
            callback(response.data, null);
        })
        .catch(error => {
            callback(null, error);
        });
}

export function post_org_internal(body, callback) {
    let host = backendHost();
    let path = host + '/org';
    let headers = {
        'Content-Type': 'application/json',
    };

    axios
        .post(path, body, headers)
        .then(response => {
            callback(response.data, null);
        })
        .catch(error => {
            callback(null, error);
        });
}

export function put_org(config, callback) {
    let headers = {
        'Content-Type': 'application/json',
        'Iconico-Api-Key': config.org.apiKey,
    };

    var parameters = config.parameters || {};
    parameters.headers = headers;

    let host = backendHost();
    let path = host + '/' + config.org.path;
    axios
        .put(path, config.body, parameters)
        .then(response => {
            //this.$store.state.status = response.status;
            callback(response, null);
        })
        .catch(error => {
            callback(null, error);
        });
}

export function toggle_module(config, callback) {
    let headers = {
        'Content-Type': 'application/json',
        'Iconico-Api-Key': config.org.apiKey,
        Authorization: 'Bearer ' + config.authToken,
    };

    var parameters = config.parameters || {};
    parameters.headers = headers;

    let host = backendHost();
    let path =
        host +
        '/' +
        config.org.path +
        '/modules/' +
        config.path +
        '/' +
        config.status;
    axios
        .put(path, config.body, parameters)
        .then(response => {
            //this.$store.state.status = response.status;
            callback(response, null);
        })
        .catch(error => {
            callback(null, error);
        });
}

export function post_object(config) {
    let headers = {
        'Content-Type': 'application/json',
        'Iconico-Api-Key': config.org.apiKey,
        Authorization: 'Bearer ' + config.authToken,
    };

    var parameters = config.parameters || {};
    parameters.headers = headers;

    let host = backendHost();
    let path = host + '/' + config.org.path + '/' + config.object;
    return axios.post(path, config.body, parameters);
}

export function put_object(config) {
    let headers = {
        'Iconico-Api-Key': config.org.apiKey,
        Authorization: 'Bearer ' + config.authToken,
    };

    var parameters = config.parameters || {};
    parameters.headers = headers;

    let host = backendHost();
    let path =
        host +
        '/' +
        config.org.path +
        '/' +
        config.object +
        '/' +
        config.body._id;
    return axios.put(path, config.body, parameters);
}

export function del_object(config) {
    let headers = {
        'Iconico-Api-Key': config.org.apiKey,
        Authorization: 'Bearer ' + config.authToken,
    };
    var parameters = config.parameters || {};
    parameters.headers = headers;

    let host = backendHost();
    let path =
        host +
        '/' +
        config.org.path +
        '/' +
        config.object +
        '/' +
        config.objectId;
    return axios.delete(path, parameters);
}

export function subscribe(config) {
    let headers = {
        'Iconico-Api-Key': config.org.apiKey,
        Authorization: 'Bearer ' + config.authToken,
    };

    var parameters = config.parameters || {};
    parameters.headers = headers;

    let host = backendHost();
    let path = `${host}/${config.org.path}/payments/product/${config.product}/subscribe`;
    return axios.post(path, {}, parameters);
}

export function unsubscribe(config) {
    let headers = {
        'Iconico-Api-Key': config.org.apiKey,
        Authorization: 'Bearer ' + config.authToken,
    };

    var parameters = config.parameters || {};
    parameters.headers = headers;

    let host = backendHost();
    let path = `${host}/${config.org.path}/payments/product/${config.product}/unsubscribe`;
    return axios.post(path, {}, parameters);
}

export function account_login(org, credentials) {
    const headers = {
        headers: {
            'Content-Type': 'application/json',
            'Iconico-Api-Key': org.apiKey || '',
        },
    };

    let host = backendHost();
    let path = host + '/' + org.path + '/account/login';

    return axios.post(path, credentials, headers);
}

export function account_logout(config) {
    let headers = {
        'Iconico-Api-Key': config.org.apiKey,
        Authorization: 'Bearer ' + config.authToken,
    };
    var parameters = config.parameters || {};
    parameters.headers = headers;

    let host = backendHost();
    let path = host + '/' + config.org.path + '/account/logout';
    return axios.post(path, parameters);
}

export function account_resend_invitation(config) {
    let headers = {
        'Iconico-Api-Key': config.org.apiKey,
        Authorization: 'Bearer ' + config.authToken,
    };
    var parameters = config.parameters || {};
    parameters.headers = headers;

    let host = backendHost();
    let path =
        host +
        '/' +
        config.org.path +
        '/account/' +
        config.accountId +
        '/invite';
    return axios.post(path, {}, parameters);
}

export function loadStripeSubscriptions(config) {
    const headers = {
        headers: {
            Authorization: 'Bearer ' + config.authToken,
            'Iconico-Api-Key': config.org.apiKey,
        },
    };

    let host = backendHost();
    let path = host + '/' + config.org.path + '/payments/subscriptions';

    return axios.get(path, headers);
}

export function load_cards(config) {
    const headers = {
        headers: {
            Authorization: 'Bearer ' + config.authToken,
            'Iconico-Api-Key': config.org.apiKey,
        },
    };

    let host = backendHost();
    let path = host + '/' + config.org.path + '/payments/cards';

    return axios.get(path, headers);
}

export function addCard(config) {
    const headers = {
        headers: {
            Authorization: 'Bearer ' + config.authToken,
            'Iconico-Api-Key': config.org.apiKey,
        },
    };

    let host = backendHost();
    let path = host + '/' + config.org.path + '/payments/card';
    let body = config.body;

    return axios.post(path, body, headers);
}

export function account_accept(org, Accountpath, credentials, callback) {
    const headers = {
        headers: {
            'Content-Type': 'application/json',
            'Iconico-Api-Key': org.apiKey,
        },
    };

    let host = backendHost();
    let path = host + '/' + org.path + '/account/' + Accountpath;

    axios
        .post(path, credentials, headers)
        .then(response => {
            callback(response.data, null);
        })
        .catch(error => {
            callback(null, error);
        });
}

export function get_routes_modules(config, callback) {
    let headers = {
        'Iconico-Api-Key': config.org.apiKey,
        Authorization: 'Bearer ' + config.authToken,
        'Cache-Control': 'no-cache, no-store, must-revalidate',
    };

    var parameters = config.parameters;
    parameters.headers = headers;
    let host = backendHost();
    let path = host + '/' + config.org.path + '/routes/' + config.routePath;

    axios
        .get(path, parameters)
        .then(response => {
            callback(response.data, null);
        })
        .catch(error => {
            callback(null, error);
        });
}

export function get_invoices(config) {
    let headers = {
        'Iconico-Api-Key': config.org.apiKey,
        Authorization: 'Bearer ' + config.authToken,
        'Cache-Control': 'no-cache, no-store, must-revalidate',
    };

    var parameters = config.parameters;
    parameters.headers = headers;
    let host = backendHost();
    let path = host + '/' + config.org.path + '/payments/invoices';

    return axios.get(path, parameters);
}

export function get_upcoming_invoices(config) {
    let headers = {
        'Iconico-Api-Key': config.org.apiKey,
        Authorization: 'Bearer ' + config.authToken,
        'Cache-Control': 'no-cache, no-store, must-revalidate',
    };

    var parameters = config.parameters;
    parameters.headers = headers;
    let host = backendHost();
    let path = host + '/' + config.org.path + '/payments/invoices/upcoming';

    return axios.get(path, parameters);
}

export function get_customer(config) {
    let headers = {
        'Iconico-Api-Key': config.org.apiKey,
        Authorization: 'Bearer ' + config.authToken,
        'Cache-Control': 'no-cache, no-store, must-revalidate',
    };

    var parameters = config.parameters;
    parameters.headers = headers;
    let host = backendHost();
    let path = host + '/' + config.org.path + '/payments/customer';

    return axios.get(path, parameters);
}

export function delete_card(config, cardId) {
    let headers = {
        'Iconico-Api-Key': config.org.apiKey,
        Authorization: 'Bearer ' + config.authToken,
    };

    var parameters = config.parameters;
    parameters.headers = headers;
    let host = backendHost();
    let path = host + '/' + config.org.path + '/payments/cards/' + cardId;

    return axios.delete(path, parameters);
}

export function update_customer(config) {
    let headers = {
        'Iconico-Api-Key': config.org.apiKey,
        Authorization: 'Bearer ' + config.authToken,
    };

    var parameters = config.parameters;
    parameters.headers = headers;
    let host = backendHost();
    let path = host + '/' + config.org.path + '/payments/customer';

    return axios.put(path, config.body, parameters);
}

export function get_modules(config, callback) {
    let headers = {
        'Iconico-Api-Key': config.org.apiKey,
        Authorization: 'Bearer ' + config.authToken,
        'Cache-Control': 'no-cache, no-store, must-revalidate',
    };

    var parameters = config.parameters;
    parameters.headers = headers;
    let host = backendHost();
    let path = host + '/' + config.org.path + '/modules';

    axios
        .get(path, parameters)
        .then(response => {
            callback(response.data, null);
        })
        .catch(error => {
            callback(null, error);
        });
}

export function get_base_properties(config, callback) {
    let headers = {
        'Iconico-Api-Key': config.org.apiKey,
        Authorization: 'Bearer ' + config.authToken,
    };

    var parameters = config.parameters;
    parameters.headers = headers;
    let host = backendHost();
    let path = host + '/' + config.org.path + '/schema/base';

    axios
        .get(path, parameters)
        .then(response => {
            callback(response.data, null);
        })
        .catch(error => {
            callback(null, error);
        });
}

export function subscription_status(config) {
    let headers = {
        'Iconico-Api-Key': config.org.apiKey,
        Authorization: 'Bearer ' + config.authToken,
    };

    var parameters = config.parameters;
    parameters.headers = headers;
    let host = backendHost();
    let path =
        host + '/' + config.org.path + '/payments/status/' + config.product;
    return axios.get(path, parameters);
}

export function get_objects(config) {
    let headers = {
        'Iconico-Api-Key': config.org.apiKey,
        Authorization: 'Bearer ' + config.authToken,
    };

    var parameters = config.parameters;
    parameters.headers = headers;
    let host = backendHost();
    let path = host + '/' + config.org.path + '/' + config.object;
    return axios.get(path, parameters);
}

export function get_object(config) {
    let headers = {
        'Iconico-Api-Key': config.org.apiKey,
        Authorization: 'Bearer ' + config.authToken,
    };

    var parameters = config.parameters || {};
    parameters.headers = headers;

    let host = backendHost();
    let path =
        host +
        '/' +
        config.org.path +
        '/' +
        config.object +
        '/' +
        config.objectId;
    return axios.get(path, parameters);
}

export function get_script_file(config) {
    let headers = {
        'Iconico-Api-Key': config.org.apiKey,
        Authorization: 'Bearer ' + config.authToken,
    };

    var parameters = config.parameters || {};
    parameters.headers = headers;

    const host = backendHost();
    const path = `${host}/${config.org.path}/script/file`;

    return axios
        .get(path, parameters)
        .then(result => Promise.resolve(result.data));
}

export function upload_googlestorage(config, callback) {
    let headers = {
        'Iconico-Api-Key': config.org.apiKey,
        Authorization: 'Bearer ' + config.authToken,
    };

    var parameters = {};
    parameters.headers = headers;

    let host = backendHost();
    let path = host + '/' + config.org.path + '/upload';

    axios
        .post(path, config.body, parameters)
        .then(response => {
            callback(response, null);
        })
        .catch(error => {
            callback(null, error);
        });
}

export function del_googlestorage(config, callback) {
    let headers = {
        'Iconico-Api-Key': config.org.apiKey,
        Authorization: 'Bearer ' + config.authToken,
    };
    var parameters = config.parameters || {};
    parameters.headers = headers;

    let host = backendHost();
    let path = host + '/' + config.org.path + '/upload/' + config.objectId;
    axios
        .delete(path, parameters)
        .then(response => {
            callback(response.data, null);
        })
        .catch(error => {
            callback(null, error);
        });
}

export function call(config) {
    let headers = {
        'Iconico-Api-Key': config.org.apiKey,
        Authorization: 'Bearer ' + config.authToken,
    };

    let host = backendHost();
    let path = host + '/' + config.org.path + '/' + config.path;

    return axios({
        method: config.method,
        url: path,
        params: config.parameters,
        headers: headers,
        data: config.body,
    });
}

export function get_static_image_url(image) {
    var images = require.context('../assets/', false, /\.(png|jpe?g|svg)$/);
    return images('./' + image);
}

export function get_image_url_from_path(path) {
    if (path) {
        if (path.includes('https://storage.googleapis.com/')) {
            return path;
        } else {
            return 'https://storage.googleapis.com/iconico-files/' + path;
        }
    }

    return this.get_static_image_url('iconico.png');
}

export function get_influxdb(config) {
    const headers = {
        headers: {
            'Content-Type': 'application/json',
            'Iconico-Api-Key': config.org.apiKey,
            Authorization: 'Bearer ' + config.authToken,
        },
    };

    let host = backendHost();
    let path = host + '/' + config.org.path + '/influxdb';

    return axios.post(path, config.body, headers);
}
