var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pb-2 table-responsive"},[_c('table',{staticClass:"table table-sm text-center"},[_c('thead',[_c('tr',_vm._l((_vm.columnDetails),function(column,index){return _c('th',{key:index,staticClass:"border border-primary border-top-0 border-left-0 border-right-0 text-justify",staticStyle:{"font-family":"HelveticaNeueLt","font-size":"1.2em"}},[_vm._v(" "+_vm._s(_vm.$t(column.title))+" ")])}),0)]),_c('tbody',_vm._l((_vm.objectDetails),function(row,index){return _c('tr',{key:index},_vm._l((_vm.columnDetails),function(column,index){return _c('td',{key:index,staticClass:"align-bottom text-justify",style:(row.style)},[_c('div',{staticClass:"cell-td fontbase font-lt"},[(
                                !row[column.icon] &&
                                    !column.color &&
                                    column.function
                            )?_c('span',{staticClass:"p-0 m-0 align-text-top",class:_vm.classForColumn(column, row),style:(column.style)},[_vm._v(" "+_vm._s(column.function(row))+" ")]):_vm._e(),(
                                !row[column.icon] &&
                                    !column.color &&
                                    !column.function
                            )?_c('span',{staticClass:"p-0 m-0 align-text-top",class:_vm.classForColumn(column, row),style:(column.style)},[_vm._v(" "+_vm._s(row[column.property])+" ")]):_vm._e(),(row[column.icon] && !column.color)?_c('span',{staticClass:"badge badge-pill badge-dark p-2 align-middle",class:_vm.classForColumn(column, row),staticStyle:{"margin-top":"-5px"}},[_c('i',{staticClass:"fas",class:row[column.icon]})]):_vm._e(),(column.color)?_c('span',{staticClass:"badge p-1 text-lowercase",staticStyle:{"width":"90px"},style:(_vm.styleForColorCell(row, column))},[_vm._v(" "+_vm._s(row[column.property])+" ")]):_vm._e()])])}),0)}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }