<template>
    <div id="app" class="p-0">
        <router-view class="router"></router-view>

        <transition name="fade" mode="out-in">
            <Alert v-show="showAlert()" :dataAlert="alertInfo()" />
        </transition>
    </div>
</template>

<script>
export default {
    mounted() {
        document.title = "Iconico - Choose Organization";
    },
    name: "app",
    methods: {
        showAlert() {
            return Boolean(this.$store.state.alertInfo);
        },
        alertInfo() {
            return this.$store.state.alertInfo;
        },
    },
    watch: {
        $route(to /*from*/) {
            document.title = to.meta.title || "Iconico";
        },
    },
};
</script>

<style>
html,
body,
#app {
    display: -ms-flexbox;
    display: flex;
    min-height: 100vh;
    height: 100%;
    min-width: 100%;
    -ms-flex-align: center;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: #f5f5f5;
}

.router {
    width: 100%;
}
</style>