<template>
    <div id="Alert" class="fixed-bottom clearfix pr-4">
        <div
            class="alert alert-dismissible fade show float-right"
            :class="alertClass()"
            role="alert"
        >
            <h4 class="alert-heading">{{ alertTitle() }}</h4>
            {{ alertMessage() }}
            <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-label="Close"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
</template>
<script>
    export default {
        name: "Alert",
        props: ["dataAlert"],
        data() {
            return {};
        },
        methods: {
            alertTitle() {
                return this.dataAlert && this.dataAlert.title
                    ? this.dataAlert.title
                    : "";
            },
            alertMessage() {
                return this.dataAlert && this.dataAlert.message
                    ? this.dataAlert.message
                    : "";
            },
            alertClass() {
                let bg =
                    this.dataAlert && this.dataAlert.bg
                        ? this.dataAlert.bg
                        : "success";
                return `alert-${bg}`;
            },
        },
    };
</script>

<style></style>
