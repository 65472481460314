<template>
    <div>
        <DisplayCard :title="$t(title)">
            <transition name="fade">
            <div class="flex-container">
                <div class="" v-if="info">
                    <h1 class="pl-2 font-bold">{{ info }}</h1>
                </div>

                <div v-for="(column, index) in data" :key="index" class="">
                    <span :class="column.color">{{ column.title }}</span>
                    <h5 class="font-bold">{{ column.value }}</h5>
                </div>
            </div>
            </transition>
        </DisplayCard>
    </div>
</template>

<script>
export default {
    name: "FlexCard",
    mounted() {},
    props: {
        title: {
            type: String,
            default: "core.device_home.basic_information",
        },
        info: {
            type: String,
        },
        data: {
            type: Array,
        },
    },
};
</script>

<style scoped lang="scss">
@import '../../../sass/appVariables.scss';
.flex-container {
    display: flex;
    justify-content: space-around;
}
.flex-container div {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
}
.danger {
    color: $webDanger;
}
.success {
    color: $webSuccess;
}
.warning {
    color: $webWarning;
}
.separator {
    border-right: #c4c4c4 2px solid;
}
</style>
