import Joi from 'joi';

export function showAlert(info) {
    let {
        state,
        message,
        title,
        bg,
        timeout,
        showCallback,
        hideCallback,
    } = info;

    if (!timeout) {
        timeout = 3;
    }

    let background = bg || 'success';

    const alertInfo = {
        title,
        message,
        timeout,
        bg: background,
    };

    if (showCallback) {
        showCallback();
    }

    state.alertInfo = alertInfo;

    if (timeout) {
        setTimeout(() => {
            state.alertInfo = null;
            if (hideCallback) {
                hideCallback();
            }
        }, timeout * 1000);
    }
}

export function cleanUpAndGoToLogin(self) {
    self.$cookie.delete('Org', { domain: 'iconico.us' });
    self.$cookie.delete('Account', { domain: 'iconico.us' });
    var pathArray = self.$router.currentRoute.path.split('/');
    pathArray.pop();
    const newPath = pathArray.join('/');
    self.$router.push(newPath);
}

export function secondsToDhms(uptime) {
    if (!isNaN(uptime)) {
        var seconds = Number(uptime);
        var d = Math.floor(seconds / (3600 * 24));
        var h = Math.floor((seconds % (3600 * 24)) / 3600);
        var m = Math.floor((seconds % 3600) / 60);
        var s = Math.floor((seconds % 3600) % 60);

        var dDisplay = d > 0 ? (d + 'd ').padStart(7) : ' '.padStart(7);
        var hDisplay = h > 0 ? (h + 'h ').padStart(5) : ' '.padStart(5);
        var mDisplay = m > 0 ? (m + 'm ').padStart(5) : ' '.padStart(5);
        var sDisplay = s > 0 ? (s + 's ').padStart(5) : ' '.padStart(5);

        return dDisplay + hDisplay + mDisplay + sDisplay;
    } else {
        return uptime;
    }
}

export function secondsToDhm(uptime) {
    if (!isNaN(uptime)) {
        var seconds = Number(uptime);
        var d = Math.floor(seconds / (3600 * 24));
        var h = Math.floor((seconds % (3600 * 24)) / 3600);
        var m = Math.floor((seconds % 3600) / 60);

        var dDisplay = d > 0 ? (d + 'd ').padStart(7) : ' '.padStart(7);
        var hDisplay = h > 0 ? (h + 'h ').padStart(5) : ' '.padStart(5);
        var mDisplay = m > 0 ? (m + 'm ').padStart(5) : ' '.padStart(5);

        return dDisplay + hDisplay + mDisplay;
    } else {
        return uptime;
    }
}

export function get_percentile(percentile, array) {
    array.sort(function(a, b) {
        return a - b;
    });
    var index = (percentile / 100) * array.length;
    var result = null;
    if (Math.floor(index) == index) {
        result = (array[index - 1] + array[index]) / 2;
    } else {
        result = array[Math.floor(index)];
    }
    return result;
}

export function percent(calPercentage) {
    let p = (100 * calPercentage) / 100;
    if (p < 0) {
        p = p * -1;
    }
    return p.toFixed(0) + '%';
}

export function timeCronJobs(fechaIni) {
    var now = new Date(fechaIni);
    var endDate = new Date();
    var diff = endDate - now;
    //var hours   = Math.floor(diff / 3.6e6);
    var minutes = Math.floor((diff % 3.6e6) / 6e4);
    var seconds = Math.floor((diff % 6e4) / 1000);
    if (minutes > 0) return minutes + ' min and ' + seconds + ' sec';
    else return seconds + ' sec';
}
export function timeMilliseconds(fechaInicio) {
    let time = new Date(fechaInicio);
    let m = time.getMilliseconds();
    return m + 'ms';
}

export function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function formatBitsPerSecond(bits, decimals = 2) {
    if (bits === 0) return '0 bps';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = [
        'bps',
        'Kbps',
        'Mbps',
        'Gbps',
        'Tbps',
        'Pbps',
        'Ebps',
        'Zbps',
        'Ybps',
    ];

    const i = Math.floor(Math.log(bits) / Math.log(k));

    return parseFloat((bits / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function degreesCelsius(degrees) {
    return degrees.toFixed(0) + '°C';
}

export function formatVol(voltage) {
    return voltage.toFixed(1) + ' ' + 'V';
}

export function formatAmp(amps) {
    return amps.toFixed(2) + ' ' + 'A';
}
export function formatRpm(rpm) {
    return rpm.toFixed(0) + ' ' + 'rpm';
}

export function formatApparentPower(power) {
    return power.toFixed(0) + ' ' + 'kVA';
}

export function formatReactivePower(power) {
    return power.toFixed(0) + ' ' + 'kVar';
}

export function formatPower(power) {
    return power.toFixed(0) + ' ' + 'kWh';
}

export function formatHertz(hertz) {
    return hertz.toFixed(2) + ' ' + 'Hz';
}

export const deviceValidation = Joi.object({
    ipv4: Joi.string()
        .optional()
        .ip({
            version: ['ipv4'],
            cidr: 'forbidden',
        }),
    ipv6: Joi.string()
        .optional()
        .ip({
            version: ['ipv6'],
            cidr: 'forbidden',
        }),
    name: Joi.string().required(),
    manufacturer: Joi.required(),
    port: Joi.number(),
    type_device: Joi.required(),
});
