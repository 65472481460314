<template>
    <transition name="fade">
        <DisplayCard v-if="isLoaded" :title="$t('core.common.multiple')">
            <div class="clearfix">
                <div class="float-right">
                    <ActionBar :buttons="actionButtons()" />
                </div>
            </div>

            <InfoAlert
                :icon="'fa-info-circle'"
                :colorClass="'primary'"
                :text="textInfo()"
                class="p-2"
            />

            <InfoAlert
                v-if="isError"
                :icon="'fa-bomb'"
                :colorClass="'danger'"
                :text="textError()"
                class="p-2"
            />

            <InfoAlert
                v-if="isSuccess"
                :icon="'fa-check-circle'"
                :colorClass="'success'"
                :text="textSuccess"
                class="p-2"
            />

            <EditorJs
                class="pt-2"
                :script.sync="file"
                :readonly="false"
                style="min-height: 500px; height: 50vh"
            >
            </EditorJs>
        </DisplayCard>
    </transition>
</template>
<script>
    export default {
        name: "CreateMultiple",
        props: {
            path: String,
        },
        mounted() {
            this.loadData();
        },
        data() {
            return {
                file: "",
                schema: [],
                values: "",
                valuesPath: [],
                isLoaded: false,
                error: [],
                isError: false,
                isSuccess: false,
                textSuccess: "",
            };
        },
        methods: {
            loadData() {
                this.schema = this.$system.schemas[this.path];
                let columns = this.schema.additionalProperties;
                columns.forEach((column) => {
                    if (column.name) {
                        this.values += column.name + ",";
                        this.valuesPath.push({
                            path: column.path,
                            type: column.type,
                        });
                    }
                });
                this.isLoaded = true;
            },
            textInfo() {
                return `${this.$t("core.common.values")}: ${this.values.slice(
                    0,
                    -1
                )}`;
            },
            textError() {
                let text = "ERROR: ";
                this.error.forEach((e) => {
                    text += e + "\n";
                });
                return text;
            },
            actionButtons() {
                return [
                    {
                        type: "save",
                        action: this.controlObjects,
                    },
                ];
            },
            async controlObjects() {
                var dataset = this.file.split("\n");
                this.error = [];
                if (dataset[1] != "") {
                    let result = await this.parseData(dataset);
                    if (result.error.length > 0) {
                        this.error = result.error;
                        this.isError = true;
                        const self = this;
                        setTimeout(function() {
                            self.isError = false;
                        }, 10000);
                    } else {
                        let objects = result.data;
                        this.saveObjects(objects);
                    }
                }
            },
            saveObjects(objects) {
                let config = {
                    org: this.$store.state.org,
                    authToken: this.$store.state.account.token,
                    body: objects,
                    object: this.path,
                };
                let count = objects.length;
                this.$iconico
                    .post_object(config)
                    .then((result) => {
                        this.textSuccess = this.$t(
                            "core.create_multiple.save_multiple",
                            { count }
                        );
                        this.isSuccess = true;
                        this.file = "";
                        const self = this;
                        setTimeout(function() {
                            self.isSuccess = false;
                        }, 5000);
                    })
                    .catch((error) => {
                        this.controlError(error);
                    });
            },
            parseData(dataset) {
                const countValues = this.valuesPath.length;
                let fila = {};
                let objects = [];
                let error = [];
                for (var i = 0; i < dataset.length; i++) {
                    var value = dataset[i].split(",");
                    if (countValues == value.length) {
                        for (var x = 0; x < countValues; x++) {
                            let path = this.valuesPath[x].path;
                            switch (this.valuesPath[x].type) {
                                case "Number":
                                    fila[path] = parseInt(value[x]);
                                    break;
                                case "Boolean":
                                    fila[path] = value[x] == "1" ? true : false;
                                    break;
                                case "mongoose.Schema.Types.ObjectId":
                                    if (value[x] != "") fila[path] = value[x];
                                    break;
                                default:
                                    fila[path] = value[x].toString();
                            }
                        }
                        objects.push(fila);
                        fila = {};
                    } else {
                        error[i] = `${this.$t("core.common.line")} ${i}`;
                    }
                }
                return { data: objects, error };
            },
            controlError(error) {
                this.$store.state.status = error.response
                    ? error.response.status
                    : 500;
                this.$store.state.message = error.message;
            },
        },
    };
</script>
<style scoped></style>
