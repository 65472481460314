<template>
    <div>
        <ContentPage
            :navigationInfo="this.navigationInfo"
            representedObject="equipment"
            :showPages="false"
            :showAdd="false"
            :showSearch="false"
            :showPagination="false"
            :staticTitle="this.title"
        />
    </div>
</template>

<script>
import Vue from "vue";

export default {
    name: "Equipment",
    props: {
        title: {
            type: String,
        },
    },
    data() {
        return {
            navigationInfo: {
                object: "equipment",
                icon: "server",
                extraColumns: [
                    { title: "web.common.location", function: this.location },
                    {
                        title: "web.common.last_service",
                        function: this.service,
                        populate: "client",
                    },
                    {
                        title: "web.common.refrigerators",
                        function: this.refrigeratos,
                    },
                    {
                        title: "web.common.alarms_last_month",
                        function: this.alarms,
                        // populate: "location",
                        classFunction: this.classAlarms,
                    },
                ],
            },
        };
    },
    methods: {
        rootComponent() {
            let ContentPage = Vue.component("ContentPage");
            return {
                title: this.$t("web.navigation.equipment"),
                component: ContentPage,
                properties: this.propertiesFunction(),
            };
        },
        propertiesFunction() {
            return {
                navigationInfo: this.navigationInfo,
                representedObject: "equipment",
                showPages: true,
                showAdd: true,
                showSearch: true,
            };
        },

        service(equipment) {
            // if (equipment.client && equipment.client.display_name) {
            //     return equipment.client.display_name;
            // }
            return "-";
        },
        refrigeratos(equipment) {
            return "-";
        },
        alarms(equipment) {
            // if (equipment.type) {
            //     return equipment.type;
            // }
            return "1";
        },
        location(equipment) {
            // if (equipment.location && equipment.location.display_name) {
            //     return equipment.location.display_name;
            // }
            return "-";
        },
        classAlarms(equipment) {
            let test = Math.floor(Math.random() * 3) + 1;
            if (test === 1) {
                return "cell-scuare-red ";
            }
            if (test === 2) {
                return "cell-scuare-warning ";
            }
            return "cell-scuare-blue ";
        },
    },
};
</script>

<style lang="scss">
@import "../../../sass/appVariables.scss";

.cell-scuare-red {
    color: $webWhite;
    width: 100%;
    border-radius: 10% !important;
    background-color: $webDanger;
    display: flex;
    width: 2rem;
    height: 2rem;
    align-items: center;
    justify-content: center;
    position: relative;
    left: 45%;
}
.cell-scuare-blue {
    color: $webBackGround;
    width: 100%;
    border-radius: 10% !important;
    background-color: $webLightGrey;
    display: flex;
    width: 2rem;
    height: 2rem;
    align-items: center;
    justify-content: center;
    position: relative;
    left: 45%;
}

.cell-scuare-warning {
    color: $webGrey;
    width: 100%;
    border-radius: 10% !important;
    background-color: $webWarning;
    display: flex;
    width: 2rem;
    height: 2rem;
    align-items: center;
    justify-content: center;
    position: relative;
    left: 45%;
}
</style>