<template>
    <div class="card text-center mx-auto mb-4">
        <!-- <div class="card-header">
            Empty List
        </div> -->
        <div class="card-body">
            <h4 class="card-title">{{ title }}</h4>

            <p class="card-text display-1"><i class="fas fa-box-open" /></p>

            <p class="card-text">{{ calculateMessage() }}</p>

            <button
                v-if="objectName"
                class="
                    btn btn-sm btn-success
                    pb-2
                    pt-2
                    pl-4
                    pr-4
                    font-weight-bold
                "
                @click="createCallback()"
            >
                {{ $t("core.common.add") + objectName }}
            </button>

            <button
                v-if="objectName"
                class="btn btn-sm btn-dark pb-2 pt-2 pl-4 pr-4 ml-2"
                @click="returnList()"
            >
                {{ $t("core.empty.back_to") + objectName }}
            </button>
        </div>
        <!-- <div class="card-footer text-muted">
            {{ $t('core.empty.footer') }}
        </div> -->
    </div>
</template>

<script>
    export default {
        name: "EmptyState",
        props: [
            "title",
            "createCallback",
            "message",
            "objectName",
            "returnList",
        ],
        methods: {
            calculateMessage() {
                return this.message || this.$t("core.empty.empty_message");
            },
        },
    };
</script>

<style></style>
