<template>
    <div id="ChartRadialBar">
        <div :id="idElement">
            <apexchart
                type="radialBar"
                :height="this.height"
                ref="radialBar"
                :options="chartOptions"
                :series="series"
            ></apexchart>
        </div>
    </div>
</template>
<script>
    import VueApexCharts from "vue-apexcharts";
    export default {
        name: "ChartRadialBar",
        components: {
            apexchart: VueApexCharts,
        },
        props: ["dataModel", "idElement", "height", "label", "colors"],
        data() {
            return {
                series: [this.dataModel],
                chartOptions: {
                    chart: {
                        id: this.idElement,
                        type: "radialBar",
                        height: this.height,
                    },
                    plotOptions: {
                        radialBar: {
                            startAngle: -135,
                            endAngle: 225,
                            hollow: {
                                margin: 0,
                                size: "70%",
                                background: "#fff",
                                image: undefined,
                                imageOffsetX: 0,
                                imageOffsetY: 0,
                                position: "front",
                            },
                            track: {
                                background: "#F2F2F2",
                                strokeWidth: "70%",
                                margin: 0, // margin is in pixels
                            },

                            dataLabels: {
                                show: true,
                                name: {
                                    offsetY: -10,
                                    show: true,
                                    color: "#888",
                                    fontSize: "16px",
                                },
                                value: {
                                    formatter: function(val) {
                                        return parseInt(val) + "%";
                                    },
                                    color: "#111",
                                    fontSize: "26px",
                                    show: true,
                                },
                            },
                        },
                    },
                    colors: this.colors,

                    stroke: {
                        lineCap: "round",
                    },
                    labels: [this.label],
                },
            };
        },
    };
</script>

<style scoped></style>
