<template>
    <div
        class="col-12 col-xl-12 scroll"
        :style="'height:' + this.height + 'px;'"
    >
        <div class="timeline">
            <div class="tl-item" v-for="(row, index) in object" :key="index">
                <div
                    class="tl-dot"
                    :class="row.status ? 'b-primary' : 'b-danger'"
                ></div>
                <div class="tl-content">
                    <div class="text-dark fontbase">{{ row.title }}</div>
                    <div class="tl-date text-muted mt-1 font-bold">
                        {{ row.date }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Timeline",
        props: ["object", "height"],
        data() {
            return {};
        },
    };
</script>

<style>
    .scroll {
        overflow-y: scroll;
    }
    .timeline {
        position: relative;
        border-color: rgba(160, 175, 185, 0.15);
        padding: 0;
        margin: 0;
    }
    .tl-item {
        font-family: HelveticaNeueLt;
        border-radius: 3px;
        position: relative;
        display: -ms-flexbox;
        display: flex;
    }

    .tl-item > * {
        padding: 10px;
    }

    .tl-item .avatar {
        z-index: 2;
    }

    .tl-item:last-child .tl-dot:after {
        display: none;
    }

    .tl-item:last-child .tl-dot:after {
        display: none;
    }

    .tl-dot {
        position: relative;
        top: 6px;
        border-color: rgba(160, 175, 185, 0.15);
    }

    .tl-dot:after,
    .tl-dot:before {
        content: "";
        position: absolute;
        border-color: inherit;
        border-width: 3px;
        border-style: solid;
        border-radius: 50%;
        width: 15px;
        height: 15px;
        top: 12px;
        left: 50%;
        transform: translateX(-50%);
    }

    .tl-dot:after {
        width: 0;
        height: auto;
        top: 25px;
        bottom: -15px;
        border-right-width: 0;
        border-top-width: 0;
        border-bottom-width: 0;
        border-radius: 0;
    }

    .tl-date {
        font-size: 0.6rem;
        margin-top: 2px;
        min-width: 150px;
        max-width: 50%;
    }
    .b-primary {
        border-color: #20971c !important;
    }

    .b-danger {
        border-color: #f54394 !important;
    }
</style>
