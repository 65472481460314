<template>
    <div class="" v-if="isData">
        <div class="clearfix">
            <slot />
        </div>

        <div style="min-width: 65rem">
            <DisplayCard
                :title="cardTitle()"
                :isCards="this.isDisplayTable() || showCards"
            >
                <div class="row justify-content-end">
                    <div class="col-2"></div>
                    <div class="col-10 pr-0">
                        <div>
                            <div
                                class="row justify-content-end"
                                style="position: relative"
                            >
                                <div class="col-4" style="margin-right: 2rem" v-if="showSearch">
                                    <div
                                        class="
                                            input-group input-group-md
                                            search-position
                                        "
                                        style="min-width: 9rem"
                                    >
                                        <div
                                            v-show="searchText.length > 0"
                                            class="input-group-append"
                                        >
                                            <button
                                                class="
                                                    input-group-text
                                                    btn btn-info
                                                    border-0
                                                "
                                                @click="emptySearch()"
                                            >
                                                <i class="fas fa-times"> </i>
                                            </button>
                                        </div>
                                        <input
                                            class="
                                                form-control
                                                inputsBorder
                                                border-style
                                            "
                                            type="text"
                                            ref="search"
                                            v-model="searchText"
                                            :placeholder="
                                                $t('core.common.search')
                                            "
                                            @keyup="onSearch($event)"
                                        />

                                        <div class="input-group-prepend">
                                            <span
                                                class="
                                                    input-group-text
                                                    icon-color
                                                    border-style
                                                "
                                            >
                                                <i
                                                    class="
                                                        fas
                                                        fa-search
                                                        w3-large
                                                    "
                                                >
                                                </i>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    v-if="showRefreshData"
                                    class="col-1"
                                    style="margin-right: 4rem"
                                >
                                    <button
                                        class="
                                            btn btn-sm btn-iconico-lightblue
                                            btnDark
                                        "
                                        @click="refreshData()"
                                        style="min-width: 9rem"
                                    >
                                        <i
                                            class="
                                                fas
                                                fa-redo-alt
                                                icon-position
                                            "
                                        />
                                        {{
                                            `${this.$t(
                                                'monitor.monitor_main.reload'
                                            )}`
                                        }}
                                    </button>
                                </div>

                                <div
                                    v-if="showAddMultipleDevice"
                                    class="col-1"
                                    style="margin-right: 4rem"
                                >
                                    <button
                                        class="
                                            btn btn-sm btn-iconico-blue
                                            btnPrimary
                                        "
                                        @click="addMultipleDevice()"
                                        style="min-width: 9rem"
                                    >
                                        <i
                                            class="
                                                fas
                                                fa-plus
                                                icon-multiple-position
                                            "
                                        />
                                        {{
                                            `${this.$t(
                                                'core.common.create_many'
                                            )}`
                                        }}
                                    </button>
                                </div>

                                <div class="col-1" style="margin-right: 7rem">
                                    <button
                                        v-if="showAdd"
                                        class="
                                            btn btn-sm btn-iconico-blue
                                            btnSuccess
                                        "
                                        @click="addObject()"
                                        style="min-width: 9rem"
                                    >
                                        <i class="fas fa-plus icon-size" />
                                        {{ translatedAddTitle() }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row justify-content-start pt-2">
                    <div clas="col-6">
                        <FilterBar
                            v-if="true"
                            :filters="filters"
                            :loadFilters="loadFilters"
                            :reloadPage="downloadFirstPage"
                            :allFilters="allFilters"
                        />
                    </div>
                    <div
                        class="row justify-content-start pt-2 position"
                        style="position: relative; left: -5.5rem"
                    >
                        <div class="col-2">
                            <Tags
                                v-if="tags.length > 1 && this.showTags"
                                :tags="tags"
                                :selectionChanged="changedSelectedTags"
                                :object="'device'"
                            />
                        </div>
                    </div>
                </div>

                <transition name="fade">
                    <PaginatedTable
                        v-if="isDisplayTable()"
                        :isSearching="searchText"
                        :columns="columns()"
                        :mappedTags="mappedTags"
                        :rows="objects"
                        :rowDetails="rowDetails"
                        :pageSelected="pageSelected"
                        :onOrder="onOrder"
                        :sortTitle="sortKey()"
                        :showPagination="showPagination"
                        :sortOptions="sortCriteria"
                    />
                </transition>
            </DisplayCard>
        </div>
    </div>
</template>

<script>
import CreateMultiple from '../screens/CreateMultiple.vue';
import taggableObjects from './taggableObjects';
import FilterBar from '../screens/FilterBar.vue';
export default {
    name: 'ContentPage',
    props: {
        staticTitle:{
            type:String,
        },
        filterData: {
            type: Array,
        },
        showExtraData: {
            type: Object,
        },
        showTags: {
            type: Boolean,
            default: true,
        },
        showExtraTabs: {
            type: Boolean,
            default: false,
        },
        showStatusButton: {
            type: Boolean,
            default: false,
        },
        showAddMultipleDevice: {
            type: Boolean,
            default: false,
        },
        showRefreshData: {
            type: Boolean,
            default: false,
        },
        mappedTagsProp: {
            type: Object,
        },
        disableDisplay: {
            type: Boolean,
            default: false,
        },
        deleteObject: {
            type: Function,
        },
        activeRefresh: {
            type: Function,
        },
        extraData: {
            type: Function,
        },
        dateParameters: {
            type: Function,
        },
        showDateButtons: {
            type: Boolean,
            default: false,
        },
        reactiveRefresh: {
            type: Boolean,
            default: false,
        },
        cardInfo: {
            type: String,
        },
        buttonConfig: {
            type: String,
        },
        sort: {
            type: Object,
        },
        showDevice: {
            type: Boolean,
            default: true,
        },
        showSensors: {
            type: Boolean,
            default: true,
        },
        groupEvents: {
            type: Array,
        },
        typesNotification: {
            type: Array,
        },
        eventsCode: {
            type: Array,
        },
        sensorsDefaults: {
            type: Object,
        },
        objectsRefreshedCallback: {
            type: Function,
            required: false,
        },
        extraParameters: {
            type: Object,
            required: false,
        },
        navigationInfo: {
            type: Object,
            required: true,
        },
        columnDetails: Array,
        extraDisplayActions: Array,
        showSearch: {
            type: Boolean,
            default: true,
        },
        showPages: {
            type: Boolean,
            default: false,
        },
        showManufacturers: {
            type: Boolean,
            default: false,
        },
        showDeviceButton: {
            type: Boolean,
            default: false,
        },
        showTypes: {
            type: Boolean,
            default: false,
        },
        showAdd: {
            type: Boolean,
            default: true,
        },
        showPagination: {
            type: Boolean,
            default: true,
        },
        getPath: {
            required: false,
            type: String,
        },
        representedObject: {
            required: true,
            type: String,
        },
        representedObjectInfo: {
            required: false,
            type: Object,
        },
        searchTerm: {
            type: Function,
            required: false,
        },
        inputComponent: Object,
        displayComponent: Object,
        hideTags: {
            type: Boolean,
            default: false,
        },
        showRowDetails: {
            type: Boolean,
            default: true,
        },
        showCards: {
            type: Boolean,
            default: true,
        },
        systemObject: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    mounted() {
        if (this.sort) {
            this.sortCriteria = this.sort;
        } else {
            this.sortCriteria = { name: 1 };
        }

        if (this.$refs.search) {
            this.$refs.search.focus();
        }

        this.loadData();
        this.loadFilters();
        this.$watch(
            () => {
                return this.$store.state.serverActive;
            },
            () => {
                this.downloadFirstPage();
            }
        );
    },
    components: {
        FilterBar,
    },
    data() {
        return {
            allFilters: {},
            filters: [],
            filterStatus: {},
            sortCriteria: null,
            pageSize: 10,
            tagObject: null,
            tagObjectDisplay: null,
            tags: [],
            mappedTags: {},
            filterTags: [],
            searchText: '',
            manufacturersMapping: [],
            populate: '',
            selectedObject: undefined,
            objects: [],
            objectEdit: {},
            objectRefresh: 'server',
            isSpinner: false,
            objectDetails: [],
            manufacturers: [],
            isData: false,
            filterType: this.$t('core.common.all'),
            formatter: new Intl.DateTimeFormat(this.$i18n.locale, {
                month: 'short',
                day: 'numeric',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
            }),
            optionsForType: [
                'AP',
                'CPE',
                'Router',
                'EdgeSwitch',
                'Switch',
                'OLT',
                'Other',
                this.$t('core.common.all'),
            ],
            selectType: null,
        };
    },
    methods: {
        refreshData() {
            this.downloadFirstPage();
        },
        getDataForProps() {
            let baseData = {
                device: this.device,
                mappedTags: this.mappedTags,
                path: 'device',
                confirmObjectChanges: this.confirmObjectChanges,
                cancelObjectChanges: this.cancelObjectChanges,
                actionDelete: this.deleteSelected,
            };

            return { ...baseData };
        },
        addMultipleDevice() {
            this.isDevice = !this.isDevice;
            this.isMultiple = true;
            this.$store.state.Object = null;
            this.device = null;
            let newCrumb = {
                title: this.$t('monitor.monitor_main.add_multiple_devices'),
                component: CreateMultiple,
                properties: this.getDataForProps(),
            };
            this.$store.state.crumbs.push(newCrumb);
        },
        loadFilters() {
            this.filters = [
                {
                    name: 'pagefilters',
                    show: this.showPages,
                    buttonTitle: this.$t('core.common.page_size'),
                    options: [10, 15, 20],
                    selected: this.pageSize,
                    selectionChanged: this.pageSizeChanged,
                    itemTitle: 'rows',
                },
            ];
            if (this.filterData) {
                this.filterData.forEach((filter) => {
                    this.filters.push(filter);
                });
            }
        },

        editSelected() {
            let crumbs = this.$store.state.crumbs;
            crumbs.pop();

            let newCrumb = {
                title: this.translatedEditTitle(this.objectEdit),
                component: this.getInputComponent(),
                properties: this.getInputComponentProperties(),
            };

            this.$store.state.crumbs.push(newCrumb);
        },
        cancelObjectChanges() {
            this.objectEdit = {};
            this.isSpinner = false;
            this.$store.state.crumbs.pop();
            this.downloadCurrentPage();
        },
        confirmObjectChanges(objectBody, file, propertyFile) {
            this.isSpinner = true;
            this.confirmChanges(objectBody, file, propertyFile);
        },
        confirmChanges(object, file, propertyFile) {
            let config = {
                org: this.$store.state.org,
                authToken: this.$store.state.account.token,
                body: object,
            };
            config = this.adjustForSystemObject(config);
            this.$store.state.showPageLoading = true;
            if (object._id) {
                let pathApi = this.navigationInfo.pathApi || {};
                config.object = pathApi.put || this.navigationInfo.object;

                this.$iconico
                    .put_object(config)
                    .then((result) => {
                        if (file) {
                            this.onUploadFile(
                                file,
                                result.data[propertyFile].filename
                            );
                        }
                        if (
                            this.reactiveRefresh &&
                            this.$system.objects.server
                        ) {
                            this.$system.objects.server.find((server) => {
                                if (server._id == object._id) {
                                    server.name = object.name;
                                }
                            });
                            this.$store.state.refresh =
                                this.$store.state.refresh + 1;
                        }
                        this.$store.state.status = 200;
                        this.$store.state.message = this.$t(
                            'core.alerts.success_modify'
                        );
                        this.cancelObjectChanges();
                        this.$store.state.showPageLoading = false;
                    })
                    .catch((error) => {
                        this.controlError(error);
                        this.$store.state.showPageLoading = false;
                    });
                if (this.objectRefresh == config.object) {
                    this.probe_refresh(object._id);
                }
            } else {
                let pathApi = this.navigationInfo.pathApi || {};
                config.object = pathApi.post || this.navigationInfo.object;
                this.$iconico
                    .post_object(config)
                    .then((response) => {
                        if (file) {
                            this.onUploadFile(
                                file,
                                response.data[propertyFile].filename
                            );
                        }
                        if (
                            this.reactiveRefresh &&
                            this.$system.objects.server
                        ) {
                            this.$system.objects.server.push(response.data);
                            this.$store.state.refresh =
                                this.$store.state.refresh + 1;
                        }
                        this.$store.state.status = 200;
                        this.$store.state.message = this.$t(
                            'core.alerts.success_create'
                        );
                        this.cancelObjectChanges();
                        this.$store.state.showPageLoading = false;
                    })
                    .catch((error) => {
                        this.controlError(error);
                        this.$store.state.showPageLoading = false;
                    });
            }

            if (file && object[propertyFile]) {
                config.objectId = object[propertyFile].filename;
                this.$iconico.del_googlestorage(config, (response, error) => {
                    if (error == null || response != null) {
                    }
                });
            }
        },
        onUploadFile(file, fileName) {
            file.append('fileName', fileName);
            let config = {
                org: this.$store.state.org,
                authToken: this.$store.state.account.token,
                body: file,
            };
            this.$iconico.upload_googlestorage(config, (response, error) => {
                if (error == null || response != null) {
                }
            });
        },
        getInputComponentProperties() {
            let baseInputProperties = {
                columns: this.columnsForm(),
                navigationInfo: this.navigationInfo,
                mappedTags: this.mappedTags,
                object: this.objectEdit,
                systemObject: this.systemObject,
                confirmObjectChanges: this.confirmObjectChanges,
                cancelObjectChanges: this.cancelObjectChanges,
                isSpinner: this.isSpinner,
                hideTags: this.hideTags,
                title: this.cardTitle(),
                showExtraData: this.showExtraData,
            };
            let extraPropertiesForInputComponent = {
                cardInfo: this.cardInfo,
                showDevice: this.showDevice,
                showSensors: this.showSensors,
                groupEvents: this.groupEvents,
                typesNotification: this.typesNotification,
                eventsCode: this.eventsCode,
                sensorsDefaults: this.sensorsDefaults,
                buttonConfig: this.buttonConfig,
                showExtraData: this.showExtraData,
            };
            return {
                ...baseInputProperties,
                ...extraPropertiesForInputComponent,
            };
        },
        getDisplayComponentProperties() {
            let baseDisplayProperties = {
                columns: this.columnsForm(),
                activeRefresh: this.activeRefresh,
                navigationInfo: this.navigationInfo,
                object: this.objectEdit,
                mappedTags: this.mappedTags,
                confirmObjectChanges: this.confirmObjectChanges,
                cancelObjectChanges: this.cancelObjectChanges,
                columnDetails: this.columnDetails,
                systemObject: this.systemObject,
                objectDetails: this.objectDetails,
                actionEdit: this.editSelected,
                actionDelete: this.deleteSelected,
                extraDisplayActions: this.extraDisplayActions,
                showExtraTabs: this.showExtraTabs,
                title: this.cardTitle(),
            };
            let extraPropertiesForDisplayComponent = {
                eventsCode: this.eventsCode,
                extraData: this.extraData,
            };
            return {
                ...baseDisplayProperties,
                ...extraPropertiesForDisplayComponent,
            };
        },
        async loadData() {
            this.selectedObject = this.getPath || this.representedObject;

            this.tagObject =
                this.representedObject == 'tag' ? taggableObjects[0] : null;
            this.tagObjectDisplay =
                this.representedObject == 'tag'
                    ? this.$t(this.tagObject.display)
                    : null;

            this.populateObject();
            this.downloadCurrentPage();
            this.loadTags();

            this.isData = true;
        },
        async loadTags() {
            if (!this.mappedTagsProp) {
                let config = {
                    org: this.$store.state.org,
                    authToken: this.$store.state.account.token,
                    object: 'tag',
                    parameters: {
                        params: {
                            sort: { name: 1 },
                            pagination: false,
                            find: { object: this.representedObject },
                        },
                    },
                };

                let response = await this.$iconico.get_objects(config);

                this.tags = response.data.data.docs;
                this.tags.forEach((tag) => (this.mappedTags[tag._id] = tag));
            } else {
                this.mappedTags = this.mappedTagsProp;
            }
        },
        addObject() {
            let newCrumb = {
                title: this.translatedAddTitle(),
                component: this.getInputComponent(),
                properties: this.getInputComponentProperties(),
            };
            this.$store.state.crumbs.push(newCrumb);
        },
        isDisplayTable() {
            return !this.$store.state.showPageLoading;
        },
        cardTitle() {
            if (this.staticTitle){
                return this.staticTitle
            }
            let crumbs = this.$store.state.crumbs;
            if (crumbs.length == 0) {
                return '';
            }
            const lastBreadcrumb = crumbs[crumbs.length - 1];
            return lastBreadcrumb.title;
        },
        tagObjectChanged(value) {
            this.tagObjectDisplay = value;
            taggableObjects.forEach((taggable) => {
                if (this.$t(taggable.display) == value) {
                    this.tagObject = taggable;
                }
            });
            this.downloadFirstPage();
        },
        emptySearch() {
            this.searchText = '';
            this.downloadFirstPage();
        },
        tagOptions() {
            return taggableObjects.map((tagInfo) => this.$t(tagInfo.display));
        },
        changedSelectedTags(selections) {
            let selectedTags = [];

            selections.forEach((tag) => {
                if (tag.selected) selectedTags.push(tag._id);
            });
            this.filterTags = selectedTags;
            this.downloadFirstPage();
        },
        getInputComponent() {
            if (this.inputComponent) {
                return this.inputComponent;
            }
            return this.$options.components.InputForm;
        },
        getDisplayComponent() {
            if (this.displayComponent) {
                return this.displayComponent;
            }
            return this.$options.components.DisplayForm;
        },
        deleteSelected() {
            let objectName = this.getObjectName(this.objectEdit);
            const objectDescriptor = `core.objects.${this.representedObject}`;
            const translatedObjectDescriptor = this.$t(objectDescriptor);
            let titleObject =
                translatedObjectDescriptor != objectDescriptor
                    ? translatedObjectDescriptor
                    : this.$t('core.common.object');
            if (this.objectEdit.first_name) {
                let lastName = '';
                if (this.objectEdit.last_name) {
                    lastName = this.objectEdit.last_name;
                }
                objectName = `${this.objectEdit.first_name} ${lastName}`;
                titleObject = this.$t('core.common.user');
            }
            const objectArticle = `core.articles.${this.representedObject}`;
            const translatedObjectArticle = this.$t(objectArticle);
            let article =
                translatedObjectArticle != objectArticle
                    ? translatedObjectArticle
                    : this.$t('core.articles.generic');
            this.$store.state.modal = {
                title: `${this.$t(
                    'core.content.delete_button'
                )} ${titleObject}`,
                body: `${this.$t(
                    'core.common.delete_body'
                )} ${article} ${titleObject} ${objectName}?`,
                closeText: this.$t('core.common.close'),
                confirmText: this.$t('core.common.delete'),
                bg: 'danger',
                confirm: this.confirmDelete,
                close: () => {
                    this.$store.state.modal = null;
                },
            };
        },
        translatedEditTitle(object) {
            let name = this.getObjectName(object);
            return `${this.$t('core.common.edit')} ${name}`;
        },
        translatedAddTitle() {
            return this.$t('core.common.add');
        },
        nameSingular() {
            if (
                this.representedObjectInfo &&
                this.representedObjectInfo.nameSingular
            ) {
                return this.representedObjectInfo.nameSingular;
            }
            return this.$t('core.common.object');
        },
        namePlural() {
            if (
                this.representedObjectInfo &&
                this.representedObjectInfo.namePlural
            ) {
                return this.representedObjectInfo.namePlural;
            }
            return this.$t('core.common.objects');
        },
        listIsEmpty() {
            if (this.objects && this.objects.docs) {
                return this.objects.docs.length == 0;
            }
            return true;
        },
        populateObject() {
            if (this.navigationInfo.extraColumns) {
                this.populate = '';
                this.navigationInfo.extraColumns.forEach((column, index) => {
                    if (column.populate) {
                        this.populate = this.populate
                            ? `${column.populate} ${this.populate}`
                            : column.populate;
                    }
                });
            }
        },

        pageFilterStatus(status) {
            this.filterStatus = this.filterData.selectionChanged(status);
            this.filterData.status = status;
            this.downloadPage(1);
        },
        pageSizeChanged(size) {
            this.pageSize = size;
            return {};
        },
        sortKey() {
            let keys = Object.keys(this.sortCriteria);
            if (keys.length > 0) {
                return keys[0];
            }
            return '';
        },
        onOrder(column) {
           
            let columnName = column.title.split('.');
            let selectedColumn = column.property || columnName[columnName.length - 1];
            let sortKey = this.sortKey();

            if (column.sort == 'descending') {
                if (sortKey == selectedColumn) {
                    this.sortCriteria[sortKey] = this.sortCriteria[sortKey] * 1;
                } else {
                    delete this.sortCriteria[sortKey];
                    this.sortCriteria[selectedColumn] = 1;
                }
            }
            if (column.sort == 'ascending') {
                if (sortKey == selectedColumn) {
                    this.sortCriteria[sortKey] = this.sortCriteria[sortKey] * 1;
                } else {
                    delete this.sortCriteria[sortKey];
                    this.sortCriteria[selectedColumn] = -1;
                }
            }
            if (column.sort == 'both') {
                if (sortKey == selectedColumn) {
                    this.sortCriteria[sortKey] =
                        this.sortCriteria[sortKey] * -1;
                } else {
                    delete this.sortCriteria[sortKey];
                    this.sortCriteria[selectedColumn] = 1;
                }
            }
            this.downloadPage(1);
        },
        onSearch(event) {
            this.downloadPage(1);
        },
        downloadFirstPage() {
            this.downloadPage(1);
        },
        downloadCurrentPage() {
            this.downloadPage(this.objects.page);
        },
        searchExpression() {
            if (!this.searchText || this.searchText.length < 3) {
                return null;
            }

            if (this.searchTerm) {
                return this.searchTerm(this.searchText);
            }

            return { name: { $regex: this.searchText, $options: 'i' } };
        },
        findCriteria() {
            const search = this.searchExpression();
            const tags =
                this.filterTags.length > 0
                    ? { tags: { $in: this.filterTags } }
                    : null;
            const objectForTag =
                this.tagObject && this.tagObject.path != 'all'
                    ? { object: this.tagObject.path }
                    : null;
            let dateParams = null;
            if (this.showDateButtons) {
                dateParams = this.dateParameters(this.dateFrom, this.dateUntil);
            }
            if (
                search ||
                tags ||
                objectForTag ||
                this.extraParameters ||
                this.filterStatus ||
                dateParams
            ) {
                let allFilterParameters = {};
                if (this.allFilters) {
                    Object.values(this.allFilters).forEach((filter) => {
                        if (filter) {
                            allFilterParameters[Object.keys(filter)[0]] =
                                filter[Object.keys(filter)[0]];
                        }
                    });
                }

                const result = {
                    ...allFilterParameters,
                    ...dateParams,
                    ...this.extraParameters,
                    ...search,
                    ...tags,
                    ...objectForTag,
                };

                return result;
            }
            return null;
        },
        async downloadPage(page) {
            let sort = this.sortCriteria || this.sort;
            let params = {
                page,
                limit: this.pageSize,
                sort,
                populate: this.populate,
                find: this.findCriteria(),
            };
            const parameters = {
                params,
            };
            this.downloadCurrentObjects(parameters);
        },
        downloadCurrentObjects(parameters) {
            this.downloadObjects(
                this.navigationInfo.object,
                parameters,
                (newObjects, error) => {
                    if (newObjects) {
                        if (newObjects.docs) {
                            this.objects = newObjects;
                        } else {
                            this.objects.docs =
                                newObjects[Object.keys(newObjects)[0]];
                        }
                        if (this.objectsRefreshedCallback) {
                            this.objectsRefreshedCallback(this.objects);
                        }
                    } else {
                        this.controlError(error);
                    }

                    this.$store.state.showPageLoading = false;
                    if (this.$refs.search) {
                        this.$nextTick(() => this.$refs.search.focus());
                    }
                }
            );
        },
        downloadObjects(object, parameters, callback) {
            this.objects = [];
            this.$store.state.showPageLoading = true;

            let config = {
                org: this.$store.state.org,
                authToken: this.$store.state.account.token,
                object,
                parameters: parameters || {},
            };
            this.$iconico
                .get_objects(config)
                .then((result) => {
                    const response = result.data;
                    callback(response.data || response, null);
                })
                .catch((error) => {
                    callback(null, error);
                });
        },
        columns() {
            var columns = [];
            if (this.navigationInfo.extraColumns) {
                this.navigationInfo.extraColumns.forEach((column, index) => {
                    columns.push(column);
                });
            }
            return columns;
        },
        columnsForm() {
            var columnsForm = [];
            let schemaName =
                this.navigationInfo.schema || this.navigationInfo.object;

            let schema = this.$system.schemas[schemaName];

            if (!schema && this.objectEdit.additionalProperties) {
                schema = this.objectEdit;
            }

            let extraColumns = schema.additionalProperties;
            if (extraColumns) {
                columnsForm = extraColumns;
            }

            return columnsForm;
        },
        rowDetails(row) {
            if (this.disableDisplay) {
                return null;
            } else {
                if (this.showRowDetails) {
                    if (row.additionalProperties) {
                        this.objectDetails = row.additionalProperties;
                    }

                    this.objectEdit = row;

                    let newCrumb = {
                        title: this.getObjectName(row),
                        component: this.getDisplayComponent(),
                        properties: this.getDisplayComponentProperties(),
                    };

                    this.$store.state.crumbs.push(newCrumb);
                }
            }
        },
        getObjectName(object) {
            if (object.first_name && object.last_name) {
                return `${object.first_name} ${object.last_name}`;
            }

            if (object.first_name) {
                return `${object.first_name}`;
            }

            if (object.firstName && object.lastName) {
                return `${object.firstName} ${object.lastName}`;
            }

            if (object.name) {
                return object.name;
            }

            if (object.date) {
                let date = new Date(object.date);
                return this.formatter.format(date);
            }

            if (object.pppuser && object.pppuser.name) {
                return object.pppuser.name;
            }

            if (object.device && object.device.name) {
                return object.device.name;
            }

            let detailsName = `${this.navigationInfo.nameSingular} ${this.$t(
                'core.common.details'
            )}`;

            return detailsName;
        },
        async confirmDelete() {
            const object = this.objectEdit;
            let config = {
                org: this.$store.state.org,
                authToken: this.$store.state.account.token,
                object: this.navigationInfo.object,
                objectId: object._id,
            };
            this.$store.state.modal = null;
            this.$store.state.showPageLoading = true;
            try {
                if (this.deleteObject) {
                    await this.deleteObject(object._id);

                    if (this.activeRefresh) {
                        this.activeRefresh();
                    }
                } else {
                    config = this.adjustForSystemObject(config);
                    let result = await this.$iconico.del_object(config);
                    if (this.reactiveRefresh && this.$system.objects.server) {
                        let newSystem = this.$system.objects.server.filter(
                            (server) => {
                                return server._id != object._id;
                            }
                        );
                        this.$system.objects.server = newSystem;
                        this.$store.state.refresh =
                            this.$store.state.refresh + 1;
                    }
                    this.$store.state.status = 200;
                    this.$store.state.message = this.$t(
                        'core.alerts.success_delete'
                    );

                    this.cancelObjectChanges();

                    if (this.objectRefresh == config.object) {
                        this.probe_refresh(object._id);
                    }
                }
                this.$store.state.showPageLoading = false;
            } catch (error) {
                this.$store.state.showPageLoading = false;
                this.controlError(error);
            }
        },
        pageSelected(page) {
            this.downloadPage(page);
        },
        adjustForSystemObject(config) {
            if (this.systemObject) {
                config.parameters = {
                    params: {
                        iconico_secret: process.env.VUE_APP_ICONICO_SECRET,
                    },
                };
            }
            return config;
        },
        probe_refresh(serverID) {
            let config = {
                org: this.$store.state.org,
                authToken: this.$store.state.account.token,
                object: 'server',
                body: { _id: serverID, probe_refresh: true },
            };
            this.$iconico.put_object(config).catch((error) => {
                this.controlError(error);
            });
        },
        controlError(error) {
            this.$store.state.status = error.response.status || 500;
            this.$store.state.message = error.message;
        },
    },
};
</script>

<style scoped>
.position {
    margin-right: 1.5rem;
    margin-left: 0.5rem;
}
.search-position {
    position: relative;
}
.border-style {
    border-radius: 5px !important;
}
.icon-size {
    transform: scale(1.5);
    position: relative;
    right: 0.8rem;
}
.icon-position {
    transform: scale(1.5);
    position: relative;
    right: 1.3rem;
}
.icon-color {
    background-color: #3975e1;
    text-align: center;
    color: #fff;
}
.icon-multiple-position {
    transform: scale(1.5);
    position: relative;
    right: 0.6rem;
}
.btn-iconico-blue {
    color: #fff;
    background-color: #284b8b;
    border-color: #284b8b;
}
.btn-iconico-blue:hover {
    color: #fff;
    background-color: #3767bf;
    border-color: rgb(47, 75, 202);
}
.btn-iconico-blue:focus,
.btn-iconico-blue.focus {
    color: #fff;
    background-color: #3767bf;
    border-color: rgb(47, 75, 202);
    box-shadow: 0 0 0 0.2rem rgba(21, 119, 199, 0.5);
}
.btn-iconico-blue.disabled,
.btn-iconico-blue:disabled {
    color: #fff;
    background-color: #284b8b;
    border-color: #284b8b;
}
.btn-iconico-blue:not(:disabled):not(.disabled):active,
.btn-iconico-blue:not(:disabled):not(.disabled).active,
.show > .btn-iconico-blue.dropdown-toggle {
    color: #fff;
    background-color: #284b8b;
    border-color: #284b8b;
}
.btn-iconico-blue:not(:disabled):not(.disabled):active:focus,
.btn-iconico-blue:not(:disabled):not(.disabled).active:focus,
.show > .btn-iconico-blue.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(22, 115, 192, 0.5);
}
.btn-iconico-lightblue {
    color: #fff;
    background-color: #3975e1;
    border-color: #3975e1;
}
.btn-iconico-lightblue:hover {
    color: #fff;
    background-color: #5c90f1;
    border-color: rgb(47, 75, 202);
}
.btn-iconico-lightblue:focus,
.btn-iconico-lightblue.focus {
    color: #fff;
    background-color: #3975e1;
    border-color: rgb(47, 75, 202);
    box-shadow: 0 0 0 0.2rem rgba(21, 119, 199, 0.5);
}
.btn-iconico-lightblue.disabled,
.btn-iconico-lightblue:disabled {
    color: #fff;
    background-color: #284b8b;
    border-color: #284b8b;
}
.btn-iconico-lightblue:not(:disabled):not(.disabled):active,
.btn-iconico-lightblue:not(:disabled):not(.disabled).active,
.show > .btn-iconico-lightblue.dropdown-toggle {
    color: #fff;
    background-color: #284b8b;
    border-color: #284b8b;
}
.btn-iconico-lightblue:not(:disabled):not(.disabled):active:focus,
.btn-iconico-lightblue:not(:disabled):not(.disabled).active:focus,
.show > .btn-iconico-lightblue.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(22, 115, 192, 0.5);
}
</style>
