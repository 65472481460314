<template>
    <div class="nav-item dropdown " style="min-width: 14rem">
        <a
            class="nav-link dropdown-toggle py-1 pl-3 pr-3 text-start"
            data-toggle="dropdown"
            href="#"
            role="button"
            aria-haspopup="true"
            aria-expanded="false"
        >
            {{ this.buttonTitle + ": " + selected }}
        </a>

        <div class="dropdown-menu">
            <a
                v-for="(option, index) in options"
                :key="index"
                class="dropdown-item"
                @click="rowSelected(option)"
                href="#"
            >
                <div
                    class="col-12 col-xl-12 text-justify"
                    :active="option === selected"
                >
                    {{ option }} {{ itemTitle }}
                </div>
            </a>
        </div>
    </div>
</template>
<script>
    export default {
        name: "Dropdown",

        props: {
            filterName:{
                type: String
            },
            allFilters:{
                type:Object
            },
            reloadPage:{
                type:Function,
            },
            buttonTitle: {
                type: String,
            },
            options: {
                type: [Array, Object],
            },
            selected: {
                type: [String, Number],
            },
            selectionChanged: {
                type: Function,
            },
            itemTitle: {
                type: String,
            },
            loadFilters: {
                type: Function,
            },
        },
        data() {
            return {};
        },
        mounted() {
  
        },
        methods: {
            rowSelected(option) {
                this.selectionChanged(option);
                if (this.loadFilters) {
                    this.loadFilters();
                }
                if ( this.allFilters){
                    this.allFilters[this.filterName] = this.selectionChanged(option)
                }                
                if (this.reloadPage){
                    this.reloadPage();
                }
         
            },
        },
    };
</script>

<style scoped>
    .dropdown-toggle::after {
        margin: auto;
        position: absolute;
        left: 90%;
        top: 40%;
    }
    .dropdown:hover {
        box-shadow: 4px 4px 6px -5px rgba(56, 68, 180, 0.75);
        -webkit-box-shadow: 4px 4px 6px -5px rgba(56, 68, 180, 0.75);
        -moz-box-shadow: 4px 4px 6px -5px rgba(56, 68, 180, 0.75);
    }
    .dropdown button:hover {
        color: rgba(56, 68, 180, 1);
    }
    .dropdown {
        left: 0;

        /* transform: translateX(-50%) !important; */
        /* top: 100% !important; */
    }
</style>
