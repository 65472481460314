<template>
    <div>
        <ContentPage v-bind="propertiesFunction()" :key="refresh" />
    </div>
</template>

<script>
import Vue from "vue";
export default {
    name: "NetworksTab",
    data() {
        return {
            formatter: new Intl.DateTimeFormat(this.$i18n.locale, {
                month: "short",
                day: "numeric",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
                second: "numeric",
            }),
            refresh: true,
            servers: {},
            navigationInfo: {
                object: "account",
                nameSingular: this.$t("core.common.user"),
                namePlural: this.$t("core.common.users"),
                icon: "user",
                pathApi: {
                    post: "account/invite",
                },
                extraColumns: [
                    {
                        title: "core.common.name",
                        function: this.nameForUser,
                    },
                    {
                        title: "core.common.date",
                        function: this.formatDate,
                    },
                    {
                        title: "core.common.status",
                        function: this.statusForUser,
                    },
                ],
            },
        };
    },
    mounted() {
        this.loadServers();
    },
    methods: {
        rootComponent() {
            let ContentPage = Vue.component("ContentPage");
            return {
                title: this.$t("core.common.users"),
                component: ContentPage,
                properties: this.propertiesFunction(),
            };
        },
        formatDate(userInfo) {
            let date = new Date(userInfo.created);
            return this.formatter.format(date);
        },
        propertiesFunction() {
            return {
                navigationInfo: this.navigationInfo,
                representedObject: "account",
                showPages: true,
                showSearch: false,
                showAdd: false,
                tagObject: false,
                showTags: false,
                searchTerm: this.searchTerm,
                extraDisplayActions: this.extraDisplayActions(),
                deleteConfig: this.deleteConfig(),
                extraParameters: this.extraParameters(),
                buttonConfig: "invite",
            };
        },
        searchTerm(term) {
            return {
                $or: [
                    { first_name: { $regex: term, $options: "i" } },
                    { last_name: { $regex: term, $options: "i" } },
                    { email: { $regex: term, $options: "i" } },
                ],
            };
        },
        extraParameters() {
            return {
                role: "Probe",
            };
        },
        loadServers() {
            let config = {
                org: this.$store.state.org,
                authToken: this.$store.state.account.token,
                object: "server",
                parameters: { params: { select: "name", pagination: false } },
            };

            this.$iconico
                .get_objects(config)
                .then((result) => {
                    const response = result.data.data;
                    const docs = response.docs;

                    docs.forEach(
                        (server) => (this.servers[server._id] = server)
                    );
                })
                .catch((error) => {});
            this.refresh = false;
        },
        isProbe(account) {
            return account.role == "Probe";
        },
        deleteMessage(account) {
            return `Permanently delete the account for ${this.nameForUser(
                account
            )}?`;
        },
        deleteConfig() {
            return {
                deleteMessage: this.deleteMessage,
            };
        },
        statusForUser(userInfo) {
            return userInfo.invitation_token
                ? this.$t("admin.users.invited")
                : this.$t("admin.users.active");
        },
        emailForUser(userInfo) {
            if (this.isProbe(userInfo)) {
                return "";
            }
            return userInfo.email;
        },
        nameForUser(userInfo) {
            if (this.isProbe(userInfo)) {
                const serverIdRegex = /.*-(.*)@.*/g;
                const found = serverIdRegex.exec(userInfo.email);
                if (found && found.length > 1) {
                    const serverId = found[1];
                    const server = this.servers[serverId];
                    if (server) {
                        this.refresh = true;
                        return `${this.$t("admin.users.probe")} ${server.name}`;
                    }
                }
                //Couldn't find server
                return "-";
            }
            return userInfo.last_name + ", " + userInfo.first_name;
        },
        extraDisplayActions() {
            return [
                {
                    type: "resend",
                    action: this.resendInvitation,
                    if: this.hasInvitation,
                },
                /*,
                {
                    type:'deactivate',
                    action:this.deactivate
                }*/
            ];
        },
        hasInvitation(account) {
            return account.invitation_token;
        },
        async resendInvitation(account) {
            let config = {
                org: this.$store.state.org,
                authToken: this.$store.state.account.token,
                body: {},
                accountId: account._id,
            };
            let response = await this.$iconico.account_resend_invitation(
                config
            );
        },
        deactivate(account) {
            alert(JSON.stringify(account));
        },
    },
};
</script>

<style scoped>
</style>
