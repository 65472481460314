<template>
    <div>
        <auth-frame>
            <div class="container-fluid h-100">
                <transition name="fade">
                    <div class="h-100">
                        <div
                            class="
                                text-center text-uppercase text-primary
                                title
                                m-4
                            "
                        >
                            {{ $t("core.await_confirmation.done") }}
                        </div>

                        <img
                            src="../../assets/envelope.svg"
                            class="email-logo email-logo-mobile mx-auto d-block"
                            alt="Email Logo"
                        />

                        <p
                            class="
                                instructions
                                text-center
                                mx-auto
                                d-block
                                text-secondary
                            "
                        >
                            {{ $t("core.await_confirmation.instructions") }}
                        </p>

                        <button
                            class="
                                btn btn-md
                                mx-auto
                                btn-primary btn-block
                                mt-4
                            "
                            @click="goToLogin()"
                            style="max-width: 20rem"
                        >
                            {{ $t("core.sign_in.sign_in") }}
                        </button>
                    </div>
                </transition>
            </div>
        </auth-frame>
    </div>
</template>

<script>
    import AuthFrame from "../forms/AuthFrame.vue";

    export default {
        name: "AwaitConfirmation",
        computed: {
            org() {
                return this.$store.state.org;
            },
        },
        components: {
            AuthFrame,
        },
        data() {
            return {
                orgPath: null,
                isLoaded: false,
            };
        },
        mounted() {
            if (this.org == null) {
                const routePath = this.$router.currentRoute.path;
                this.orgPath = routePath.slice(1);
                this.orgPath = this.orgPath.split("/")[0];
                this.$iconico.get_org_internal(
                    this.orgPath,
                    (orgInfo, error) => {
                        if (orgInfo == null || error != null) {
                            alert("Invalid Organization Path." + error);
                            this.$router.push("/");
                        } else {
                            this.$store.state.org = orgInfo;
                        }
                    }
                );
            }
            this.isLoaded = true;
        },
        methods: {
            goToLogin() {
                this.$router.push("/" + this.orgPath);
            },
        },
    };
</script>

<style lang="scss" scoped>
    .title {
        font-family: HelveticaNeue;
        font-size: 1rem;
        font-weight: 800;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: 2.33px;
    }

    .email-logo {
        max-width: 150px;
        margin-top: 3rem;
        object-fit: contain;
    }

    .instructions {
        font-family: HelveticaNeue;
        margin-top: 3rem;
        max-width: 300px;
        font-size: 0.9rem;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: 2.33px;
    }
</style>
