class Tour {
    constructor(name, i18n, steps) {
        this.name = name;
        this.i18n = i18n;
        this.steps = steps;
        this.hasSeenDescriptor = `hasSeen${name}`;
    }

    hasBeenSeen() {
        const hasSeenTour = localStorage.getItem(this.hasSeenDescriptor);
        return hasSeenTour;
    }

    markSeen() {
        localStorage.setItem(this.hasSeenDescriptor, true);
    }

    options() {
        return {
            labels: {
                buttonSkip: this.i18n.t('core.tour.buttons_skip_tour'),
                buttonPrevious: this.i18n.t('core.tour.buttons_previous'),
                buttonNext: this.i18n.t('core.tour.buttons_next'),
                buttonStop: this.i18n.t('core.tour.buttons_finish'),
            },
        };
    }

    translatedSteps() {
        let translated = this.steps.map(step => {
            let result = {
                target: step.target,
                content: this.i18n.t(step.content),
            };

            let title = step.header && step.header.title;
            if (title) {
                result.header = { title: this.i18n.t(title) };
            }

            if (step.params) {
                result.params = step.params;
            }

            return result;
        });

        return translated;
    }
}

export default Tour;
