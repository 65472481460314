import Vue from 'vue';
import VueRouter from 'vue-router';
import VueCookie from 'vue-cookie'; 
import VueTour from 'vue-tour';

import './sass/main.css';
import 'vue-tour/dist/vue-tour.css';

import "bootstrap/dist/js/bootstrap.min.js";

//Localization
import VueI18n from 'vue-i18n';
import spanish from './lang/es.js';
import english from './lang/en.js';

//Components
import App from './App.vue';
import ChooseOrg from './core/src/components/screens/ChooseOrg.vue';
import CreateOrg from './core/src/components/screens/CreateOrg.vue';
import SignIn from './core/src/components/screens/SignIn.vue';
import AwaitConfirmation from './core/src/components/screens/AwaitConfirmation.vue';
import UpdatePassword from './core/src/components/screens/UpdatePassword.vue';
import Home from './core/src/components/screens/Home.vue';

import {store} from './store';
import * as iconico from './core/src/controllers/iconico';
import * as utilities from './core/src/controllers/utilities';
import * as system from './core/src/controllers/system';
import Tour from './core/src/controllers/tour';

import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js' 
import 'flag-icon-css/css/flag-icon.css'

import {navigationGroups, tabMapping} from './controllers/navigation'

import {loginTourSteps} from './adminTours';

//App
Vue.use(VueI18n);
Vue.use(VueRouter); 
Vue.use(VueCookie);
Vue.use(VueTour);

Vue.config.productionTip = false;
Vue.prototype.$iconico = iconico; 
Vue.prototype.$utilities = utilities; 
Vue.prototype.$system = system; 
// Vue.prototype.$cookie = Vue.cookie;

let iconicoState = store.state;

iconicoState.selectedDashboardTab = "setting";
iconicoState.selectedDashboardTabActive = "settings";
iconicoState.app = 'dags';
iconicoState.downloadSystemObjects = [];


const postLoginVerification = (account) => {

    return new Promise((resolve, reject) => {
        const role = account.role;
        
        if (role === 'Administrator') {
            resolve(account);
        }
        else {
            reject( new Error("Logged in account needs to be an Administrator.") );
        }
    })
};

export const i18n = new VueI18n({
    locale: 'en',
    fallbackLocale: 'en',
    messages: { es: spanish, en: english}
});

const tour = new Tour('LoginTour', i18n, loginTourSteps);

const routes = [
    { path: '/create', component: CreateOrg, meta: {title:'Iconico - Organization'}},
    { path: '/:path', component: SignIn, meta: {title:'Iconico - Login'}, props:{postLoginVerification}},
    { path: '/:path/await', component: AwaitConfirmation, meta: {title:'Iconico - Confirmation'}},
    { path: '/:path/account/updatePassword/:token', component:UpdatePassword, meta: {title:'Iconico - Password'} },
    { path: '/:path/account/acceptInvitation/:token', component:UpdatePassword, meta: {title:'Iconico - Invitation'} },
    { path: '/', component: ChooseOrg, meta: {title:'Iconico - Organization'}},
    { path: '/:path/home', component: Home, meta: {title:'Iconico'}, props:{navigationGroups, tabMapping, tour}}
];

const router = new VueRouter({ 
    routes,
    mode: 'history'
});

const requireComponent = require.context(
    // The relative path of the components folder
    './core/src/components/ui',
    // Whether or not to look in subfolders
    false,
    // The regular expression used to match base component filenames
    /[A-Z]\w+\.(vue|js)$/
);

requireComponent.keys().forEach(fileName => {
    // Get component config
    const componentConfig = requireComponent(fileName);
  
    // Get PascalCase name of component
    const componentName = fileName
        .split('/')
        .pop()
        .replace(/\.\w+$/, '');

    // Register component globally
    Vue.component(
        componentName,
        // Look for the component options on `.default`, which will
        // exist if the component was exported with `export default`,
        // otherwise fall back to module's root.
        componentConfig.default || componentConfig
    );
});
    
new Vue({
    i18n,
    store,
    router,
    render: h => h(App),
}).$mount('#app')
