<template>
    <span class="m-1 p-1 rounded font-lt" :style="style()">
        <i
            v-if="!hideIcon"
            class="fas fa-tag fa-lg ml-1"
            :style="{ color: 'white' }"
        ></i>
        {{ tag ? tag.name || "-" : "" }}
    </span>
</template>

<script>
    import bestContrast from "./bestContrast";

    export default {
        name: "Tag",
        props: ["tag", "size", "hideIcon"],
        methods: {
            style() {
                let colorText = "#000000";
                if (this.tag && this.tag.color) {
                    colorText = this.tag.color;
                }
                let color = bestContrast(colorText);
                let size = this.size || 0.85;

                return {
                    background: colorText,
                    "font-size": `${size}rem`,
                    color,
                };
            },
        },
    };
</script>
