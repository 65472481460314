<template>
    <div class="btn-group dropdown" style="min-width: 14rem">
        <a
            class="nav-link dropdown-toggle py-1 pl-3 pr-3 text-start"
            data-toggle="dropdown"
            href="#"
            role="button"
            aria-haspopup="true"
            aria-expanded="false"
        >
            {{ $t("core.common.tags") }}
        </a>

        <div class="dropdown-menu">
            <a
                v-for="(tag, index) in selections"
                :key="index"
                class="dropdown-item p-1 py-2"
                @click.stop="rowSelected(index)"
                href="#"
            >
                <input type="checkbox" class="ml-2" v-model="tag.selected" />

                <Tag :tag="tag" />
            </a>
        </div>
    </div>
</template>
<script>
    export default {
        name: "Tags",
        props: ["tags", "selectionChanged"],
        mounted() {
            this.selections = this.tags.map((tag) => {
                let option = { ...tag };
                option.selected = false;
                return option;
            });
        },
        data() {
            return {
                selections: [],
            };
        },
        methods: {
            rowSelected(index) {
                let tag = this.selections[index];
                tag.selected = !tag.selected;

                if (this.selectionChanged) {
                    this.selectionChanged(this.selections);
                }
            },
        },
    };
</script>

<style scoped>
    .tag-box {
        display: inline-flex;
        width: 2.6rem;
        height: 1rem;
    }

    .dropdown-menu {
        width: 14rem !important;
        height: 20rem !important;
        overflow-x: hidden;
        overflow-y: scroll;
    }
    .dropdown-toggle::after {
        margin: auto;
        position: absolute;
        left: 90%;
        top: 40%;
    }
    .dropdown:hover {
        box-shadow: 4px 4px 6px -5px rgba(56, 68, 180, 0.75);
        -webkit-box-shadow: 4px 4px 6px -5px rgba(56, 68, 180, 0.75);
        -moz-box-shadow: 4px 4px 6px -5px rgba(56, 68, 180, 0.75);
    }
    .dropdown button:hover {
        color: rgba(56, 68, 180, 1);
    }
    .dropdown {
        left: 0;

        /* transform: translateX(-50%) !important; */
        /* top: 100% !important; */
    }
</style>
