<template>
    <div id="DevicesForm" v-if="equipmentLoad">
        <div class="clearfix">
            <div class="float-right">
                <ActionBar v-if="true" :buttons="actionButtons()" />
            </div>
        </div>
        <div class="Rectangle">
            <div class="sectionForm">
                {{ this.$t("core.device_home.basic_information") }}
            </div>
        </div>
        <div class="card-body">
            <div class="row m-2">
                <!-- <div class="inputStyle col-12 col-xl-4">
                    <SelectSearch
                        :object="'client'"
                        :disabled="!isEdit"
                        :placeholderName="$t('core.common.device')"
                        :selectionChanged="selectionChanged"
                        :itemSelected="equipmentObject.client"
                        :path="'client'"
                        :paramSelect="'name'"
                    />
                </div> -->
                <div class="inputStyle col-12 col-xl-4">
                    <SelectSearch
                        :object="'equipment_type'"
                        :disabled="!isEdit"
                        :placeholderName="$t('core.common.device')"
                        :selectionChanged="selectionChanged"
                        :itemSelected="equipmentObject.equipment_type"
                        :path="'equipment_type'"
                        :paramSelect="'name'"
                    />
                </div>

                <div class="inputStyle col-12 col-xl-4">
                    <input
                        :disabled="!isEdit"
                        v-model="equipmentObject.name"
                        :placeholder="$t('core.common.name')"
                    />
                    <label>{{ $t("core.common.name") }}</label>
                </div>
            </div>
        </div>

        <div class="Rectangle">
            <div class="sectionForm">
                {{ this.$t("core.device_home.location") }}
            </div>
        </div>
        <div class="card-body">
            <div class="row m-2">
                <div class="inputStyle col-12 col-xl-4">
                    <SelectSearch
                        :object="'location'"
                        :disabled="!isEdit"
                        :placeholderName="$t('core.common.device')"
                        :selectionChanged="selectionChanged"
                        :itemSelected="equipmentObject.location"
                        :path="'location'"
                        :paramSelect="'name'"
                    />
                </div>
            </div>
        </div>
        <div class="Rectangle">
            <div class="sectionForm">
                {{ this.$t("core.device_home.basic_information_optional") }}
            </div>
        </div>

        <div class="card-body">
            <div class="row m-2">
               <div class="inputStyle col-12 col-xl-4">
                    <SelectSearch
                        :object="'server'"
                        :disabled="!isEdit"
                        :placeholderName="$t('core.common.device')"
                        :selectionChanged="selectionChanged"
                        :itemSelected="equipmentObject.server"
                        :path="'server'"
                        :paramSelect="'name'"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "EquipmentForm",
    mounted() {
        this.mountedData();
    },
    components: {},
    props: {        
        cancelObjectChanges: {
            type: Function,
        },
        object: {
            type: Object,
        },
        edit:{
            type: Boolean,
            default: false
        },
        actionDelete:{
            type: Function
        }
    },
    data() {
        return {
            equipmentLoad: false,
            equipmentObject: {},
            isEdit:false,      
        };
    },
    methods: {
        mountedData() {
            if (this.object._id) {
                this.equipmentObject.name = this.object?.name;
                this.equipmentObject.server = this.object?.server;
                this.equipmentObject.location = this.object?.location?._id;
                this.equipmentObject.equipment_type = this.object?.type?._id;
                this.equipmentObject.description = this.object?.description;
            }
            this.equipmentLoad = true;
            this.isEdit = this.edit
        },
        async updateEquipment() {
            try {
                let config = {
                    org: this.$store.state.org,
                    authToken: this.$store.state.account.token,
                    object: "equipment",
                    body: {
                        _id: this.object._id,
                        name: this.equipmentObject.name,
                        display_name: this.equipmentObject.display_name,
                        server:this.equipmentObject.server,
                        location: this.equipmentObject.location,
                        type: this.equipmentObject.equipment_type,
                        description: this.equipmentObject.description,
                    },
                };
                let response = await this.$iconico.put_object(config);
                this.$store.state.status = 200;
                this.$store.state.message = `${this.$t("web.notifications.update_equipment")}`;
                this.cancelObjectChanges()
            } catch (error) {
                this.controlError(this.$t("core.alerts.error_create"));
            }
        },
        //------
        async createEquipment() {
            try {
                this.$store.state.showPageLoading = true;
                let config = {
                    org: this.$store.state.org,
                    authToken: this.$store.state.account.token,
                    object: "equipment",
                    body: {
                        name: this.equipmentObject.name,
                        display_name: this.equipmentObject.display_name,
                        location: this.equipmentObject.location,
                        server:this.equipmentObject.server,
                        type: this.equipmentObject.equipment_type,
                        description: this.equipmentObject.description,
                    },
                };
                let response = await this.$iconico.post_object(config);
                this.$store.state.status = 200;
                this.$store.state.message = `${this.$t("web.notifications.create_equipment")}`;
                this.$store.state.showPageLoading = false;
                this.cancelObjectChanges()
            } catch (error) {
                this.$store.state.showPageLoading = false;
                this.controlError(this.$t("core.alerts.error_create"));
            }
        },

        actionButtons() {
            let buttons = [
         
            ];

            if (this.isEdit) {
                buttons.push({ type: 'cancel', action: this.cancelForm });
                buttons.push({ type: 'save', action: this.submitForm });
            } else {
                buttons.push({ type: 'edit', action: this.toggleEdit });
                buttons.push({ type: 'delete', action: this.delete });
            }

        
            return buttons;
        },
        delete(){
            this.actionDelete()
        },
        toggleEdit() {
            this.isEdit = !this.isEdit;
        },

        selectionChanged(object) {
            this.equipmentObject[object.path] = object.value;
        },
        cancelForm() {
            if (this.cancelObjectChanges) {
                this.cancelObjectChanges();
            } else {
                this.toggleEdit();
            }
        },

        submitForm() {
            if (this.object._id) {
                this.updateEquipment();
            } else {
                this.createEquipment();
            }
        },

        controlError(error) {
            let alertInfo = {
                state: this.$store.state,
                title: this.$t("core.alerts.error"),
                message: error,
                bg: "danger",
                timeout: 3,
            };

            this.$utilities.showAlert(alertInfo);
        },
    },
};
</script>

<style scoped>
@import "./../../assets/styleForm.css";
.labelForm {
    text-transform: uppercase;
    color: #3844b4;
    margin: 0px 11px 11px 11px;
}

.sectionForm {
    width: 227px;
    height: 24px;
    margin: 43px 50px 40px 18px;
    font-family: HelveticaNeue;
    font-size: 15px;
    font-weight: bolder;
    line-height: 1.5;
    letter-spacing: 1.78px;
    color: #3844b4;
    text-transform: uppercase;
}
.Rectangle {
    width: 8px;
    height: 30px;
    background-color: #3844b4;
}
.activeDevices {
    width: 1164px;
    height: 16px;
    margin: 35px 11px 35px 11px;
    font-family: HelveticaNeue;
    font-size: 14px;
    font-weight: bolder;
    line-height: normal;
    letter-spacing: 1px;
    color: #3f4555;
    max-width: 540px;
}
.iconPassword {
    border: none;
    right: 15px;
    background-color: #f2f3fb;
    padding: 20px 2px 5px 5px;
    position: absolute;
}
</style>
