<template>
    <div id="TagsInputObject">
        <div class="inputStyle mb-3">
            <InfoAlert
                v-if="isAddTag && isErrorTag"
                class="pt-2 pb-2"
                :icon="'fa-clone'"
                :colorClass="'danger'"
                :text="textAlert()"
            />

            <div class="input-group" v-if="isAddTag">
                <input
                    style="width: 80%"
                    type="text"
                    :placeholder="$t('core.common.tag')"
                    v-model="tagName"
                />
                <label>{{ $t("core.common.tag") }}</label>
                <div class="input-group-prepend">
                    <span
                        style="width: 50px"
                        class="input-group-text"
                        :style="{ background: tagColor }"
                        for="validatedInputGroupSelect"
                    ></span>
                </div>
            </div>

            <button
                type="button"
                v-if="isAddTag"
                class="btn btnSuccess"
                @click="addTagObject()"
            >
                {{ $t("core.common.save") }}
            </button>
            <button
                type="button"
                class="btn fontbase"
                :class="isAddTag ? 'btnDark' : 'btnPrimary'"
                @click="isAddTag = !isAddTag"
            >
                <span v-if="!isAddTag">
                    {{
                        `${this.$t("core.common.add")} ${this.$t(
                            "core.common.tag"
                        )}`
                    }}</span
                >
                <span v-if="isAddTag"> {{ $t("core.common.cancel") }}</span>
            </button>
        </div>
        <slider-picker
            class="w-100 mb-2"
            v-if="isAddTag"
            @input="updateValue"
            v-model="colors"
        />
    </div>
</template>
<script>
    import { Slider } from "vue-color";
    export default {
        name: "TagsInputObject",
        mounted() {},
        components: {
            "slider-picker": Slider,
        },
        props: ["isErrorTag", "addTag"],
        data() {
            return {
                tagName: "",
                tagColor: "#ae0b4f",
                colors: "#ae0b4f",
                isAddTag: false,
            };
        },
        methods: {
            updateValue() {
                this.tagColor = this.colors.hex;
            },
            textAlert() {
                return this.$t("core.common.error_save");
            },
            addTagObject() {
                this.isAddTag = false;
                this.addTag(this.tagName, this.tagColor);
            },
        },
    };
</script>
