<template>
    <div>
        <div class="card-body">
            <div class="row m-2">
                <div class="inputStyle col-12 col-xl-4">
                    <SelectSearch
                        :object="'tag'"
                        :placeholderName="$t('core.common.manufacturer')"
                        :selectionChanged="selectionChanged"
                        :itemSelected="object.manufacturer"
                        :path="'tag'"
                    />
                </div>
            </div>
        </div>
        <div v-for="(field, index) in fields" :key="index">
            <SingleGraph
                :id="object._id"
                :server="object.server._id"
                path="equipment"
                :field="[field.identifier]"
                bucket="monitoring_30d"
                :name="field.name"
                :title="field.name"
            />
        </div>
    </div>
</template>

<script>
import SingleGraph from "./SingleGraph.vue";

export default {
    name: "EquipmentGraphs",
    async mounted() {
        await this.downloadTags();
        await this.downloadValues(this.defaultTag);
    },
    components: {
        SingleGraph,
    },
    props: {
        object: {
            type: Object,
            require: true,
        },
    },
    data() {
        return {
            defaultTag: "",
            selectedTag: "",
            variableResponse: [],
            fields: [],
        };
    },
    methods: {
        async selectionChanged(object) {
            if (object?.value) {
                this.selectedTag = object.value;
            }
            await this.downloadValues(this.selectedTag);
        },
        async downloadTags() {
            let params = {
                pagination: false,
            };
            const parameters = {
                params,
            };
            let config = {
                org: this.$store.state.org,
                authToken: this.$store.state.account.token,
                object: "tag",
                parameters: parameters || {},
            };
            let response = await this.$iconico.get_objects(config);

            if (response?.data?.data?.docs[0]?._id) {
                this.defaultTag = response.data.data.docs[0]._id;
            }
            return response;
        },
        async downloadValues(tagID) {
            this.fields = [];
            let params = {
                pagination: false,
            };
            if (tagID) {
                params.find = { tags: tagID };
            }
            const parameters = {
                params,
            };
            let config = {
                org: this.$store.state.org,
                authToken: this.$store.state.account.token,
                object: "variable",
                parameters: parameters || {},
            };
            let response = await this.$iconico.get_objects(config);
            if (response?.data?.data?.docs) {
                this.variableResponse = response.data.data.docs.forEach(
                    (element) => {
                        this.fields.push({
                            identifier: element.identifier,
                            name: element.name,
                        });
                    }
                );
            }
            return response;
        },
    },
};
</script>

<style>
</style>