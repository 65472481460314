const objects = {
    latency: [
        { value: 'rtt_avr', text: 'rtt_avr' },
        { value: 'host_live', text: 'host_live' },
        { value: 'pck_lost', text: 'pck_lost' },
    ],
    resources: [
        { value: 'cpu', text: 'cpu' },
        { value: 'used_memory', text: 'used_memory' },
        { value: 'used_disk', text: 'used_disk' },
    ],
    interfaces: [
        { value: 'sfp-rx-power', text: 'sfp_rx_power' },
        { value: 'sfp-tx-power', text: 'sfp_tx_power' },
        { value: 'sfp-temperature', text: 'sfp_temperature' },
    ],
    health: [
        { value: 'temperature', text: 'temperature' },
        { value: 'cpu-temperature', text: 'cpu_temperature' },
        { value: 'voltage', text: 'voltage' },
        { value: 'current', text: 'current' },
    ],
    bgp: [{ value: 'prefix-count', text: 'prefix_count' }],
    poe: [
        { value: 'voltage', text: 'voltage' },
        { value: 'power', text: 'power' },
        { value: 'current', text: 'current' },
    ],
    wireless: [
        { value: 'signal-strength', text: 'signal_strength' },
        { value: 'signal-strength-ch0', text: 'signal_strength_ch0' },
        { value: 'signal-strength-ch1', text: 'signal_strength_ch1' },
        { value: 'signal-strength-ch2', text: 'signal_strength_ch2' },
        { value: 'signal-to-noise', text: 'signal_to_noise' },
        { value: 'noise', text: 'noise' },
        { value: 'tx-ccq', text: 'tx_ccq' },
        { value: 'rx-ccq', text: 'rx_ccq' },
        { value: 'tx-signal-strength', text: 'tx_signal_strength' },
        { value: 'tx-signal-strength-ch0', text: 'tx_signal_strength_ch0' },
        { value: 'tx-signal-strength-ch1', text: 'tx_signal_strength_ch1' },
        { value: 'tx-signal-strength-ch2', text: 'tx_signal_strength_ch2' },
    ],
    // w60g:[
    //     { value: 'tx-mcs' },
    //     { value: 'tx-phy-rate' },
    //     { value: 'signal' },
    //     { value: 'rssi' },
    //     { value: 'tx-packet-error-rate' }
    // ]
};
module.exports = objects;
