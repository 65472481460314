<template>
    <div>
        <auth-frame>
            <div class="container-fluid h-100">
                <transition name="fade">
                    <form class="form-signin h-100" @submit="onSubmit">
                        <div v-if="!showReset" class="h-100">
                            <div class="d-flex flex-column h-100">
                                <div class="row justify-content-center">
                                    <div
                                        class="col"
                                        :class="
                                            this.$vssWidth < 900
                                                ? 'custom-position'
                                                : ''
                                        "
                                    >
                                        <div
                                            v-if="this.$store.state.org"
                                            class="
                                                text-center text-primary
                                                choose-subtitle
                                                m-4
                                            "
                                        >
                                            {{ this.$store.state.org.name }}
                                            {{ $t("core.sign_in.login") }}
                                        </div>

                                        <label for="inputEmail" class="mt-2">
                                            {{ $t("core.common.email") }}
                                        </label>

                                        <input
                                            type="email"
                                            id="inputEmail"
                                            v-model="email"
                                            class="
                                                form-control form-control-lg
                                                mb-2
                                            "
                                            :placeholder="
                                                $t('core.common.email')
                                            "
                                            required
                                            autofocus
                                        />

                                        <label for="inputPassword" class="mt-2">
                                            {{ $t("core.sign_in.password") }}
                                        </label>

                                        <input
                                            type="password"
                                            id="inputPassword"
                                            v-model="password"
                                            class="
                                                form-control form-control-lg
                                                mb-2
                                            "
                                            :placeholder="
                                                $t('core.sign_in.password')
                                            "
                                            required
                                        />

                                        <button
                                            class="
                                                btn btn-lg btn-primary btn-block
                                                my-4
                                            "
                                            type="submit"
                                        >
                                            <span
                                                class="
                                                    spinner-grow
                                                    postition-relative
                                                    float-right
                                                "
                                                v-if="isSpinner"
                                                role="status"
                                                aria-hidden="true"
                                            >
                                            </span>

                                            {{ $t("core.sign_in.sign_in") }}
                                        </button>
                                    </div>
                                </div>

                                <div
                                    class="
                                        row
                                        justify-content-center
                                        mt-1
                                        align-items-end
                                        flex-grow-1
                                    "
                                >
                                    <div class="col">
                                        <button
                                            href="#"
                                            @click="showReset = !showReset"
                                            class="
                                                btn btn-block
                                                p-0
                                                forgot-link
                                            "
                                        >
                                            {{
                                                $t(
                                                    "core.sign_in.forgot_your_password"
                                                )
                                            }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="showReset">
                            <div
                                class="
                                    text-center text-primary
                                    choose-subtitle
                                    m-4
                                "
                            >
                                {{ $t("core.sign_in.reset_password") }}
                            </div>

                            <label for="inputEmail" class="mt-2">
                                {{ $t("core.common.email") }}
                            </label>

                            <input
                                type="email"
                                id="inputEmail"
                                v-model="email"
                                class="form-control form-control-lg mb-4"
                                :placeholder="$t('core.common.email')"
                                required
                                autofocus
                            />

                            <button
                                class="btn btn-lg btn-primary btn-block my-4"
                                type="submit"
                            >
                                {{ $t("core.sign_in.reset") }}
                            </button>

                            <button
                                class="
                                    btn btn-lg btn-outline-secondary btn-block
                                    my-4
                                "
                                @click="showReset = !showReset"
                            >
                                {{ $t("core.common.cancel") }}
                            </button>
                        </div>
                    </form>
                </transition>
            </div>
        </auth-frame>
    </div>
</template>

<script>
    import AuthFrame from "../forms/AuthFrame.vue";
    import VueScreenSize from "vue-screen-size";

    export default {
        mixins: [VueScreenSize.VueScreenSizeMixin],
        name: "SignIn",
        computed: {
            org() {
                return this.$store.state.org;
            },
        },
        components: {
            AuthFrame,
        },
        data() {
            return {
                get_image_url_from_path: this.$iconico.get_image_url_from_path,
                get_static_image_url: this.$iconico.get_static_image_url,
                orgPath: null,
                email: null,
                password: null,
                showReset: false,
                isSpinner: false,
                isLoaded: false,
            };
        },
        mounted() {
            let Account = JSON.parse(this.$cookie.get("Account"));
            let Org = JSON.parse(this.$cookie.get("Org"));
            if (localStorage.getItem("lang")) {
                this.$i18n.locale = localStorage.getItem("lang");
            } else {
                if (Account != null && Account.lang) {
                    this.$i18n.locale = Account.lang;
                } else {
                    if (this.$store.state.org && this.$store.state.org.lang) {
                        this.$i18n.locale = this.$store.state.org.lang;
                    }
                }
            }
            if (Org != null && Account != null) {
                this.$store.state.org = Org;
                this.$store.state.account = Account;
                
                this.$router.push("/" + Org.path + "/home");
            } else {
                if (this.org == null) {
                    this.orgPath = this.$router.currentRoute.path.replace(
                        /\//g,
                        ""
                    );

                    this.$iconico.get_org_internal(
                        this.orgPath,
                        (orgInfo, error) => {
                            if (orgInfo == null || error != null) {
                                this.showAlert(
                                    "Invalid Organization Path.",
                                    error,
                                    "danger"
                                );
                            } else {
                                this.$store.state.org = orgInfo;
                                this.$cookie.set(
                                    "Org",
                                    JSON.stringify(orgInfo),
                                    {
                                        expires: "12h",
                                        domain: "iconico.us",
                                    }
                                );
                                if (orgInfo.lang) {
                                    this.$i18n.locale = orgInfo.lang;
                                }
                            }
                        }
                    );
                }
            }
            this.isLoaded = true;
        },
        props: ["postloginVerification","loginVerification"],
        methods: {
            orgHasLogo() {
                return this.$store.state.org && this.$store.state.org.logo;
            },
            showAlert(title, message, bg) {
                let self = this;

                let showCallback = function() {
                    self.isSpinner = false;
                    if (self.showReset) {
                        self.showReset = false;
                    }
                };

                let hideCallback = function() {
                    if (!self.$store.state.org) {
                        self.$router.push("/");
                    }
                };

                let alertInfo = {
                    state: this.$store.state,
                    title: this.$t("core.sign_in.error_signin"),
                    message: this.$t("core.sign_in.error_password"),
                    bg,
                    showCallback,
                    hideCallback,
                    timeout: 3,
                };

                this.$utilities.showAlert(alertInfo);
            },
            onSubmit(event) {
                this.isSpinner = true;
                event.preventDefault();

                const body = {
                    email: this.email,
                    password: this.password,
                };

                const org = this.$store.state.org;
                if (!this.showReset) {
                    this.$iconico
                        .account_login(org, body)
                        .then((result) => {
                            const response = result.data;

                            if (this.postloginVerification) {
                                return this.postloginVerification(response);
                            }


                            return response;
                        })
                        .then((response) => {
                            this.$store.state.account = response;
                            this.$store.state.org = response.org;
                            this.$i18n.locale =
                                response.lang || response.org.lang;
                            this.$cookie.set(
                                "Org",
                                JSON.stringify(response.org),
                                {
                                    expires: "12h",
                                    domain: "iconico.us",
                                }
                            );
                            this.$cookie.set(
                                "Account",
                                JSON.stringify(response),
                                {
                                    expires: "12h",
                                    domain: "iconico.us",
                                }
                            );
                            this.$cookie.set("lang", this.$i18n.locale);
                            this.isSpinner = false;
                            let route ="/home"
                            if(this.$store.state.account && this.loginVerification){                                
                                route = this.loginVerification(this.$store.state.account)
                            }
                            this.$router.push(
                                this.$router.currentRoute.path + route
                            );
                        })
                        .catch((error) => {
                            this.showAlert(
                                "Failed to Log In.",
                                error,
                                "danger"
                            );
                        });
                } else {
                    this.$iconico.account_accept(
                        org,
                        "resetPassword",
                        body,
                        (response, error) => {
                            if (error != null || response == null) {
                                this.showAlert(
                                    "Failed to Reset Password.",
                                    error,
                                    "danger"
                                );
                            } else {
                                this.showAlert(
                                    "Success",
                                    "We'll send a password reset email.",
                                    "success"
                                );
                            }
                        }
                    );
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    .custom-position {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .form-signin {
        align-items: center;
        width: 80%;
        //padding: 15px;
        margin: auto;
    }

    .form-signin .checkbox {
        font-weight: 400;
    }

    .form-signin .form-control:focus {
        z-index: 2;
    }

    .logo {
        object-fit: contain;
    }

    .choose-subtitle {
        font-family: HelveticaNeue;
        font-size: 1.3rem;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: 2.33px;
    }

    .forgot-link {
        margin-bottom: 5rem;

        font-family: HelveticaNeue;
        font-size: 1.3rem;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 2px;
        text-align: center;
        text-decoration: none;
        color: #6b7285;

        &:hover,
        &:focus,
        &:active {
            color: var(--primary);
        }
    }
</style>
