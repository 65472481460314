import Vue from 'vue';
import Vuex from 'vuex';
import Iconico from './core/src/store';

Vue.use(Vuex);


export const store = new Vuex.Store({
    state: {
        ...Iconico,
        scriptRunnerRequests: [],
        consoleRequests: []
    },
    mutations: {

    },
    actions: {

    }
});
