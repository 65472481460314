export default {
    common: {
        general_search: 'Busqueda General',
        invite: 'invitar',
        cancel: 'Cancelar',
        add: 'Agregar',
        default: 'Predeterminada',
        save: 'Guardar',
        save_and_add: 'Guardar & Crear Otro',
        edit: 'Editar',
        latitude: 'Latitud',
        longitude: 'Longitud',
        delete: 'Eliminar',
        confirm_delete: 'Borrar Objeto',
        confirm_reload: 'Restaurar Objeto',
        delete_body: '¿Estás seguro que quieres borrar',
        reload_body: '¿Estás seguro que quieres restaurar',
        close: 'Cerrar',
        run: 'Ejecutar',
        email: 'Email',
        name: 'Nombre',
        path: 'Código',
        parent: 'Padre',
        first_name: 'Nombre',
        last_name: 'Apellido',
        yes: 'Sí',
        no: 'No',
        version: 'Versión',
        role: 'Rol',
        change_logo: 'Cambiar Logo',
        logo: 'Logo',
        mobile: 'Teléfono',
        server: 'Red',
        servers: 'Redes',
        device: 'Dispositivo',
        devices: 'Dispositivos',
        description: 'Descripción (Opcional)',
        page_size: 'Tamaño Página',
        create_many: 'Crear Varios',
        status: 'Estado',
        script: 'Script',
        scripts: 'Scripts',
        file: 'Archivo',
        code: 'Código',
        app_code: 'Aplicación',
        method: 'Método HTTP',
        object: 'Objeto',
        time: 'Tiempo de Ejecución',
        event: 'Evento',
        events: 'Eventos',
        cron: 'Cron',
        ip: 'IP',
        total_cycles: 'Ciclos Totales',
        manufacturer: 'Fabricante',
        update_wireless: 'Actualizar Wireless',
        type: 'Tipo',
        date: 'Fecha',
        interface: 'Interfaz',
        interfaces: 'Interfaces',
        uptime: 'Uptime',
        ram: 'RAM',
        cpu: 'CPU',
        disk: 'Disco',
        search: 'Buscar',
        operator: 'Operador',
        user: 'Usuario',
        administrator: 'Administrador',
        users: 'Usuarios',
        details: 'Detalles',
        content: 'Contenido',
        schema: 'Esquema',
        schemas: 'Esquemas',
        required: 'Necesario',
        image: 'Imagen',
        probe_refresh: 'Probe Refresh',
        type_device: 'Tipo Dispositivo',
        active: 'Activo',
        sign_out: 'Cerrar Sesión',
        inactive: 'Inactivo',
        resend: 'Reenviar',
        deactivate: 'Desactivar',
        device_name: 'Nombre Dispositivo',
        hostname: 'Nombre de host',
        ipv4: 'IP v4',
        ipv6: 'IP v6',
        ssh_user: 'Usuario',
        ssh_pass: 'Contraseña',
        password: 'Contraseña',
        token: 'Token',
        port: 'Puerto',
        api_port: 'API Puerto',
        ssh_port: 'SSH Puerto',
        snmp_community: 'SNMP Comunidad',
        snmp_version: 'SNMP Versión',
        probe_time: 'Periodo de Red',
        sensor_category: 'Categoría de Sensores',
        sensors: 'Sensores',
        resources: 'Recursos',
        mqtt: 'MQTT',
        log: 'Log',
        logs: 'Logs',
        cpu_load: 'CPU Load',
        memory_used: 'Memory Used',
        disk_used: 'Disk Used',
        temperature: 'Temperatura',
        temperature_cpu: 'Temperatura CPU',
        temperature_board: 'Temperatura Board',
        current: 'Corriente',
        fan_speed: 'Fan Speed',
        voltage_psu: 'Voltaje PSU',
        current_psu: 'Corriente PSU',
        voltage: 'Voltaje',
        system_health: 'Salud del Sistema',
        system_resources: 'Recursos del Sistema',
        cpuModel: 'CPU Model',
        last_probe: 'Último Sondeo',
        log_probe: 'Log Probe',
        sensor: 'Sensor',
        values: 'Valores',
        value: 'Valor',
        consumption: 'Consumo de energía',
        color: 'Color',
        tag: 'Etiqueta',
        tags: 'Etiquetas',
        modules: 'Módulos',
        module: 'Módulo',
        notification_config: 'Configuración de Notificaciones',
        notification: 'Notificación',
        action: 'Acción',
        actions: 'Acciones',
        event_code: 'Event Code',
        action_user_tag: 'Etiqueta de usuario',
        event_severity: 'Gravedad del evento',
        action_user_id: 'Usuario de la acción',
        notifications: 'Notificaciones',
        all: 'Todos',
        select: 'Seleccionar',
        new: 'Nuevo',
        templates: 'Plantillas',
        test: 'Probar',
        configuration: 'Configuración',
        multiple: 'Múltiple',
        line: 'Línea',
        for: 'Para',
        any_device: 'Todos los Dispositivos',
        send: 'Enviar',
        sms: 'SMS',
        app: 'Aplicación',
        whatsApp: 'WhatsApp',
        telegram: 'Telegram',
        telegram_bot: 'Ver Bot de Telegram',
        telegram_installed: 'Telegram está instalado en esta organización',
        telegram_installation:
            'Invite Iconico_Bot a un canal de Telegram y ejecute el siguiente comando:',
        slack: 'Slack',
        team_name: 'Nombre de Equipo',
        channel: 'Canal',
        remove_slack: 'Remover Slack',
        to: 'A',
        recipients: 'Destinatarios',
        subject: 'Sujeto',
        event_device: 'Dispositivo de acción',
        hours: 'Horas',
        days: 'Días',
        no_label: 'Sin Etiqueta',
        is: 'Es',
        connection: 'Conexión',
        error_save: 'Error al crear un nuevo Tag',
        probe: 'Sondeo',
        dashboard: 'Tablero',
        reload: 'Forzar Actualización',
        add_device: 'Agregar Dispositivo',
        category_name: 'Categoría',
        installation: 'Instalación',
        offline_devices: 'Dispositivos Desconectados',
        not_subscribed: 'No Suscripto',
        not_subscribed_message:
            'Usted no está suscripto a esta aplicación. Visite la aplicación de administración para instalarla.',
        info_nav: 'Le quedan',
        info_trialdays: 'días de prueba, recuerde agregar método de pago',
        identifier: 'Identificador',
        unit: 'Unidad de medida',
    },
    servers: {
        online: 'Conectado',
        offline: 'Desconectado',
        probing: 'Controlando',
    },

    users: {
        invited: 'Invitado',
        active: 'Activo',
        invite: 'Invitar Usuario',
        probe: 'Red',
    },

    sensor: {
        tension: 'tensión',
        rtt_avr: 'Promedio de Tiempo Ida y Vuelta',
        host_live: 'Host Vivo',
        pck_lost: 'Paquetes Perdidos',
        cpu: 'CPU',
        used_memory: 'Memoria Usada',
        used_disk: 'Disco Usado',
        sfp_rx_power: 'sfp_rx_power',
        sfp_tx_power: 'sfp_tx_power',
        sfp_temperature: 'sfp_temperature',
        temperature: 'temperature',
        cpu_temperature: 'cpu_temperature',
        voltage: 'voltage',
        current: 'current',
        prefix_count: 'prefix_count',
        power: 'power',
        signal_strength: 'signal_strength',
        signal_strength_ch0: 'signal_strength_ch0',
        signal_strength_ch1: 'signal_strength_ch1',
        signal_strength_ch2: 'signal_strength_ch2',
        signal_to_noise: 'signal_to_noise',
        noise: 'noise',
        tx_ccq: 'tx_ccq',
        rx_ccq: 'rx_ccq',
        tx_signal_strength: 'tx_signal_strength',
        tx_signal_strength_ch0: 'tx_signal_strength_ch0',
        tx_signal_strength_ch1: 'tx_signal_strength_ch1',
        tx_signal_strength_ch2: 'tx_signal_strength_ch2',
    },
    electric: {
        voltage_a: 'Voltaje Fase A-B',
        voltage_b: 'Voltaje Fase B-C',
        voltage_c: 'Voltaje Fase C-A',
        current_a: 'Corriente Fase A',
        current_b: 'Corriente Fase B',
        current_c: 'Corriente Fase C',
        power_a: 'Potencia Activa Fase A',
        power_b: 'Potencia Activa Fase B',
        power_c: 'Potencia Activa Fase C',
        power_total: 'Potencia Activa Total',
        voltage_out_a: 'Voltaje Fase A',
        voltage_out_b: 'Voltaje Fase B',
        voltage_out_c: 'Voltaje Fase C',
        reactive_power_a: 'Potencia Reactiva Fase A',
        reactive_power_b: 'Potencia Reactiva Fase B',
        reactive_power_c: 'Potencia Reactiva Fase C',
        reactive_power_total: 'Potencia Reactiva Total',
        apparent_power_a: 'Potencia Aparente Fase A',
        apparent_power_b: 'Potencia Aparente Fase B',
        apparent_power_c: 'Potencia Aparente Fase C',
        apparent_power_total: 'Potencia Aparente Total',
        power_factor_a: 'Factor de Potencia Fase A',
        power_factor_b: 'Factor de Potencia Fase B',
        power_factor_c: 'Factor de Potencia Fase C',
        power_factor_total: 'Factor de Potencia Total',
        frequency: 'Frecuencia',
    },
    measure: {
        voltage: 'V (Voltaje)',
        kwh: 'kWh (Kilo Watt / Hora)',
        current: 'A (Amperes)',
        milliseconds: 'MS (Milisegundos)',
        percentage: '% (Porcentaje)',
        megabytes: 'MB (Megabyte)',
        megabitspersecond: 'Mbps (Megabits por segundo)',
        gigabyte: 'GB (Gigabyte)',
        degrees: '°C (Grados Celsius)',
        hertz: 'Hz (Hertz)',
    },
    device_home: {
        ap: 'AP',
        cpe: 'CPE',
        router: 'Router',
        edgeswitch: 'EdgeSwitch',
        switch: 'Switch',
        other: 'Otros',
        no_sensor: 'Sin sensores para este dispositivo',
        manufacturer_type: 'Fabricante & Tipo',
        basic_information: 'Información básica',
        access: 'Acceso',
        location: 'Localización (Opcional)',
        tags: 'Etiquetas (Opcional)',
        active_device:
            'Sólo los dispositivos activos son controlados. Es útil crear dispositivos y dejarlos inactivos si quieres tenerlos aquí pero no están todavía conectados a tu red.',
    },
    sign_in: {
        please: 'Por favor, registrese',
        please_to: 'Inicia sesión en',
        password: 'Contraseña',
        sign_in: 'Ingresar',
        forgot_your_password: 'Olvidaste tu contraseña?',
        reset_password: 'Restablecer la contraseña',
        reset: 'Restablecer',
        lang: 'Idioma',
        error_signin: 'Credenciales Inválidas',
        error_password: 'Controle su usuario o contraseña',
    },
    navigation: {
        run: 'Ejecutar',
        previous: 'Anterior',
        next: 'Siguiente',
        settings: 'Configuración',
        iconico_apps: 'Aplicaciones de Iconico',
    },
    startup: {
        line1:
            'Iconico es una plataforma completa de Software de Redes con muchas aplicaciones desarrolladas.',
        line2: 'Sigue Conectado',
    },
    choose_org: {
        choose_organization: 'Continuar',
        organization_path: 'Código de la Organización',
        type_name: 'Escribe el código de su organización',
        create_organization: 'Crear Organización',
        org_not_found: 'Organización no encontrada',
        org_error: 'No hay ninguna organización con código',
        org_check:
            '. Por favor chequea si hay errores o crea una organización nueva',
    },
    password_reset: {
        reset_password: 'Restablecer Contraseña',
        invitation: 'Aceptar Invitación',
        choose_password: 'Elegir Contraseña',
        confirm_email: 'Confirmar Correo Electrónico',
        save: 'Guardar',
        reset: 'Restablecer',
        accept_invitation: 'Aceptar Invitación',
    },
    await_confirmation: {
        done: 'Listo!',
        instructions:
            'Por favor verifique su correo y siga las instrucciones para completar la creación de su organización.',
    },
    create_org: {
        org_path: 'Código',
        org_name: 'Nombre',
        administrator_info: 'Administrador',
        organization_info: 'Organización',
        create_organization: 'Crear Organización',
        success_message:
            'Organización Creada. Por favor verifique su correo para continuar.',
        error_message:
            'Hubo un error creando la organización. Contacte support@iconico.us si el problema persiste.',
    },
    articles: {
        generic: 'el',
        male: 'el',
        female: 'la',
        account: 'el',
        server: 'la',
        device: 'el',
        sensor: 'el',
    },
    objects: {
        account: 'Usuario',
        server: 'Red',
        device: 'Dispositivo',
        sensor: 'Sensor',
    },
    account: {
        account_settings: 'Configuración de Cuenta',
        modified: 'Cuenta modificada',
        picture: 'Foto',
        language: 'Idioma',
        change_password: 'Cambiar Contraseña',
        save_password: 'Guardar Contraseña',
    },
    content: {
        confirm_delete: '¿Estás seguro que deseas borrar',

        user: 'el Usuario',
        object: 'el Objeto',
        delete_title: 'Confirmación',
        delete_button: 'Borrar',
    },
    empty: {
        title: 'Sin Elementos',
        title_search: 'No Encontrado',
        message: 'No hay objetos.',
        message_search: 'Ningún objeto se ajusta a su búsqueda.',
        empty_message: 'No hay elementos  en este nivel.',
        footer: 'Se pueden crear objetos en cualquier momento',
        back_to: 'Volver a',
    },
    notifications: {
        reload_explanation: `Forzar actualización de la aplicación sonda para volver a cargar todos los fabricantes, dispositivos, sensores, y reiniciar el proceso de sondeo.`,
        notifications: 'Notificaciones',
        notification_templates: 'Plantillas de Notificación',
        information:
            'Las plantillas de notificación contienen la estructura del contenido de un tipo particular de notificación. Las plantillas se utilizan normalmente con parámetros para rellenar información adicional.',
        parameters: 'Para este evento tenemos los siguientes parametros',
        content: 'Las variables de notificación deben ir entre llaves {var}',
        predetermined: 'Predeterminado',
    },
    create_multiple: {
        save_multiple: 'Se guardaron {count} registros con éxito',
    },
    alerts: {
        probe_refresh_success: 'Red Recargada Éxitosamente!',
        success: 'Éxito',
        error: 'Error',
        copied_text: 'Copiado al portapapeles!',
        success_modify: 'Objeto fue correctamente modificado',
        success_delete: 'Objeto fue correctamente borrado',
        success_create: 'Objeto fue correctamente creado',
        error_create: 'Hubo un error creando el objeto',
        error_refresh: 'Hubo un error recargando el objeto',
        error_delete: 'Hubo un error borrando el objeto',
        error_modify: 'Hubo un error modifcando el objeto',
        error_loading: 'Hubo un error cargando la información necesaria',
    },
    validate: {
        required: 'No se permite estar vacío',
        invalid_ip4_format: 'Formato de IP Versión 4 inválido',
        invalid_ip6_format: 'Formato de IP Versión 6 inválido',
        number: 'Tiene que ser un número',
    },
    install_server: {
        install_server_info: `Es necesario instalar la red Iconico mediante docker en una computadora o máquina virtual en su red.`,
        install_app: `Esta aplicación se va a conectar a los distintos equipos para realizar sondeos de conectividad, latencia, etc.`,
        install_process: `El proceso de instalación es simple, hay que descargar la imagen docker de la red y luego crear un contenedor.`,
        install_download_docker: `Descargar docker:`,
        install_download_server: `Descargar la red:`,
        docker_desktop: 'Instalar Docker para Desktop',
        install_run: `Descomprimir y ejecutar:`,
        full_command: 'Comando completo',
        install_run_linux: `o ejecutar el siguiente comando en una distribución Linux como Ubuntu:`,
        install_run_linux_command: `sudo apt install docker.io`,
    },
    events: {
        critical: 'Crítico',
        serious: 'Serio',
        warning: 'Advertencia',
        info: 'Información',
        minor: 'Menor',
        severity: 'Gravedad',
        code: 'Código',
        variable: 'Variable',
        alarm: 'Alarma',
        value_before: 'Valor anterior',
        value_actual: 'Valor actual',
        no_event_device: 'No se encuentran eventos para este device',
        no_event: 'No se encuentran eventos',
        device_reboot: 'Dispositivo reiniciado',
        device_upgrade: 'Actualizar la versión SO',
        device_hardware: 'HARDWARE cambiado, precaución',
        firmware_upgrade: 'Actualización de firmware',
        device_identity: 'Cambio de nombre de dispositivo',
    },
    tour: {
        buttons_skip_tour: 'Saltar Guía',
        buttons_previous: 'Anterior',
        buttons_next: 'Siguiente',
        buttons_finish: 'Terminar',
    },
    maps: {
        map_style: 'Map Style',
        streets: 'Streets',
        outdoors: 'Outdoors',
        light: 'Light',
        dark: 'Dark',
        satellite: 'Satellite',
        satellite_streets: 'Satellite Streets',
    },
};
