import core from '../core/src/lang/es.js';

export default {
    core,
    web:{
        navigation:{
            'equipment':'EQUIPOS',
            'alarms': 'Alertas',
            'monitoring': 'Monitoreo',
            'equipment_types': 'TIPOS DE EQUIPO',
            'sensor_types': 'TIPOS DE SENSOR',
            'variable': 'VARIABLES',
            'equipment_details':'DETALLES DE EQUIPOS',
            'equipment_graphs':'GRAFICOS'

        },
        common:{
            'client':'Cliente',
            'type':'Tipo',
            'location':'Ubicación',
            'last_service':'Ultimo Servicio',
            'refrigerators':'Refrigeradores',
            'alarms_last_month':'Alarmas Ultimo Mes'
        },
        notifications:{
            'create_equipment':'Equipo Creado!',
            'update_equipment':'Equipo Actualizado!',
        }
    },
//     admin: {
//         navigation: {
//             'organization': 'ORGANIZACIÓN',
//             'network': 'RED',
//             'apps': 'APLICACIONES',
//             'developer': 'DESARROLLADOR',
//             'users': 'Usuarios',
//             'billing': 'Facturación',
//             'activity': 'Actividad',
//             'notifications': 'Notificaciones',
//             'servers': 'Servidores',
//             'objects_and_schemas': 'Objetos',
//             'scripts': 'Scripts',
//             'logs': 'Logs',
//             'console': 'Consola',
//             'sockets':'Sockets',
//             'info':'... Más Información'
//         },
//         users: {
//             'invited': 'Invitado',
//             'active': 'Activo',
//             'invite': 'Invitar Usuario',
//             'probe': 'Red'
//         },
//         tour: {
//             'app_launcher_title': 'Abrir Aplicación',
//             'app_launcher_message': 'Selecciona aplicaciones instaladas aquí',
//             'account_settings_title': 'Configuración de Cuenta',
//             'account_settings_message': 'Haga click aquí para cambiar preferencias de cuenta',
//             'org_settings_title': 'Configuración de Organización',
//             'org_settings_message': `Cambiar preferencias de la organización:
//             <ul>
//             <li>Lenguaje</li>
//             <li>Imagen de Logo</li>
//             </ul>`,
//             'users_title': 'Administrar Usuarios',
//             'users_message': `
//             <ul>
//             <li>Invitar nuevos usuarios</li>
//             <li>Cambiar roles</li>
//             <li>Borrar usuarios</li>
//             </ul>`,
//             'apps_title': 'Administrar aplicaciones',
//             'apps_message': 'Instalar nuevas aplicaciones en su organización'
//         },
//         organization: {
//             'settings': 'Opciones de la Organización',
//             'main_information': 'Información',
//             'self_registration': 'Permitir Registración',
//             'administrators': 'Administradores',
//             'select_file': 'Seleccionar Archivo'
//         },
//         billing: {
//             'success_delete': '¡La tarjeta fue eliminada con exito!',
//             'success': '¡La tarjeta fue cargada con exito!',
//             'error': 'Hubo un error al cargar la tarjeta',
//             'card': 'Tarjeta de Crédito',
//             'cards': 'Tarjetas de Crédito',
//             'order': 'Factura',
//             'orders': 'Facturas',
//             'number': 'Número',
//             'default': 'Principal',
//             'expiry': 'Vencimiento',
//             'expires': 'Vence',
//             'order_id': 'Identificador',
//             'total': 'Total',
//             'type': 'Tipo',
//             'manual': 'Manual',
//             'credit_card': 'Tarjeta de Crédito',
//             'payment_methods': 'Métodos de Pago',
//             'subscriptions': 'Suscripciones',
//             'download': 'Descargar',
//             'download_invoice': 'Descargar Factura',
//             'receipts': 'Recibos',
//             'product': 'Producto',
//             'products': 'Productos',
//             'subscribe': 'Suscribirse',
//             'unsubscribe': 'Desuscribirse',
//             'period_end': 'Fin de Período',
//             'estimated_billing': 'Facturación Estimada',
//             'status': 'Estado',
//             'active': 'Activa',
//             'usage': 'Uso',
//             'currency': 'Moneda',
//             'make_default': 'Hacer Principal',
//             'subscription_details': 'Detalles de Suscripción',
//             'system_app': 'Esta es una aplicación de sistema y no se puede desinstalar.',
//             'unsubscribe_prompt': 'Está seguro que desea desuscribirse de',
//             'subscribe_prompt': 'Está seguro que desea suscribirse a',
//             'status_incomplete': 'Incompleto',
//             'status_incomplete_expired': 'Incompleto Vencido',
//             'status_trialing': 'Período de Prueba',
//             'status_active': 'Activo',
//             'status_past_due': 'Atrasado',
//             'status_canceled': 'Cancelado',
//             'status_unpaid': 'Sin Pago',
//             'add_payment_method': 'Añadir método de pago',
//             'add_new_card': 'Agregar nueva tarjeta',
//             'no_payment_methods': 'Aún no se han agregado métodos de pago a esta cuenta.',
//             'not_subscribed': 'No suscrito',
//             'trialing_alert': 'Actualmente está suscrito al período de prueba gratuito de Iconico Sentinel. Esta prueba vence pronto.',
//             'past_due_alert': 'El pago de su suscripción está vencido, pague ahora para continuar usando las herramientas o envíenos un correo electrónico a support@iconico.us',
//             'unpaid_alert': 'Acción requerida: Hubo un problema al procesar su último pago, ingrese nueva información de pago o envíenos un correo electrónico a support@iconico.us',
//             'card_text': 'Se requiere una tarjeta de crédito para instalar ',  
//             'card_text30d': 'No te preocupes, los primeros 30 días son gratis y puedes cancelar tu suscripción en cualquier momento, sin cargos adicionales.', 
//             'card_textp': 'Iconico cumple los estándares de Payment Card Industry y no vamos a manipular ni tener acceso a la información de tu tarjeta, simplemente interactuar con sistemas que se encargan de recibir y procesar esta información.'
//         },
//         scripts: {
//             'add_script': 'Agregar Script',
//             'confirm_delete': 'Confirmar Borrado de Script',
//             'delete_body': '¿Está seguro que desea borrar el script?',
//             'org_scripts': 'Scripts de la Organización',
//             'app_scripts': 'Scripts del Sistema',
//             'job': 'Trabajo',
//             'trigger': 'Evento',
//             'route': 'Ruta',
//             'library':  'Librería'
//         },
//         activity:{
//             'writes': 'Escrituras',
//             'query': 'Consulta',
//             'query_duration': 'Duración de la consulta',
//             'usage': 'Uso'
//         },
//         modules: {
//             'iconico_apps': 'Aplicaciones de Iconico',
//             'install_app': 'Instalar Aplicación',
//             'uninstall_app': 'Desinstalar',
//             'open_app': 'Abrir'
//         },
//         apps: {
//             'sentinel': 'Sentinel',
//             'clerk': 'Clerk',
//             'scribe': 'Scribe',
//             'admin': 'Administración'
//         },
//         console: {
//             'feature_restricted': 'Funcionalidad Restringida',
//             'scripts_restricted': 'La creación y ejecución de scripts está restringida en su organización. Por favor mande un correo electrónico a la siguiente dirección si desea habilitarlo:',
//             'sample_code':`console.log('Su código aquí.');

// //Use la siguiente línea para obtener un modelo Mongoose del objeto que necesita

// let Device = await model('device');

// //Use expresiones de Mongoose para obtener información de estos modelos

// let firstDevice = await Device.find().lean(true).limit(1).exec();

// //Recuerda siempre devolver un valor

// return firstDevice;
//                             `,
//             'console': 'Consola',
//             'script_runner': 'Ejecutar Script',
//             'history': 'Historial',
//             'request': 'Petición',
//             'error_parsing': 'Error en el JSON del cuerpo',
//             'response': 'Respuesta',
//             'response_code': 'Código de Respuesta',
//             'response_time': 'Tiempo de Respuesta',
//             'body': 'Cuerpo',
//             'connect': 'Conectar',
//             'disconnect': 'Desconectar',
//             'send': 'Enviar',
//             'message': 'Mensaje',
//             'connecting': 'Conectando',
//             'received': 'Recibido',
//             'sent': 'Enviado',
//             'connected': 'Conectado',
//             'disconnected': 'Desconectado',
//             'initialized': 'Inicializado',
//             'select_server': 'Seleccionar Red',
//             'authorization': 'Autorización'
//         }

//     }
}

