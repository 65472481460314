<template>
    <div>
        <PrismEditor
            class="fontbase"
            :class="dark ? 'js-editor-dark' : 'js-editor'"
            v-model="code"
            :highlight="highlighter"
            :line-numbers="lineNumbers"
            :readonly="readonly"
        >
        </PrismEditor>
    </div>
</template>

<script>
    import { PrismEditor } from "vue-prism-editor";
    import "vue-prism-editor/dist/prismeditor.min.css";

    import { highlight, languages } from "prismjs/components/prism-core";
    import "prismjs/components/prism-clike";
    import "prismjs/components/prism-javascript";
    // import 'prismjs/themes/prism-tomorrow.css';
    import "prismjs/themes/prism-coy.css";

    export default {
        name: "EditorJs",
        props: {
            script: {
                type: String,
                default: "",
            },
            dark: {
                type: Boolean,
                default: false,
            },
            lineNumbers: {
                type: Boolean,
                default: true,
            },
            readonly: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                code: "",
            };
        },
        mounted() {
            this.code = this.script;
            this.$watch(
                () => this.code,
                () => this.$emit("update:script", this.code)
            );
        },
        methods: {
            highlighter(code) {
                return highlight(code, languages.js); // languages.<insert language> to return html with markup
            },
        },
        components: {
            PrismEditor,
        },
    };
</script>

<style lang="scss">
    /* required class */

    .js-editor-dark {
        background: rgb(57, 54, 54);

        font-weight: 100;
        font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
        line-height: 1.5;
        padding-left: 5px;
    }

    .js-editor {
        // background: rgb(57, 54, 54);
        background: rgb(243, 243, 243);
        font-weight: 100;
        font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
        line-height: 1.5;
        padding-left: 5px;
    }

    /* optional class for removing the outline */

    .prism-editor__editor {
        // color: rgb(199, 225, 237);
        color: rgb(16, 47, 84);
    }

    .prism-editor__textarea:focus {
        color: rgb(16, 47, 84);
        outline: none;
    }
</style>
