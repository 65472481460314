<template>
    <div>
        <Crumbs :rootComponent="rootComponent()" />
    </div>
</template>

<script>
import Vue from "vue";

export default {
    name: "EquipmentType",
    data() {
        return {
            navigationInfo: {
                object: "equipment_type",
                icon: "server",
                extraColumns: [
                    { 
                        title: "core.common.name",
                        function: row => row.name ?? "-" 
                    }
                ],
            },
        };
    },
    methods: {
        rootComponent() {
            let ContentPage = Vue.component("ContentPage");
            return {
                title: this.$t("web.navigation.equipment_types"),
                component: ContentPage,
                properties: this.propertiesFunction(),
            };
        },
        propertiesFunction() {
            return {
                navigationInfo: this.navigationInfo,
                representedObject: "equipment_types",
                showPages: true,
                showAdd: true,
                showSearch: true,
            };
        }
    }
};
</script>

<style>
</style>