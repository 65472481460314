<template>
    <h1>DefaultComponent</h1>
</template>

<script>
    export default {
        name: "DefaultComponent",
    };
</script>

<style></style>
