<template>
    <div class="backdrop" scroll="no">
        <div class="gray-background"></div>

        <img
            src="../../assets/auth-background.svg"
            class="auth-background fixed-top"
            alt="Authentication Background"
        />

        <img
            src="../../assets/iconico-logo.svg"
            class="iconico-logo iconico-logo-mobile mt-4 mx-auto"
            alt="Iconico Logo"
        />

        <div class="h-100 d-flex container-fluid main-content">
            <div class="row w-100 mx-auto justify-content-center">
                <div class="col-xl-4 company-info">
                    <div class="d-flex flex-column container-fluid h-100">
                        <div class="row justify-content-center">
                            <div class="col">
                                <img
                                    src="../../assets/iconico-logo.svg"
                                    class="
                                        iconico-logo
                                        mt-4
                                        center-block
                                        d-block
                                        mx-auto
                                    "
                                    alt="Iconico Logo"
                                />
                            </div>
                        </div>

                        <div class="row justify-content-center">
                            <div class="col">
                                <p
                                    class="
                                        text-white
                                        mx-4
                                        text-center
                                        subtext
                                        top-text
                                    "
                                >
                                    {{ $t("core.startup.line1") }}
                                </p>
                            </div>
                        </div>

                        <div
                            class="
                                row
                                justify-content-center
                                align-items-end
                                flex-grow-1
                            "
                        >
                            <div class="col">
                                <div
                                    class="text-center mx-4 subtext bottom-text"
                                >
                                    {{ $t("core.startup.line2") }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    class="col-xl-4 slot-container"
                    :class="this.$vssWidth < 900 ? 'custom-position' : ''"
                >
                    <div class="container-fluid flags my-4">
                        <div class="row justify-content-end">
                            <div class="col-1">
                                <button
                                    class="
                                        btn btn-link-lg
                                        float-right
                                        mr-2
                                        px-1
                                        py-0
                                        flag-button
                                    "
                                    :class="classForLang('es')"
                                    @click="selectLang('es')"
                                >
                                    <span
                                        class="
                                            flag-icon
                                            flag-icon-es
                                            flag-icon-image
                                        "
                                    ></span>
                                </button>
                            </div>
                            <div class="col-1">
                                <button
                                    class="
                                        btn btn-link-lg
                                        float-right
                                        ml-2
                                        px-1
                                        py-0
                                        flag-button
                                    "
                                    :class="classForLang('en')"
                                    @click="selectLang('en')"
                                >
                                    <span
                                        class="
                                            flag-icon
                                            flag-icon-us
                                            flag-icon-image
                                        "
                                    ></span>
                                </button>
                            </div>
                        </div>
                    </div>

                    <slot />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import VueScreenSize from "vue-screen-size";
    export default {
        name: "AuthFrame",
        mixins: [VueScreenSize.VueScreenSizeMixin],
        mounted() {
            //document.documentElement.style.overflow = 'hidden';
        },
        data() {
            return {};
        },
        props: [],
        methods: {
            classForLang(lang) {
                if (this.$i18n.locale == lang) {
                    return " active-language ";
                }
                return "";
            },
            selectLang(lang) {
                this.$i18n.locale = lang;
                if (localStorage.getItem("lang")) {
                    localStorage.removeItem("lang");
                }
                localStorage.setItem("lang", lang);
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import "node_modules/bootstrap/scss/functions";
    @import "node_modules/bootstrap/scss/variables";
    @import "node_modules/bootstrap/scss/mixins/_breakpoints";

    $light-gray-background: rgba(242, 243, 251, 0.95);
    $shadow-color: rgba(127, 127, 129, 0.44);

    .custom-position {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .backdrop {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background: white;
    }

    .gray-background {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 10%;
        box-shadow: -10px 0 10px $shadow-color;
        background: $light-gray-background;
        z-index: 10;

        display: none;

        @include media-breakpoint-up(xl) {
            display: block;
        }
    }

    .auth-background {
        -webkit-filter: drop-shadow(4px 4px 2px $shadow-color);
        filter: drop-shadow(4px 4px 2px $shadow-color);
        height: 820px;
        z-index: 20;
    }

    .slot-container {
        background: white;
        box-shadow: -5px 0px 10px $shadow-color, 5px 0px 10px $shadow-color;
        padding-top: 80px;
        z-index: 100;
    }

    .main-content {
        width: 100%;
        padding-top: 10rem;
    }

    .company-info {
        z-index: 100;

        display: none;

        @include media-breakpoint-up(xl) {
            display: block;
        }

        //Debug
        // border: 2px solid;
        // border-color: red;
    }

    .iconico-logo {
        max-width: 230px;
        max-height: 95px;
        width: auto;
        height: auto;
    }

    .iconico-logo-mobile {
        position: absolute;
        left: 0;
        right: 0;
        display: block;
        z-index: 100;

        @include media-breakpoint-up(xl) {
            display: none !important;
        }
    }

    .subtext {
        font-family: HelveticaNeue;
        font-size: 1.3rem;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: 2.33px;
    }

    .top-text {
        margin-top: 4rem;
    }

    .bottom-text {
        color: var(--primary);
        margin-bottom: 5rem;
    }

    .flags {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 120px;
    }

    .flag-button {
        font-size: 1.5rem;
    }

    .flag-icon-image {
    }

    .active-language {
        box-shadow: rgba(0, 0, 0, 0.42) 0px 3px 8px;
    }
</style>
