const objects = [
    {
        path: 'device',
        display: 'core.common.device',
    },
    {
        path: 'server',
        display: 'core.common.server',
    },
    {
        path: 'account',
        display: 'core.common.users',
    },
    {
        path: 'notification',
        display: 'core.common.notification',
    },
];

module.exports = objects;
