<template>
    <div>
        <basic-select
            v-if="idData"
            :options="options"
            :selected-option="item"
            :placeholder="placeholderName"
            @select="onSelect"
            :isDisabled="disabled"
        >
        </basic-select>
    </div>
</template>
<script>
    import { BasicSelect } from "vue-search-select";
    export default {
        name: "SelectSearch",
        components: {
            BasicSelect,
        },
        mounted() {
            this.loadData();
        },
        props: [
            "optionsObject",
            "object",
            "find",
            "placeholderName",
            "selectionChanged",
            "itemSelected",
            "path",
            "disabled",
        ],
        data() {
            return {
                idData: false,
                options: [],
                item: {
                    value: "",
                    text: "",
                    path: "",
                },
            };
        },
        methods: {
            async loadData() {
                try {
                    this.options = [];
                    if (this.object && !this.optionsObject) {
                        let objectResult = await this.downloadObjects(
                            this.object,
                            this.find
                        );
                        let objects = objectResult.data.data;
                        this.options = objects.docs.map((element) => {
                            return {
                                value: element._id,
                                path: this.path,
                                text:
                                    element.name ||
                                    `${element.first_name} ${element.last_name}`,
                            };
                        });
                    } else {
                        this.options = await this.optionsObject;
                    }
                    if (this.itemSelected) {
                        this.options.forEach((item) => {
                            if (item.value == this.itemSelected) {
                                this.item = item;
                            }
                        });
                    }
                    if (this.options.length == 1) {
                        this.onSelect(this.options[0]);
                    }
                    this.idData = true;
                } catch (error) {
                    this.controlError(error);
                }

                this.idData = true;
            },
            downloadObjects(object, find) {
                let config = {
                    org: this.$store.state.org,
                    authToken: this.$store.state.account.token,
                    object,
                    parameters: {
                        params: {
                            find,
                            pagination: false,
                            select: "_id name first_name last_name",
                        },
                    },
                };
                return this.$iconico.get_objects(config);
            },
            onSelect(item) {
                this.selectionChanged(item);
                this.item = item;
            },
            controlError(error) {
                this.$store.state.status = error.response.status;
                this.$store.state.message = error.message;
            },
        },
    };
</script>

<style scoped>
    @import "../../assets/styleSearchSelect.css";
</style>
