import Users from '../components/screens/management/Users.vue'
import Dashboard from '../components/screens/Dashboard.vue'
import Equipment from '../components/screens/Equipment.vue'
import EquipmentType from '../components/screens/EquipmentType.vue'
import Variable from '../components/screens/Variable.vue'


function navigationGroups() {

    return [        
        {  
            title: "web.navigation.monitoring",
            items: [   
                {
                    title: 'core.common.dashboard',
                    component: 'dashboard',
                    object: 'dashboard',
                    id: 'dashboard',
                    image:'columns'
                },    
                {
                    title: 'web.navigation.equipment',
                    component: 'equipment',
                    object: 'equipment',
                    id: 'equipment',
                    image:'columns'
                }           
            ]
        },
        {
            title: "core.navigation.settings",
            items: [
                {
                    title:'web.navigation.variable',
                    component:'variable',
                    object:'variable',
                    id: 'variable',
                    image:'chart-bar',
                    show: store => !store.account?.client
                },
                {
                    title:'web.navigation.equipment_types',
                    component:'equipmentType',
                    object:'equipment_type',
                    id: 'equipmentType',
                    image:'cog',
                    show: store => !store.account?.client
                },
                {
                    title:'core.common.users',
                    component:'users',
                    object:'account',
                    id: 'navigationUsers',
                    image:'users'
                }
            ]
        },
    ]
}

const tabMapping = {
    dashboard: Dashboard,
    equipment: Equipment,
    equipmentType: EquipmentType,
    users: Users,
    variable: Variable
   
}
export { navigationGroups, tabMapping };