<template>
        <div>            
            <Tabs  :tabs="tabs" />
        </div>
</template>

<script>
import EquipmentDetails from "./EquipmentDetails.vue";
import EquipmentGraphs from "./EquipmentGraphs.vue";
import EquipmentInput from "./EquipmentInput.vue"

export default {
    name: "EquipmentDisplay",
    mounted() {
   
    },

    props: ["confirmObjectChanges", "cancelObjectChanges", "actionDelete","object"],
    data() {
        return {
            isData: false,
            ppp: {},
            pppList: [],
            deviceMapping: [],
            tabs: [
                {
                    id: "EquipmentDetails",
                    display: "web.navigation.equipment_details",
                    component: EquipmentDetails,
                    properties: this.propertiesForTab,
                },
                {
                    id: "EquipmentGraphs",
                    display: "web.navigation.equipment_graphs",
                    component: EquipmentGraphs,
                    properties: this.propertiesForTab,

                },
            ],
        };
    },
    components: {
        EquipmentDetails,
        EquipmentGraphs,
    },

    methods: {
        propertiesForTab() {
            return {
                equipment: this.object,
                object:this.object,
                confirmObjectChanges:this.confirmObjectChanges,
                cancelObjectChanges:this.cancelObjectChanges,
                actionDelete:this.actionDelete
            };
        },
    },
};
</script>

<style>
</style>

