<template>
    <div
        class="col-12 col-xl-2 m-3 p-2 rounded-lg text-light"
        style="background-color: #4054c4"
    >
        <span class="float-left h4 font-weight-bold">{{ $t(title) }}</span
        ><br />
        <span class="float-right h4 font-weight-bold">
            {{ value }}
            <span class="small font-weight-bold"> {{ details }}</span>
        </span>
    </div>
</template>

<script>
    export default {
        name: "DataCard",
        props: ["title", "value", "details"],
        data() {
            return {};
        },
    };
</script>

<style></style>
