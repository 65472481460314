<template>
    <div id="ChartSparkline">
        <div :id="idElement">
            <apexchart
                :type="this.typeChart"
                :height="this.height"
                ref="chart"
                :options="chartOptions"
                :series="series"
            ></apexchart>
        </div>
    </div>
</template>
<script>
    import VueApexCharts from "vue-apexcharts";
    export default {
        name: "ChartSparkline",
        components: {
            apexchart: VueApexCharts,
        },
        props: ["dataModel", "idElement", "height", "typeChart", "colors"],
        data() {
            return {
                series: [this.dataModel],
                chartOptions: {
                    chart: {
                        id: this.idElement,
                        height: this.height,
                        sparkline: {
                            enabled: true,
                        },
                    },
                    colors: this.colors,
                    legend: {
                        show: false,
                    },
                    stroke: {
                        width: 3,
                    },
                    tooltip: {
                        fixed: {
                            enabled: false,
                        },
                        x: {
                            show: false,
                        },
                        y: {
                            title: {
                                formatter: function(seriesName) {
                                    return "";
                                },
                            },
                        },
                        marker: {
                            show: false,
                        },
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    plotOptions: {
                        radialBar: {
                            hollow: {
                                margin: 0,
                                size: "50%",
                            },
                            track: {
                                margin: 0,
                            },
                            dataLabels: {
                                show: false,
                            },
                        },
                    },
                },
            };
        },
    };
</script>

<style scoped></style>
