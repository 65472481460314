<template>
    <div id="NavigationBar">
        <nav
            class="
                navbar navbar-expand-lg
                fixed-top
                p-0
                m-0
                fontbase
                background-color background-image
            "
        >
            <!-- mobile -->
            <div class="d-sm-block d-lg-none">
                <button
                    v-show="true"
                    type="button"
                    id="sidebarCollapse"
                    @click="changeToggle"
                    class="btn p-1"
                >
                    <i class="fas fa-bars fa-lg"></i>
                </button>
            </div>
            <!-- mobile -->
            <div class="d-sm-block d-lg-none">
                <div v-show="true">
                    <img
                        v-bind:src="get_static_image_url(get_logo_app())"
                        alt=""
                        width="130"
                        height="50"
                    />
                </div>
            </div>
            <span
                class="navbar-brand navbarIcon d-none d-lg-inline"
                style="position: relative"
            >
                <div class="row">
                    <div class="col-9 col-lg-9 pl-4 d-none d-lg-inline">
                        <!-- web -->
                        <img
                            class="logo p-1"
                            v-bind:src="get_static_image_url(get_logo_app())"
                            alt=""
                            width="130"
                            height="50"
                        />
                    </div>
                    <div class="pl-2 col-2 col-lg-2 pt-2 d-none d-lg-inline">
                        <button
                            type="button"
                            id="sidebarCollapse"
                            @click="changeToggle"
                            class="btn p-1"
                        >
                            <i class="fas fa-bars fa-lg"></i>
                        </button>
                    </div>
                </div>
            </span>

            <button
                class="navbar-toggler icon-color"
                type="button"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
            >
                <span
                    @click="changeIcon"
                    class="navbar-toggler-icon position-icon"
                    v-show="collapsed"
                >
                    <i class="fas fa-chevron-up icon-color"> </i>
                </span>
                <span
                    @click="changeIcon"
                    class="navbar-toggler-icon position-icon"
                    v-show="!collapsed"
                >
                    <i class="fas fa-chevron-down icon-color"> </i>
                </span>
            </button>

            <!-- /---------- -->
            <span class="text-white" style="position: absolute; left: 250px">
                <div
                    v-if="
                        this.$store.state.app == 'admin' &&
                        subscriptionsLoaded()
                    "
                    class="dropdown bg-transparent d-none d-lg-inline"
                >
                    <button
                        class="
                            btn btn-transparent
                            font-bold
                            text-primary
                            dropdown-toggle
                        "
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                        <span
                            class="bg-primary p-2 ml-1 mr-2"
                            style="
                                border-radius: 50%;
                                max-height: 36px;
                                max-width: 36px;
                            "
                        >
                            <i class="fas fa-th-list text-white" />
                        </span>
                        <span
                            id="appLauncher"
                            class="mr-1 ml-1 align-middle font-bold"
                        >
                            Apps
                            <i class="ml-2 fas fa-angle-down"></i>
                        </span>
                    </button>
                    <div
                        class="dropdown-menu bg-white"
                        aria-labelledby="dropdownMenuButton"
                    >
                        <a
                            class="dropdown-item p-2 text-primary"
                            v-for="(subscription, index) in subscriptions"
                            :key="index"
                            target="_blank"
                            :href="subscriptionURL(subscription)"
                        >
                            <img
                                v-if="subscriptionImageURL(subscription)"
                                class="product-logo p-1"
                                :src="subscriptionImageURL(subscription)"
                            />
                            <p
                                v-if="!subscriptionImageURL(subscription)"
                                class=""
                            >
                                {{ subscriptionName(subscription) }}
                            </p>
                        </a>
                    </div>
                </div>
                <div
                    class="nav-item dropdown bg-transparent d-none d-lg-block"
                    v-if="showProbeSelector && isListServers"
                    :key="this.$store.state.refresh"
                >
                    <a
                        class="nav-link dropdown-toggle font-bold text-white"
                        data-toggle="dropdown"
                        href="#"
                        role="button"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                        <span
                            class="bg-white p-1 ml-1 mr-1"
                            style="
                                border-radius: 50%;
                                max-height: 30px;
                                max-width: 30px;
                            "
                        >
                            <i class="fas fa-wifi text-primary" />
                        </span>
                        {{
                            this.$store.state.serverActive
                                ? this.$store.state.serverActive.name
                                : $t('core.common.servers')
                        }}
                    </a>
                    <div class="dropdown-menu">
                        <span
                            v-for="(server, index) in $system.objects.server"
                            :key="index"
                        >
                            <a
                                class="dropdown-item p-2 text-primary"
                                v-if="
                                    server._id !== $store.state.serverActive._id
                                "
                                href="#"
                                @click="changeServer(server)"
                            >
                                {{ server.name }}
                            </a>
                        </span>
                    </div>
                </div>
            </span>
            <!-- ------------------- -->
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <span class="navbar-text navbarNotificaction pt-2 pb-2 pr-3">
                    <!-- <button class="btn mr-3 navbarBtn navbarBtnShop mt-1 mb-1"  @click="billingEdit()" > 
            <i class="fas fa-shopping-cart"/>
            <span v-if="this.$store.state.countOrder != 0" class="badge badge-success ml-1">{{ this.$store.state.countOrder }}</span>           
            </button> -->
                    <label class="info-sub" v-if="showTimeLeft">
                        {{ $t('core.common.info_nav') }}
                        {{ trialTimeLeft }}
                        {{ $t('core.common.info_trialdays') }}
                    </label>

                    <button
                        data-toggle="collapse"
                        data-target="#navbarSupportedContent"
                        v-if="showSearchButton"
                        class="btn mr-3 navbarBtn navbarBtnConfig mt-1 mb-1"
                        v-on:click="isSearchText()"
                    >
                        <i class="fas fa-search" />
                    </button>

                    <br class="d-lg-none" />
                    <br class="d-lg-none" />
                    <span
                        class="p-0 m-0 navbarSeparator d-none d-lg-inline"
                        v-if="showSearchButton"
                    ></span>

                    <img
                        class="imf-fluid p-0 ml-3"
                        style="
                            border-radius: 50%;
                            max-height: 40px;
                            max-width: 40px;
                        "
                        v-if="this.account.picture"
                        v-bind:src="
                            get_image_url_from_path(this.account.picture)
                        "
                        alt=""
                        width="140"
                        height="auto"
                    />
                    <span class="custom-style-separator">
                        <span class="custom-style" :class="buttonAccount()">
                            <img
                                class="imf-fluid p-0 ml-3"
                                style="
                                    border-radius: 50%;
                                    max-height: 30px;
                                    max-width: 30px;
                                "
                                v-if="!this.account.picture"
                                v-bind:src="
                                    get_static_image_url('demo-user.jpg')
                                "
                                alt=""
                                width="140"
                                height="auto"
                            />

                            <button
                                data-toggle="collapse"
                                data-target="#navbarSupportedContent"
                                id="accountSettings"
                                class="btn navBarText navbarCompany mr-2"
                                v-on:click="accountSetting()"
                            >
                                <span class="navBarText p-0 m-0">
                                    {{
                                        this.account
                                            ? this.account.first_name +
                                              ' ' +
                                              this.account.last_name
                                            : ''
                                    }}
                                </span>
                            </button>
                        </span>
                    </span>

                    <span
                        class="p-0 m-0 navbarSeparator d-none d-lg-inline"
                    ></span>

                    <br class="d-lg-none" />
                    <br class="d-lg-none" />

                    <span class="custom-style-separator">
                        <span
                            class="custom-style extra-padding"
                            :class="buttonSetting()"
                        >
                            <span class="position-custom custom-icon">
                                <i class="fas fa-wrench size-icon" />
                            </span>

                            <button
                                data-toggle="collapse"
                                data-target="#navbarSupportedContent"
                                class="btn navBarText navbarCompany mr-2"
                                v-on:click="orgSettings()"
                            >
                                <span class="navBarText p-0 m-0">{{
                                    this.$store.state.org
                                        ? this.$store.state.org.name
                                        : ''
                                }}</span>
                            </button>
                        </span>
                    </span>
                    <span
                        class="p-0 m-0 navbarSeparator d-none d-lg-inline"
                    ></span>

                    <br class="d-lg-none" />
                    <br class="d-lg-none" />
                    <button
                        data-toggle="collapse"
                        data-target="#navbarSupportedContent"
                        class="btn mr-3 navbarBtn navbarBtnSignOut d-lg-none"
                        @click="signOut"
                    >
                        <i class="fas fa-power-off" />
                    </button>
                    <button
                        class="
                            btn
                            ml-3
                            navbarBtn
                            navbarBtnSignOut
                            d-none d-lg-inline
                        "
                        @click="signOut"
                    >
                        <i class="fas fa-power-off" />
                    </button>
                </span>
            </div>
        </nav>
    </div>
</template>

<script>
export default {
    name: 'NavigationBar',
    mounted() {
        this.mapProducts();
        this.$watch(() => {
            return this.$store.state && this.$store.state.systemObjectsLoaded;
        }, this.systemObjectsLoaded);
        this.trialTime();
    },
    props: {
        showProbeSelector: {
            type: Boolean,
            default: false,
        },
        showSearchButton: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        account: function () {
            return this.$store.state.account || {};
        },
        role: function () {
            if (this.account && this.account.role) {
                return this.account.role;
            }

            return '';
        },
    },
    data() {
        return {
            showTimeLeft: false,
            trialTimeLeft: 0,
            collapsed: false,
            subscriptions: [],
            productsMap: {},
            get_image_url_from_path: this.$iconico.get_image_url_from_path,
            get_static_image_url: this.$iconico.get_static_image_url,
            isListServers: false,
        };
    },
    methods: {
        trialTime() {
            let currentApp = null;
            let subscriptions = null;
            
            if (this.$store && this.$store.state && this.$store.state.checkSubscription){
                currentApp = this.$store.state.checkSubscription;
            }

            if (this.$store && this.$store.state && this.$store.state.org && this.$store.state.org.subscriptions){
                subscriptions = this.$store.state.org.subscriptions;
            }            

            if (subscriptions) {
                const productSubscription = subscriptions.find(
                    (sub) => sub.code == currentApp
                );

                if (productSubscription && productSubscription.trial_expiration) {
                    const now = new Date();
                    const trialEnd = new Date(productSubscription.trial_expiration);
                    const result =
                        (trialEnd.getTime() - now.getTime()) /
                        (60 * 60 * 1000 * 24);
                    this.trialTimeLeft = Math.round(result);
                    this.showTimeLeft = this.trialTimeLeft > 0;
                }
            }
        },
        buttonAccount() {
            if (this.$store.state.selectedDashboardTab == 'account') {
                return 'selected-button';
            } else return '';
        },
        buttonSetting() {
            if (this.$store.state.selectedDashboardTab == 'setting') {
                return 'selected-button';
            } else return '';
        },

        toggleIconClass() {
            if (!this.collapsed) {
                return 'fas fa-chevron-down';
            } else {
                return 'fas fa-chevron-up';
            }
        },
        changeIcon() {
            this.collapsed = !this.collapsed;
        },
        changeServer(server) {
            this.$store.state.serverActive = server;
        },
        isSearchText() {
            this.$store.state.selectedDashboardTab = 'generalsearch';
            this.$store.state.selectedObject = 'generalsearch';
        },
        get_logo_app() {
            let app = this.$store.state.app;
            return app + '-logo.svg';
        },
        systemObjectsLoaded() {
            this.mapProducts();
            this.checkServers();
        },
        checkServers() {
            if (!this.$system.objects) {
                this.isListServers = false;
            } else {
                if (
                    this.$system.objects.server &&
                    this.$system.objects.server.length > 0
                ) {
                    let defaultServer = this.$system.objects.server.find(
                        (server) => server.default == true
                    );
                    if (defaultServer) {
                        this.$store.state.serverActive = defaultServer;
                    } else {
                        this.$store.state.serverActive =
                            this.$system.objects.server[0];
                    }

                    this.isListServers = this.$system.objects.server.length > 1;
                }
            }
        },
        products() {
            if (!this.$system.objects) {
                return null;
            } else {
                return this.$system.objects.product;
            }
        },
        subscriptionsLoaded() {
            if (this.subscriptions.length == 0) {
                return false;
            }

            const result = this.subscriptions.reduce(
                (allHold, sub) => allHold && this.productsMap[sub.product],
                true
            );

            return result;
        },
        mapProducts() {
            if (this.$store.state.org) {
                this.subscriptions = this.$store.state.org.subscriptions || [];
            }

            const products = this.products();

            this.productsMap = {};

            this.subscriptions.forEach(
                (subscription) =>
                    (this.productsMap[subscription.product] =
                        this.productForSubscription(subscription))
            );
        },
        signOut() {
            let config = {
                org: this.$store.state.org,
                authToken: this.$store.state.account.token,
                parameters: {},
            };

            this.$iconico
                .account_logout(config)
                .then((result) => {
                    this.$utilities.cleanUpAndGoToLogin(this);
                })
                .catch((error) => {
                    this.$utilities.cleanUpAndGoToLogin(this);
                });
            this.$cookie.delete('Account');
            this.$cookie.delete('Org');
        },
        getAdmin() {
            let url = 'https://admin.iconico.us/' + this.$store.state.org.path;
            window.open(url, '_blank');
        },
        orgSettings() {
            let app = this.$store.state.app;
            if (app != 'admin') {
                this.getAdmin();
            }
            this.$store.state.selectedDashboardTab = 'setting';
            this.$store.state.selectedObject = 'setting';
        },
        accountSetting() {
            this.$store.state.selectedDashboardTab = 'account';
            this.$store.state.selectedObject = 'account';
        },
        billingEdit() {
            if (this.$store.state.countOrder != 0) {
                this.$store.state.selectedDashboardTab = 'billing';
                this.$store.state.selectedObject = 'order';
                this.$store.state.selectedOrder = true;
            }
        },
        changeToggle() {
            this.$store.state.isToggle = !this.$store.state.isToggle;
        },
        downloadObjects(object, find) {
            let config = {
                org: this.$store.state.org,
                authToken: this.$store.state.account.token,
                object,
                parameters: {
                    params: {
                        find,
                        pagination: false,
                        select: 'name imageFile',
                    },
                },
            };
            return this.$iconico.get_objects(config);
        },
        orgIsSubscribed(product) {
            const org = this.$store.state.org || {};
            const subscriptions = org.subscriptions || [];

            return subscriptions.find(
                (subscription) => subscription.product == product._id
            );
        },
        productForSubscription(subscription) {
            const systemObjects = this.$system.objects || {};
            const products = systemObjects.product || [];

            const product = products.find(
                (product) => product._id == subscription.product
            );

            return product;
        },
        subscriptionURL(subscription) {
            const product = this.productsMap[subscription.product];

            if (product) {
                return `https://${product.urlPath || 'admin'}.iconico.us/${
                    this.$store.state.org.path
                }`;
            }

            return '';
        },
        subscriptionName(subscription) {
            const product = this.productsMap[subscription.product];

            if (product) {
                return product.name || '';
            }

            return 'Iconico';
        },
        subscriptionImageURL(subscription) {
            const product = this.productsMap[subscription.product];

            if (product && product.imageFile) {
                return product.imageFile.url || '';
            }

            return '';
        },
    },
};
</script>

<style scoped lang="scss">
@import '../../../../sass/appVariables.scss';
.icon-color {
    color: $navbarIconColor;
}
.product-logo {
    object-fit: contain;
    align-content: left;
    height: 30px;
    width: auto;
    margin: 0rem;
}

.navbarBtnSignOut {
    &:hover {
        background: $navBarExitButton;
    }
}

.position-icon {
    display: inline-block;
    width: 100%;
    height: 100%;
}
.background-image {
    background-image: $navBarImage;
}
.info-sub {
    color: #cdcfdc;
    font: Helvetica;
    size: 14px;
    font-weight: 700;
    margin: 0px 10px 0px 0px;
}

.background-color {
    background-color: $navBarBackground;
}
.custom-style {
    padding-top: 0.6rem;
    padding-bottom: 0.75rem;
    border-radius: 4px;
    background-color: $navBarButtonBackgroundColor;
}
.custom-style:hover {
    background-color: $navBarButtonBackgroundColorHover;
}
.selected-button {
    background: $navBarButtonBackgroundColorHover;
}

.custom-style-separator {
    margin: 10px;
    padding: 10px;
}

.btn:focus {
    outline: none;
    box-shadow: none;
}
.custom-icon {
    color: $navbarIconColor;
}
.extra-padding {
    padding-left: 1rem;
}
.position-custom {
    position: relative;
    right: 0px;
    top: 2px;
}
.size-icon {
    transform: scale(1.5);
}
</style>
