<template>
    <div>
        <Crumbs :rootComponent="rootComponent()" />
    </div>
</template>

<script>
import Vue from "vue";
import EquipmentInput from "./EquipmentInput.vue"
import EquipmentDisplay from "./EquipmentDisplay.vue"

export default {
    name: "Equipment",
      components: {
        EquipmentInput,
        EquipmentDisplay
    },
    data() {
        return {
            navigationInfo: {
                object: "equipment",
                icon: "server",
                extraColumns: [
                    { 
                        title: "core.common.name",
                        function: row => row.display_name ?? "-",
                        populate: "server",
                    },
                    {
                        title: "web.common.client",
                        function: row => row.client?.display_name ?? "-",
                        populate: "client",
                    },
                    {
                        title: "web.common.type",
                        function: row => row.type?.display_name ?? "-",
                        populate: "type",
                    },
                    {
                        title: "web.common.location",
                        function: row => row.location?.display_name ?? "-",
                        populate: "location",
                    },
                ],
            },
        };
    },
    methods: {
        rootComponent() {
            let ContentPage = Vue.component("ContentPage");
            return {
                title: this.$t("web.navigation.equipment"),
                component: ContentPage,
                properties: this.propertiesFunction(),
            };
        },
        propertiesFunction() {
            return {
                navigationInfo: this.navigationInfo,
                inputComponent: EquipmentInput,
                displayComponent:EquipmentDisplay,
                representedObject: "equipment",
                showPages: true,
                showAdd: true,
                showSearch: true,
            };
        },
    },
};
</script>

<style>
</style>