<template>
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-dialog" role="document">
                    <div class="modal-content shadow">
                        <div
                            class="modal-header text-white p-3"
                            :class="'bg-' + modal.bg"
                        >
                            <h5 class="modal-title font-weight-bolder">
                                <i class="fas fa-exclamation-circle" />
                                {{ modal.title }}
                            </h5>
                            <button
                                type="button"
                                @click="modal.close"
                                class="close"
                                aria-label="Close"
                            >
                                <span class="text-white" aria-hidden="true"
                                    >&times;</span
                                >
                            </button>
                        </div>
                        <div
                            class="modal-body"
                            style="background-color: #f5f5fc"
                        >
                            <p
                                class="text-dark font-weight-bolder"
                                style="font-size: 16px"
                            >
                                {{ modal.body }}
                            </p>
                            <div class="text-center pt-2 col-12">
                                <button
                                    type="button"
                                    class="
                                        btn
                                        pl-4
                                        pr-4
                                        mr-2
                                        btn-outline-dark
                                        font-weight-bolder
                                    "
                                    @click="modal.close"
                                >
                                    {{ modal.closeText }}
                                </button>
                                <button
                                    type="button"
                                    class="btn pl-4 pr-4 font-weight-bolder"
                                    :class="'btn-' + modal.bg"
                                    @click="modal.confirm"
                                >
                                    {{ modal.confirmText }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
    export default {
        name: "ModalAlert",
        props: ["modal"],
        data() {
            return {};
        },
        methods: {},
    };
</script>

<style>
    .modal-mask {
        position: fixed;
        z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: table;
        transition: opacity 0.1s ease;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
        border-radius: 20px;
    }
    .modal-content {
        border-radius: 12px;
        padding: 0;
    }
    .modal-header {
        border-radius: 10px 10px 0 0;
    }
    .modal-body {
        border-radius: 0 0 10px 10px;
    }
</style>
