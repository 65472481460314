<template>
    <div>
        <Crumbs :rootComponent="rootComponent()" />
    </div>
</template>

<script>
import TabsHome from "../TabsHome.vue";

export default {
    name: "UsersHome",
    components: {
        TabsHome,
    },
    data() {
        return {
            TabsHome,
        };
    },
    methods: {
        propertiesFunction() {
            return {};
        },
        rootComponent() {
            return {
                title: this.$t("core.common.users"),
                component: this.TabsHome,
                properties: this.propertiesFunction(),
            };
        },
    },
};
</script>

<style>
</style>