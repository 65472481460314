<template>
    <div class="pt-1">
        <ul class="nav nav-tabs nav-fill" id="myTab" role="tablist">
            <li
                v-for="(tab, index) in tabs"
                :key="index"
                class="nav-item"
                role="presentation"
            >
                <span
                    class="
                        nav-item nav-link
                        text-uppercase
                        pointer
                        fontbase
                        font-lt
                    "
                    :class="selectedTab === tab.id ? classActive() : ''"
                    :id="tabId(tab)"
                    data-toggle="tab"
                    :href="`#${tabId(tab)}`"
                    role="tab"
                    @click="selectTab(tab)"
                    :aria-selected="selectedTab === tab.id"
                >
                    {{ $t(tab.display) }}
                </span>
            </li>
        </ul>
        <div class="tab-content" id="myTabContent">
            <div
                v-for="(tab, index) in tabs"
                :key="index"
                class="tab-pane fade pt-4"
                :class="{
                    show: selectedTab === tab.id,
                    active: selectedTab === tab.id,
                }"
                :id="tabId(tab)"
                role="tabpanel"
                :aria-labelledby="tabId(tab)"
            >
                <Component :is="tab.component" v-bind="propertiesForTab(tab)" />
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Tabs",
        mounted() {
            if (this.tabs) {
                this.selectedTab = this.tabs[0].id;
            }
        },
        props: {
            tabs: Array,
            classSecondary: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                selectedTab: "",
            };
        },
        methods: {
            selectTab(tab) {
                this.selectedTab = tab.id;
            },
            tabId(tab) {
                return `tab-${tab.id}`;
            },
            classActive() {
                return this.classSecondary ? "activeSecondary" : "active";
            },
            propertiesForTab(tab) {
                if (tab.properties) {
                    return tab.properties();
                }
                return {};
            },
        },
    };
</script>

<style scoped>
    .nav-link:hover {
        border: none;
    }
    .nav-tabs .nav-item.show .nav-link,
    .nav-tabs .nav-link.active {
        color: #3844b4;
        background-color: transparent;
        border-color: transparent transparent #6b7285;
        border-bottom: 5px solid #3844b4;
        width: 80%;
        margin-left: 10%;
        margin-right: 10%;
    }
    .nav-tabs .nav-item.show .nav-link,
    .nav-tabs .nav-link.activeSecondary {
        color: #0198db;
        background-color: transparent;
        border-color: transparent transparent #6b7285;
        border-bottom: 5px solid #0198db;
        width: 80%;
        margin-left: 10%;
        margin-right: 10%;
    }
    .nav-link {
        border-bottom: 2px solid transparent;
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
        color: #6b7285;
        font-weight: 600;
        letter-spacing: 2px;
    }
    .nav-link:hover {
        color: #3844b4;
        border-bottom: 5px solid #c2c9fe;
    }
</style>
