<template>
    <div class="p-4 table-responsive">
        <table
            v-if="!emptyList()"
            class="table table-sm text-center table-hover"
        >
            <thead class="header">
                <tr>
                    <th
                        v-for="(column, index) in columns"
                        :key="index"
                        @click="getOnOrder(column)"
                        :class="cursorStyle(column)"
                    >
                        <div class="nowrap">
                            <span
                                v-if="column.title"
                                v-bind:class="[
                                    sortTitle ==
                                    (column.property ||
                                        clearTitle(column.title))
                                        ? 'text-success'
                                        : 'text-secondary',
                                ]"
                            >
                                <span>
                                    {{ $t(column.title) }}
                                    <span v-if="column.sort">
                                        <i
                                            :class="sortVerification(column)"
                                            aria-hidden="true"
                                        ></i>
                                    </span>
                                </span>
                            </span>
                        </div>
                    </th>
                </tr>
            </thead>

            <tbody>
                <tr
                    v-for="(row, index) in rows.docs"
                    :key="index"
                    class="clickable-row"
                    @click="rowDetails(row)"
                >
                    <td
                        v-for="(column, index) in columns"
                        :key="index"
                        :style="column.style"
                        class="center"
                        height="50"
                    >
                        <div class="cell-td font-lt center">
                            <span
                                class="
                                    badge badge-pill badge-dark
                                    p-2
                                    align-middle
                                "
                                style="margin-top: -4px"
                            >
                                <i
                                    v-if="column.icon"
                                    class="fas"
                                    :class="column.icon"
                                />
                            </span>
                            <div v-if="column.downloadUrl" class="zoom">
                                <a
                                    :href="downloadUrl(column, row)"
                                    target="_blank"
                                >
                                    <i
                                        :class="
                                            column.downloadIcon
                                                ? column.downloadIcon
                                                : 'fas fa-file'
                                        "
                                    ></i>
                                </a>
                            </div>
                            <div
                                v-if="
                                    column.progress && column.function(row) > 0
                                "
                                class="cellProgressBar"
                                style="margin-top: -4px"
                            >
                                <ProgressBar :value="column.function(row)" />
                            </div>
                            <div
                                class="text-center h5"
                                v-if="column.isCheckbox"
                            >
                                <i :class="classForBooleanIcon(column, row)" />
                            </div>
                            <div v-if="column.tags && !isNotColumn(column)">
                                <span
                                    v-for="(tag, index) in row.tags"
                                    :key="index"
                                >
                                    <Tag :tag="mappedTags[tag]" />
                                </span>
                            </div>
                            <div v-if="column.severity">
                                <span
                                    class="py-1 cell px-2 mx-1 rounded"
                                    :style="tagStyle(row.severity)"
                                >
                                    {{ tagName(row.severity) }}
                                </span>
                            </div>
                            <div v-if="column.status">
                                <span
                                    class="ptb1"
                                    :class="
                                        objectStatus(row[column.status])
                                            ? 'cell cell-success'
                                            : 'cell cell-danger'
                                    "
                                    >{{
                                        $t(
                                            objectStatus(row[column.status])
                                                ? 'core.common.active'
                                                : 'core.common.inactive'
                                        )
                                    }}
                                </span>
                            </div>
                            <div v-if="column.cell">
                                <span
                                    class="ptb1"
                                    :class="
                                        row[column.cell] == 'DOWN'
                                            ? 'cell cell-danger'
                                            : 'cell cell-success'
                                    "
                                    >{{ row[column.cell] }}
                                </span>
                            </div>
                            <span
                                class="badge p-1 text-lowercase"
                                v-if="column.color"
                                style="width: 90px"
                                :style="styleForColorCell(row, column)"
                            >
                                {{ row[column.property] }}
                            </span>

                            <div
                                :class="classForCell(column, row)"
                                v-if="isNotColumn(column) && column.tags"
                                class="text-align-left text-sm-left"
                            >
                                <span>
                                    {{ valueForCell(column, row) }}
                                </span>
                                <span
                                    v-for="(tag, index) in row.tags"
                                    :key="index"
                                >
                                    <Tag
                                        :tag="mappedTags[tag]"
                                        :size="0.7"
                                        :hideIcon="true"
                                    />
                                </span>
                            </div>
                            <span
                                :class="classForCell(column, row)"
                                v-if="isNotColumn(column) && !column.tags"
                            >
                                {{ valueForCell(column, row) }}
                            </span>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>

        <EmptyState
            v-if="emptyList()"
            :message="emptyStateMessage()"
            :title="emptyStateTitle()"
        />

        <nav v-if="showPagination" aria-label="Page navigation example">
            <ul
                class="
                    pagination pagination-vue
                    justify-content-center
                    font-HelveticaNeue
                    mx-auto
                    col-xl-3 col-12
                "
            >
                <li
                    class="page-item-vue p-1 align-middle"
                    @click="goToFirstPage()"
                    :class="{ disabled: !rows.hasPrevPage }"
                >
                    <a class="page-link-vue" href="#" aria-label="first-page">
                        <span class="align-middle" aria-hidden="true"
                            ><i class="fas fa-caret-left fa-2x"></i
                        ></span>
                    </a>
                </li>
                <li
                    class="page-item-vue p-1 align-middle"
                    :class="{ disabled: !rows.hasPrevPage }"
                    @click="getPagination(rows.prevPage)"
                >
                    <a class="page-link-vue p-1" href="#" aria-label="Previous">
                        <span class="align-middle">{{
                            $t('core.navigation.previous')
                        }}</span>
                    </a>
                </li>
                <li
                    class="page-item-vue p-1 align-middle"
                    v-show="rows.prevPage"
                    @click="getPagination(rows.prevPage)"
                >
                    <a class="page-link-vue" href="#">{{ rows.prevPage }}</a>
                </li>
                <li class="page-item-vue p-1 active align-middle">
                    <a class="page-link-vue" href="#">{{ rows.page }}</a>
                </li>
                <li
                    class="page-item-vue p-1 align-middle"
                    v-show="rows.nextPage"
                    @click="getPagination(rows.nextPage)"
                >
                    <a class="page-link-vue" href="#">{{ rows.nextPage }}</a>
                </li>

                <li
                    class="page-item-vue p-1 align-middle"
                    :class="{ disabled: !rows.hasNextPage }"
                    @click="getPagination(rows.nextPage)"
                >
                    <a class="page-link-vue" href="#" aria-label="Next">
                        <span class="align-middle">{{
                            $t('core.navigation.next')
                        }}</span>
                    </a>
                </li>

                <li
                    class="page-item-vue p-1 align-middle"
                    :class="{ disabled: !rows.hasNextPage }"
                    @click="goToLastPage()"
                >
                    <a
                        class="page-link-vue p-1"
                        href="#"
                        aria-label="last-page"
                    >
                        <span class="align-middle" aria-hidden="true"
                            ><i class="fas fa-caret-right fa-2x"></i
                        ></span>
                    </a>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script>
import bestContrast from './bestContrast';
import ProgressBar from './ProgressBar.vue';
export default {
    components: { ProgressBar },
    name: 'PaginatedTable',
    mounted() {},
    data() {
        return {};
    },
    props: [
        'sortOptions',
        'columns',
        'rows',
        'mappedTags',
        'isSearching',
        'rowDelete',
        'rowDetails',
        'pageSelected',
        'showPagination',
        'onOrder',
        'sortTitle',
        'downloadLogs',
    ],
    methods: {
        cursorStyle(column) {
            if (column.sort) {
                return 'pointer-cursor';
            }
            return 'default-cursor';
        },

        sortVerification(column) {
         
            if (this.sortOptions) {
                let keys = Object.keys(this.sortOptions);
                let title = '';
                let columnTitle = '';

                if (column.title) {
                    columnTitle = column.title.split('.');
                    title = columnTitle[columnTitle.length - 1];
                }
                if (
                    keys[0] == title &&
                    this.sortOptions &&
                    this.sortOptions[title] > 0
                ) {
                    return 'text-success fa fa-arrow-down';
                }
                if (
                    keys[0] == title &&
                    this.sortOptions &&
                    this.sortOptions[title] < 0
                ) {
                    return 'text-success fa fa-arrow-up';
                }
                return 'fa fa-sort sort-default';
            }
        },
        classForBooleanIcon(column, row) {
            if (column.function(row)) {
                return 'fas fa-check-square text-success text-center mb-2';
            } else {
                return 'fas fa-window-close text-danger text-center mb-2';
            }
        },
        emptyList() {
            return !this.rows || !this.rows.docs || this.rows.docs.length == 0;
        },
        styleForColorCell(row, column) {
            let colorText = row[column.property] || '#000000';

            let color = bestContrast(colorText);

            return {
                background: colorText,
                'font-size': '.9rem',
                color,
            };
        },
        isNotColumn(column) {
            return !column.hideText && !column.progress && !column.color;
        },
        objectStatus(status) {
            return status || status == 'Online';
        },
        getPagination(page) {
            if (page) {
                this.pageSelected(page);
            }
        },
        goToFirstPage() {
            if (this.rows.totalPages > 1) {
                this.pageSelected(1);
            }
        },
        goToLastPage() {
            if (this.rows.totalPages > 1) {
                this.pageSelected(this.rows.totalPages);
            }
        },
        checkBoxToggled(row, column) {
            if (column.callback) {
                column.callback(row);
            }
        },
        isDisabled(row, column) {
            if (column.disabled) {
                return column.disabled(row);
            }

            return false;
        },
        toggleDropdown(event) {
            event.currentTarget.classList.toggle('is-active');
        },
        getOnOrder(column) {
            if (column.sort) {
                this.onOrder(column);
            }
        },
        emptyStateMessage() {
            if (this.isSearching) {
                return this.$t('core.empty.message_search');
            }

            return this.$t('core.empty.message');
        },
        emptyStateTitle() {
            if (this.isSearching) {
                return this.$t('core.empty.title_search');
            }

            return this.$t('core.empty.title');
        },
        downloadUrl(column, row) {
            if (column.downloadUrl) {
                return column.downloadUrl(row);
            }
            return null;
        },
        valueForCell(column, row) {
            if (column.function) {
                return column.function(row);
            } else if (column.options) {
                const value = row[column.property];
                const options = column.options();
                var result = null;

                options.forEach((option) => {
                    if (option.value == value) {
                        result = this.$t(option.display);
                    }
                });

                if (result) {
                    return result;
                }
            }
            if (column.translator && row[column.property]) {
                result = this.translator(row[column.property]);

                return result;
            }

            return row[column.property];
        },
        translator(row) {
            let parameters = {};
            let template = row.split(',');
            template.forEach((temp) => {
                let parms = temp.split(':');
                let key = parms[0].trim();
                let value = parms[1];
                parameters[key] = value;
            });
            let final = this.$t(template[0], parameters);
            return final;
        },
        classForCell(column, row) {
            let result = ' ';

            if (row[column.property]) {
                result = result + column.class + ' p-0';
            }

            if (column.classFunction) {
                result = result + column.classFunction(row);
            }

            return result;
        },
        tagName(tagId) {
            const tag = this.mappedTags[tagId];
            return tag.name;
        },
        tagStyle(tagId) {
            const tag = this.mappedTags[tagId];
            const fontColor = tag.fontColor || '#fff';
            return {
                color: fontColor,
                'background-color': tag.color,
                border: '3px',
            };
        },
        clearTitle(title) {
            var columnName = title.split('.');

            return columnName[columnName.length - 1];
        },
    },
};
</script>

<style>
.pointer-cursor {
    cursor: pointer;
}
.default-cursor {
    cursor: default;
}

.nowrap {
    display: block !important;
    white-space: nowrap;
}
.sort-red {
    font-size: 1rem !important;
    color: red;
}
.sort-green {
    font-size: 1rem !important;
    color: green;
}
.sort-default {
    font-size: 1rem !important;
}
.center {
    padding: 0.8rem;
}
.zoom {
    transition: transform 0.2s;
}
.zoom:hover {
    transform: scale(2);
    color: #121f9b;
}
@import '../../assets/styleTable.css';
</style>
