<template>
    <div class="pb-2 table-responsive">
        <table class="table table-sm text-center">
            <thead>
                <tr>
                    <th
                        class="
                            border
                            border-primary
                            border-top-0
                            border-left-0
                            border-right-0
                            text-justify
                        "
                        style="font-family: HelveticaNeueLt; font-size: 1.2em"
                        v-for="(column, index) in columnDetails"
                        :key="index"
                    >
                        {{ $t(column.title) }}
                    </th>
                </tr>
            </thead>

            <tbody>
                <tr v-for="(row, index) in objectDetails" :key="index">
                    <td
                        v-for="(column, index) in columnDetails"
                        :key="index"
                        :style="row.style"
                        class="align-bottom text-justify"
                    >
                        <div class="cell-td fontbase font-lt">
                            <span
                                v-if="
                                    !row[column.icon] &&
                                        !column.color &&
                                        column.function
                                "
                                :class="classForColumn(column, row)"
                                class="p-0 m-0 align-text-top"
                                :style="column.style"
                            >
                                {{ column.function(row) }}
                            </span>
                            <span
                                v-if="
                                    !row[column.icon] &&
                                        !column.color &&
                                        !column.function
                                "
                                :class="classForColumn(column, row)"
                                class="p-0 m-0 align-text-top"
                                :style="column.style"
                            >
                                {{ row[column.property] }}
                            </span>
                            <span
                                v-if="row[column.icon] && !column.color"
                                :class="classForColumn(column, row)"
                                class="
                                    badge badge-pill badge-dark
                                    p-2
                                    align-middle
                                "
                                style="margin-top: -5px"
                            >
                                <i class="fas" :class="row[column.icon]" />
                            </span>
                            <span
                                class="badge p-1 text-lowercase"
                                v-if="column.color"
                                style="width: 90px"
                                :style="styleForColorCell(row, column)"
                            >
                                {{ row[column.property] }}
                            </span>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    import bestContrast from "./bestContrast";
    export default {
        name: "ListForm",
        mounted() {},
        props: ["columnDetails", "objectDetails"],
        data() {
            return {};
        },
        methods: {
            classForColumn(column, row) {
                let result = " ";

                if (row[column.property]) {
                    result = column.class;
                }

                if (column.classFunction) {
                    result = result + column.classFunction(row);
                }
                return result;
            },
            styleForColorCell(row, column) {
                let colorText = column.functionStyle(row) || "#000000";
                let color = bestContrast(colorText);

                return {
                    background: colorText,
                    "font-size": ".9rem",
                    color,
                };
            },
        },
    };
</script>

<style scoped></style>
