<template>
    <div id="ListItems">
        <div class="p-2" v-if="this.isShowSearch">
            <input
                class="form-control inputsBorder font-lt"
                :placeholder="$t('core.common.search')"
                @keyup="onSearch($event)"
            />
        </div>
        <div id="list" class="rounded text-center p-2">
            <ul
                class="nav flex-column nav-pills itemsNav"
                aria-orientation="vertical"
                v-for="(row, index) in list"
                :key="index"
            >
                <li :class="classItem(row)">
                    <a class="pl-1 pr-1 pointer" @click="selected(row, index)">
                        {{ row[value] }}
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ListItems",
        mounted() {
            this.list = this.items;
            this.isShowSearch = this.showSearch || false;
            const self = this;
            setTimeout(function() {
                if (self.items.length < 5) {
                    self.isShowSearch = false;
                }
                if (self.autoLoad) {
                    self.selected(self.list[0], 0);
                }
            }, 1000);
        },
        props: ["items", "selectedItem", "value", "autoLoad", "showSearch"],
        data() {
            return {
                isShowSearch: false,
                rowActive: null,
                list: [],
                isFilter: false,
            };
        },
        methods: {
            classItem(row) {
                return this.rowActive == row[this.value] ? "active" : "";
            },
            selected(row, index) {
                this.rowActive = null;
                this.rowActive = row[this.value];
                if (this.isFilter) {
                    const isIndex = (element) =>
                        element[this.value] == row[this.value];
                    index = this.items.findIndex(isIndex);
                }
                this.selectedItem(row, index);
            },
            onSearch: function(event) {
                this.list = [];
                if (event.target.value.length > 1) {
                    this.list = this.items.filter((value) =>
                        value[this.value].includes(event.target.value)
                    );
                    this.isFilter = true;
                } else {
                    if (event.target.value.length === 0) {
                        this.list = this.items;
                    }
                    this.isFilter = false;
                }
            },
        },
    };
</script>

<style scoped>
    #list {
        height: 40rem !important;
        overflow-x: hidden;
        overflow-y: scroll;
    }
</style>
