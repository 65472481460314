<template>
    <div>
        <auth-frame>
            <div class="container-fluid h-100">
                <form class="form-signin h-100" @submit="onSubmit">
                    <div class="d-flex flex-column container-fluid h-100">
                        <div class="row justify-content-center">
                            <div class="col">
                                <!-- <img class="logo d-block mx-auto m-4 p-3 iconico-logo" v-bind:src="get_static_image_url('logo_admin.png')" alt=""> -->

                                <div class="text-center choose-subtitle my-4">
                                    {{ $t("core.choose_org.type_name") }}
                                </div>

                                <div class="form-group">
                                    <label
                                        for="orgPath"
                                        class="mt-4 float-left"
                                    >
                                        {{
                                            $t(
                                                "core.choose_org.organization_path"
                                            )
                                        }}
                                    </label>

                                    <input
                                        type="text"
                                        id="orgPath"
                                        v-model="orgPath"
                                        class="
                                            form-control form-control-lg
                                            mb-4
                                        "
                                        style="text-transform: lowercase"
                                        placeholder="e.g. acme"
                                        required
                                        autofocus
                                    />

                                    <button
                                        class="
                                            btn btn-lg btn-primary btn-block
                                            my-4
                                        "
                                        type="submit"
                                    >
                                        {{
                                            $t(
                                                "core.choose_org.choose_organization"
                                            )
                                        }}
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div
                            class="
                                row
                                justify-content-center
                                align-items-end
                                flex-grow-1
                            "
                        >
                            <div class="col">
                                <button
                                    class="btn btn-lg btn-link create-button"
                                    @click="onCreate"
                                >
                                    {{
                                        $t(
                                            "core.choose_org.create_organization"
                                        )
                                    }}
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </auth-frame>
    </div>
</template>

<script>
    import AuthFrame from "../forms/AuthFrame.vue";

    export default {
        name: "ChooseOrg",
        data() {
            return {
                get_static_image_url: this.$iconico.get_static_image_url,
                orgPath: null,
            };
        },
        components: {
            AuthFrame,
        },
        mounted() {
            if (localStorage.getItem("lang")) {
                this.$i18n.locale = localStorage.getItem("lang");
            }
            let Account = JSON.parse(this.$cookie.get("Account"));
            let Org = JSON.parse(this.$cookie.get("Org"));
            if (Org != null && Account != null) {
                this.$store.state.org = Org;
                this.$store.state.account = Account;
                this.$router.push("/" + Org.path + "/home");
            } else {
                this.$store.state.org = null;
            }
        },
        methods: {
            onSubmit(event) {
                event.preventDefault();

                this.$iconico.get_org_internal(
                    this.orgPath,
                    (orgInfo, error) => {
                        if (orgInfo == null || error != null) {
                            let alertInfo = {
                                state: this.$store.state,
                                title: this.$t("core.choose_org.org_not_found"),
                                message: this.$t("core.choose_org.org_error") + " " + this.orgPath + this.$t("core.choose_org.org_check"),
                                bg: "danger",
                                timeout: 6,
                            };

                            this.$utilities.showAlert(alertInfo);
                        } else {
                            this.$cookie.set("Org", JSON.stringify(orgInfo), {
                                expires: "12h",
                                domain: "iconico.us",
                            });
                            this.$store.state.org = orgInfo;
                            this.$router.push("/" + this.orgPath);
                        }
                    }
                );
            },
            onCreate() {
                this.$router.push("/create");
            },
        },
    };
</script>

<style lang="scss" scoped>
    .iconico-logo {
        max-width: 230px;
        max-height: 95px;
        width: auto;
        height: auto;
    }

    .choose-subtitle {
        font-family: HelveticaNeue;
        font-size: 1.3rem;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: 2.33px;
        color: var(--primary);
    }

    .form-signin {
        text-align: center;
        width: 80%;
        margin: auto;
    }

    .form-signin .form-control {
        height: auto;
        font-size: 1rem;
    }
    .form-signin .form-control:focus {
        z-index: 2;
    }

    .create-button {
        margin-bottom: 5rem;

        font-family: HelveticaNeue;
        font-size: 1.3rem;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 2px;
        text-align: center;
        text-decoration: none;
        color: #6b7285;

        &:hover,
        &:focus,
        &:active {
            color: var(--primary);
        }
    }
</style>
