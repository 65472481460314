<template>
    <nav id="sidebar" :class="{ active: this.$store.state.isToggle }">
        <div class="position-fixed listFixed">
            <ul class="list-unstyled components pt-2">
                <div v-for="(group, index) in groups" :key="index">
                    <p class="mb-1">{{ $t(group.title) }}</p>
                    <li
                        class="pl-2 pt-1 pb-1 text-uppercase"
                        v-for="(item, index) in group.items"
                        :key="index"
                        :id="item.id || `${group.title}-${item.title}`"
                        v-on:mouseenter="setHover(item)"
                        v-on:mouseleave="removeHover(item)"
                        v-on:click="setActive(item)"
                        :class="{ active: isActive(navigationTitle(item)) }"
                    >
                        <a :href="'#' + navigationTitle(item)">
                            <i class="fas mr-2" :class="'fa-' + item.image"></i>
                            <span class="pl-1">{{ itemTitle(item) }}</span>
                            <i
                                v-if="item.disclosure"
                                class="fas ml-2 float-right disclosure"
                                :class="'fa-' + item.disclosure"
                            ></i>
                        </a>
                    </li>
                </div>
            </ul>
        </div>
    </nav>
</template>

<script>
import VueScreenSize from "vue-screen-size";
import Vue from 'vue'

export default {
    mixins: [VueScreenSize.VueScreenSizeMixin],
    name: "SideBar",

    mounted() {
        this.calculateGroups();

        let firstNavGroup = this.groups[0] || {};
        let firstNavGroupItems = firstNavGroup.items || [];
        if (firstNavGroupItems.length > 0) {
            this.setActive(firstNavGroupItems[0]);
        } else {
            this.activeItem = this.$store.state.selectedDashboardTabActive;
        }

        this.loadModules();

        this.$watch(
            () => {
                return this.$system.objects;
            },
            () => {
                this.loadModules();
            }
        );
        this.$watch(
            () => {
                return this.$store.state.selectedDashboardTab;
            },
            () => {
                this.changeTabIfNeeded();
            }
        );
    },
    data() {
        return {
            activeItem: null,
            hoverItem: null,
            settingsItem: {
                title: "core.navigation.settings",
                id: "navigationOrgSettings",
                component: "setting",
                object: "setting",
                image: "cog",
            },
            modulesItem: {
                title: "core.navigation.iconico_apps",
                component: "apps",
                navigation: "apps",
                id: "navigationApps",
                image: "download",
            },
            modules: [],
            groups: [],
        };
    },

    props: ["navigationGroups"],
    methods: {
        changeToggle() {
            this.$store.state.isToggle = !this.$store.state.isToggle;
        },
        navigationTitle(item) {
            return item.navigation
                ? item.navigation
                : this.shortenNavigationTitle(item.title);
        },
        shortenNavigationTitle(title) {
            return title.split(".").pop();
        },
        isActive: function(menuItem) {
            return this.activeItem === menuItem;
        },
        isHover: function(menuItem) {
            return this.hoverItem === menuItem;
        },
        itemTitle(item) {
            if (item.use_verbatim) {
                return item.title;
            }

            return this.$t(item.title);
        },
        changeTabIfNeeded() {
            if (
                this.$store.state.selectedDashboardTab === "setting" &&
                    this.activeItem != "settings"
            ) {
                this.activeItem = "settings";
                this.$store.state.selectedObject = "setting";
            } else if (
                this.$store.state.selectedDashboardTab === "account" &&
                    this.activeItem != "account"
            ) {
                this.activeItem = "null";
            }
        },
        setHover: function(item) {
            this.hoverItem = this.navigationTitle(item);
        },
        removeHover: function(item) {
            this.hoverItem = null;
        },
        setActive: function(item) {
            if (this.$store.state.Object) this.$store.state.Object = null;
            if (item.component == "link") {
                let url =
                        "https://" +
                        item.object +
                        ".iconico.us/" +
                        this.$store.state.org.path;
                window.open(url, "_blank");
            }
            this.activeItem = this.navigationTitle(item);
            this.$store.state.selectedDashboardTab = item.component;
            this.$store.state.selectedObject = item.object;
            if (item.component == "billing") {
                this.$store.state.selectedOrder = false;
            }
            if (this.$vssWidth < 990) {
                this.changeToggle();
            }
        },
        calculateGroups() {
            const store = this.$store.state

            this.groups = this.navigationGroups()
                .map(({items = [], ...rest}) => ({
                    ...rest,
                    items: items.filter(item => item.show?.(store) ?? true)
                }))
                .filter(({ items }) => items.length)
        },
        orgIsSubscribed(product) {
            const org = this.$store.state.org || {};
            const subscriptions = org.subscriptions || [];

            return subscriptions.find(
                (subscription) => subscription.product == product._id
            );
        },
        loadModules() {
            const systemObjects = this.$system.objects || {};
            const products = systemObjects.product || [];

            if (this.modules.length == 0) {
                this.modules.push(this.modulesItem);
            } else if (this.modules.length > 1) {
                while (this.modules.length > 1) {
                    this.modules.pop();
                }
            }

            var product;
            for (product of products) {
                if (product.type === "subscription") {
                    if (this.orgIsSubscribed(product)) {
                        let moduleEntry = {
                            title: product.name || "",
                            use_verbatim: true,
                            component: "link",
                            object: product.name || "",
                            image: "toolbox",
                            disclosure: "arrow-right",
                        };

                        this.modules.push(moduleEntry);
                    }
                }
            }
        },
    },
};
</script>

<style scoped>
    ::-webkit-scrollbar {
        display: none;
    }
    .listFixed {
        height: 90%;
        overflow-y: scroll;
        overflow-x: hidden;
        min-width: 250px;
        max-width: 250px;
    }
    .disclosure {
        position: relative;
        top: 0.2rem;
    }
</style>
