<template>
    <div class="pt-1">
        <DisplayCard :title="$t('core.common.device')">
            <EquipmentForm
            :object="this.object"
            :cancelObjectChanges="cancelObjectChanges"
            :actionDelete="this.actionDelete"
            
            />
        </DisplayCard> 
    </div>
</template>

<script>
import EquipmentForm from "./EquipmentForm.vue"
export default {
    name: "EquipmentInput",
    components: {
        EquipmentForm
    },
    data() {
        return {
            device: {},
            isEdit: false,
            isLoaded: false,
            mappedTags: {},
        };
    },
    props: ["confirmObjectChanges", "cancelObjectChanges", "actionDelete","object"],

};
</script>

<style scoped></style>
