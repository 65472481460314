import core from '../core/src/lang/en.js';

export default {
    core,
    web: {
        navigation:{
            'equipment':'EQUIPMENT',
            'alarms': 'Alarms',
            'monitoring': 'Monitoring',
            'equipment_types': 'EQUIPMENT TYPES',
            'sensor_types': 'SENSOR TYPES',
            'variable': 'VARIABLE',
            'equipment_details':'EQUIPMENT DETAILS',
            'equipment_graphs':'GRAPHS'
        },
        common:{
            'client': 'Client',
            'type': 'Type',
            'location': 'Location',
            'last_service': 'Last Service',
            'refrigerators': 'Refrigerators',
            'alarms_last_month': 'Alarms Last Month'
        },
        notifications:{
            'create_equipment':'Equipemnt successfully created!',
            'update_equipment':'Equipemnt successfully updated!',
        }
    },
//     admin: {
//         navigation: {
//             'organization': 'ORGANIZATION',
//             'network': 'NETWORK',
//             'apps': 'APPS',
//             'developer': 'DEVELOPER',
//             'users':'Users',
//             'billing':'Billing',
//             'activity':'Activity',
//             'notifications':'Notification',
//             'servers':'Servers',
//             'objects_and_schemas':'Objects',
//             'scripts':'Scripts',
//             'logs':'Logs',
//             'console':'Console',
//             'sockets':'Sockets',
//             'info':'... More Information'
//         },
//         users: {
//             'invited': 'Invited',
//             'active': 'Active',
//             'invite': 'Invite User',
//             'probe': 'Network'
//         },
//         tour: {
//             'app_launcher_title': 'App Launcher',
//             'app_launcher_message': 'Select installed applications here',
//             'account_settings_title': 'Account Settings',
//             'account_settings_message': 'Click here to change your account settings',
//             'org_settings_title': 'Organization Settings',
//             'org_settings_message': `Configure your organization settings:
//             <ul>
//             <li>Language Preferences</li>
//             <li>Organization Logo</li>
//             </ul>`,
//             'users_title': 'Manage Users',
//             'users_message': `
//             <ul>
//             <li>Invite new users</li>
//             <li>Change user roles</li>
//             <li>Delete users</li>
//             </ul>`,
//             'apps_title': 'Manage Installed Apps',
//             'apps_message': 'Install new apps to your organization'
//         },
//         organization: {
//             'settings': 'Organization Settings',
//             'main_information': 'Main Information',
//             'self_registration': 'Self Registration',
//             'administrators': 'Administrators',
//             'select_file': 'Select File'
//         },
//         billing: {
//             'success_delete': 'The card was removed successfully!',
//             'success': 'The card was loaded successfully!',
//             'error': 'There was an error loading the card',
//             'card': 'Card',
//             'cards': 'Cards',
//             'order': 'Order',
//             'orders': 'Orders',
//             'number': 'Number',
//             'default': 'Default',
//             'expiry': 'Expiry',
//             'expires': 'Expires',
//             'order_id': 'Order ID',
//             'type': 'Type',
//             'manual': 'Manual',
//             'credit_card': 'Credit Card',
//             'total': 'Total',
//             'payment_methods': 'Payment Methods',
//             'subscriptions': 'Subscriptions',
//             'receipts': 'Receipts',
//             'product': 'Product',
//             'products': 'Products',
//             'subscribe': 'Subscribe',
//             'unsubscribe': 'Unsubscribe',
//             'download': 'Download',
//             'download_invoice': 'Download Invoice',
//             'period_end': 'Period End',
//             'estimated_billing': 'Estimated Billing',
//             'status': 'Status',
//             'active': 'Active',
//             'usage': 'Usage',
//             'currency': 'Currency',
//             'make_default': 'Make Default',
//             'subscription_details': 'Subscription Details',
//             'unsubscribe_prompt': 'Are you sure you wish to unsubscribe from',
//             'subscribe_prompt': 'Are you sure you wish to subscribe to',
//             'system_app': 'This is a system application and cannot be uninstalled',
//             //Possible values are incomplete, incomplete_expired, trialing, active, past_due, canceled, or unpaid.
//             'status_incomplete': 'Incomplete',
//             'status_incomplete_expired': 'Incomplete Expired',
//             'status_trialing': 'Trial Period',
//             'status_active': 'Active',
//             'status_past_due': 'Past Due',
//             'status_canceled': 'Canceled',
//             'status_unpaid': 'Unpaid',
//             'add_payment_method': 'Add Payment Method',
//             'add_new_card': 'Add New Card',
//             'no_payment_methods': 'No payment methods have been added to this account yet.',
//             'not_subscribed': 'NOT SUBSCRIBED',
//             'trialing_alert': 'You are currently subscribed in the Free Trial Period for Iconico Sentinel. This trial expires soon.',
//             'past_due_alert': 'Your subscription payment is past due, please pay now to continue using the tools or email us at support@iconico.us',
//             'unpaid_alert': 'Action Required: There was a problem processing your last payment, enter new payment information or email us at support@iconico.us',
//             'card_text': 'A credit card is required to install',
//             'card_text30d': `Don't worry, your first 30 days are free and you can cancel your subscription at any time, without charges being generated.`,
//             'card_textp': `Iconico is Payment Card Industry compliant and we won't view any of your card's information, only interact with payment processing systems with tokens.`
//         },
//         scripts: {
//             'add_script': 'Add Script',
//             'confirm_delete': 'Confirm Script Deletion',
//             'delete_body': 'Are you sure you want to delete script?',
//             'org_scripts': 'Organization Scripts',
//             'app_scripts': 'System Scripts',
//             'job': 'Job',
//             'trigger': 'Trigger',
//             'route': 'Route',
//             'library':  'Library'
//         },
//         activity:{
//             'writes': 'Writes',
//             'query': 'Query',
//             'query_duration': 'Query Duration',
//             'usage': 'Usage'
//         },
//         modules: {
//             'iconico_apps': 'Iconico Applications',
//             'install_app': 'Install Application',
//             'uninstall_app': 'Uninstall',
//             'open_app': 'Open'
//         },
//         apps: {
//             'sentinel': 'Sentinel',
//             'clerk': 'Clerk',
//             'scribe': 'Scribe',
//             'admin': 'Admin'
//         },
//         console: {
//             'feature_restricted': 'Feature Restricted',
//             'scripts_restricted': 'Script creation and custom script running are restricted in your organization. Please write to the following email if you would like to enable this feature:',
//             'sample_code':`console.log('Your code here.');

// //Use this next line to obtain a Mongoose model of the object you need

// let Device = await model('device');

// //Use mongoose expressions to get information from these object models

// let firstDevice = await Device.find().lean(true).limit(1).exec();

// //Remember to always return something

// return firstDevice;
//                             `,
//             'console': 'Console',
//             'script_runner': 'Script Runner',
//             'history': 'History',
//             'request': 'Request',
//             'error_parsing': 'Error in the JSON body',
//             'response': 'Response',
//             'response_code': 'Response Code',
//             'response_time': 'Response Time',
//             'body': 'Body',
//             'connect': 'Connect',
//             'disconnect': 'Disconnect',
//             'send': 'Send',
//             'message': 'Message',
//             'connecting': 'Connecting',
//             'received': 'Received',
//             'sent': 'Sent',
//             'connected': 'Connected',
//             'disconnected': 'Disconnected',
//             'initialized': 'Initialized',
//             'select_server': 'Select Network',
//             'authorization': 'Authorization'
//         }
//     }
}

