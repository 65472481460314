export default {
    org: null,
    account: null,
    modal: null,
    refresh: 0,
    selectedDashboardTab: null,
    selectedDashboardTabActive: null,
    selectedObject: null,
    navigationInfo: [],
    crumbs: [],
    Object: null,
    status,
    message: null,
    showPageLoading: false,
    progress: 0,
    isToggle: false,
    alertInfo: null,
    app: null,
    checkSubscription: null,
    refreshMonitorDevice: null,
    mappedTags: null,
    downloadSystemObjects: null,
    systemObjectsLoaded: false,
    serverActive: null,
};
