const bucketTime = {
    monitoring_1h:[
        {
            value: "10m",
            number: 10,
            presition: "5m",
            time: "minutes",
        },
        {
            value: "30m",
            number: 30,
            presition: "10m",
            time: "minutes",
        },
        {
            value: "60m",
            number: 30,
            presition: "15m",
            time: "minutes",
        },
    ], 
    monitoring_1d: [
        {
            value: "6h",
            number: 6,
            presition: "5m",
            time: "hours",
        },
        {
            value: "12h",
            number: 12,
            presition: "10m",
            time: "hours",
        },
        {
            value: "24h",
            number: 24,
            presition: "15m",
            time: "hours",
        },
    ],  
    monitoring_30d: [
        {
            value: "6d",
            number: 6,
            presition: "6h",
            time: "days",
        },
        {
            value: "12d",
            number: 12,
            presition: "12h",
            time: "days",
        },
        {
            value: "30d",
            number: 30,
            presition: "24h",
            time: "days",
        },
    ]
 
};
module.exports = bucketTime;
