<template>
    <div>
        <div class="text-center">
            <strong>{{ this.value }} %</strong>
        </div>
        <div class="progress progress-xs" style="height: 5px">
            <div
                class="progress-bar"
                :class="
                    this.value >= this.danger
                        ? 'bg-danger'
                        : this.value >= this.warning
                        ? 'bg-warning'
                        : 'bg-success'
                "
                role="progressbar"
                :style="'width: ' + this.value + '%;'"
                :aria-valuenow="this.value"
                aria-valuemin="0"
                :aria-valuemax="this.max"
            ></div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "ProgressBar",
        mounted() {},
        props: {
            value: {},
            danger: {
                type: Number,
                default: 90,
            },
            warning: {
                type: Number,
                default: 80,
            },
            max: {
                type: Number,
                default: 100,
            },
        },
    };
</script>

<style scoped></style>
