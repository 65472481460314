const { func } = require('joi');

let objects = {};
let schemas = {};
let dataPing = [];
let dataCron = [];

function objectPathForDownloadObject(object) {
    if (typeof object == 'object') {
        return object.path;
    }

    return object;
}

async function downloadObjects(iconico, state, downloadSystemObjects = []) {
    const downloads = downloadSystemObjects.map(object => {
        let objectPath = objectPathForDownloadObject(object);
        let params = {};

        if (typeof object == 'object') {
            params = object.parameters || {};
        }

        if (objectPath) {
            let config = {
                org: state.org,
                authToken: state.account.token,
                object: objectPath,
                parameters: {
                    params: {
                        ...params,
                        pagination: false,
                    },
                },
            };

            return iconico.get_objects(config);
        } else {
            return Promise.resolve({});
        }
    });

    const downloadedObjects = await Promise.all(downloads);

    downloadedObjects.forEach((response, index) => {
        if (response && response.data) {
            let objectList = response.data.data.docs;
            let objectPath = objectPathForDownloadObject(
                downloadSystemObjects[index]
            );
            objects[objectPath] = objectList;
        }
    });

    return objects;
}

async function downloadSchemas(iconico, state) {
    let config = {
        org: state.org,
        authToken: state.account.token,
        object: 'schema',
        parameters: {
            params: {
                pagination: false,
            },
        },
    };
    let response = await iconico.get_objects(config);

    response.data.data.docs.forEach(schema => (schemas[schema.path] = schema));
    return schemas;
}

async function downloadPing(iconico, state) {
    for (const server of objects.server) {
        let devicesServer = objects.device.filter(
            device => device.server == server._id
        );
        if (devicesServer.length > 0) {
            let devices = devicesActive(devicesServer);

            if (devices.length == 0) {
                return null;
            }

            let config = {
                org: state.org,
                authToken: state.account.token,
                body: {
                    bucket: 'ping',
                    measurement:
                        '' + state.org.path + '-ping-' + server._id + '',
                    filter: [
                        {
                            id: devices,
                            path: 'device',
                        },
                    ],
                    range: 'start:-24h',
                    function:
                        '|> sort(columns: ["_time"], desc: true) |> limit(n:1, offset: 0)',
                    field: ['pck_lost', 'rtt_avr', 'host_live'],
                },
            };
            let dataPingResult = await iconico.get_influxdb(config);
            let data = dataPingResult.data;
            var reg = data.split('\n');
            if (reg[1] != '') {
                parsedPing(reg);
            }
        }
    }
}

function parsedPing(reg) {
    for (var i = 0; i < reg.length; i++) {
        var value = reg[i].split(',');
        let device = (value[9] || '').trimEnd();
        if (value[6] != '') {
            switch (value[7]) {
                case 'pck_lost':
                    dataPing[device] = {
                        ...dataPing[device],
                        ...{ pck_lost: value[6] },
                    };
                    break;
                case 'rtt_avr':
                    dataPing[device] = {
                        ...dataPing[device],
                        ...{ rtt_avr: value[6] },
                    };
                    break;
                case 'host_live':
                    dataPing[device] = {
                        ...dataPing[device],
                        ...{
                            host_live: value[6],
                            alive: statusString(value[6]),
                        },
                    };
                    break;
            }
        }
    }
    return dataPing;
}

function statusString(host_live) {
    if (host_live == 1) {
        return 'UP';
    }
    return 'DOWN';
}
async function downloadCron(iconico, state) {
    for (const server of objects.server) {
        let devicesServer = objects.device.filter(
            device => device.server == server._id
        );
        if (devicesServer.length > 0) {
            let devices = devicesActive(devicesServer);

            if (devices.length == 0) {
                return null;
            }

            let config = {
                org: state.org,
                authToken: state.account.token,
                body: {
                    bucket: 'cronjobs',
                    measurement:
                        '' + state.org.path + '-cronjobs-' + server._id + '',
                    filter: [
                        {
                            id: devices,
                            path: 'device',
                        },
                        {
                            id: ['probe', 'ping'],
                            path: 'job',
                        },
                    ],
                    range: 'start:-24h',
                    function:
                        '|> sort(columns: ["_time"], desc: true) |> limit(n:1, offset: 0)',
                    field: ['status', 'msg'],
                },
            };
            let dataCronResult = await iconico.get_influxdb(config);
            let dataCron = dataCronResult.data;
            var reg = dataCron.split('\n');
            if (reg[1] != '') {
                parsedCron(reg);
            }
        }
    }
}

function parsedCron(reg) {
    for (var i = 0; i < reg.length; i++) {
        var value = reg[i].split(',');
        let device = (value[9] || '').trim();
        let job = (value[10] || '').trim();
        if (value[6] != '') {
            if (job == 'ping') {
                switch (value[7]) {
                    case 'status':
                        dataCron[device] = {
                            ...dataCron[device],
                            ...{ statusPing: value[6] },
                        };
                        break;
                    case 'msg':
                        dataCron[device] = {
                            ...dataCron[device],
                            ...{ msgPing: value[6], timePing: value[5] },
                        };
                        break;
                }
            } else {
                switch (value[7]) {
                    case 'status':
                        dataCron[device] = {
                            ...dataCron[device],
                            ...{ statusProbe: value[6] },
                        };
                        break;
                    case 'msg':
                        dataCron[device] = {
                            ...dataCron[device],
                            ...{ msgProbe: value[6], timeProbe: value[5] },
                        };
                        break;
                }
            }
        }
    }
    return dataCron;
}

function devicesActive(devices) {
    let devicesId = devices
        .filter(element => element.active)
        .map(element => element._id);
    return devicesId;
}

export {
    objects,
    schemas,
    dataPing,
    dataCron,
    downloadObjects,
    downloadSchemas,
    downloadPing,
    downloadCron,
    devicesActive,
};
