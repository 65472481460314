<template>
    <span class="row justify-content-start pt-2 position width margin">
        <span
            v-if="filter.show"
            v-for="(filter, index) in filters"
            :key="index"
            class="col-3 position-style"
        >
            <div v-if="filter.show">
                <Dropdown
                    v-if="filter.show"
                    :buttonTitle="filter.buttonTitle"
                    :options="filter.options"
                    :selected="filter.selected"
                    :filterName="filter.name"
                    :selectionChanged="filter.selectionChanged"
                    :itemTitle="filter.itemTitle"
                    :loadFilters="loadFilters"
                    :reloadPage="reloadPage"
                    :allFilters="allFilters"
                />
            </div>
        </span>
    </span>
</template>

<script>
export default {
    mounted() {
        this.getAllNameFilters();
    },
    name: 'FilterBar',
    data() {
        return {};
    },

    components: {},

    props: {
        allFilters: {
            type: Object,
        },
        filters: {
            type: Array,
        },
        loadFilters: {
            type: Function,
        },
        reloadPage: {
            type: Function,
        },
    },
    methods: {
        getAllNameFilters() {
            this.filters.forEach((filter) => {
                if (filter.name) {
                    this.allFilters[filter.name] = '';
                }
            });
        },
    },
};
</script>

<style scoped>
.position-style {
    margin-bottom: 1rem;
    margin-left: 1rem !important;
    margin-right: 2.5rem !important;
}
.width {
    width: 75%;
}
</style>
